import Resource from './resource';
import ScopedResource from './scoped-resource';

import Account from './resources/account';
import LineItem from './resources/line-item';
import Order from './resources/order';
import Payment from './resources/payment';
import Refund from './resources/refund';
import StoreCreditLog from './resources/store-credit-log';
import UserStoreCredit from './resources/user-store-credit';
import SpecialProduct from './resources/special-product';

const API = {
  Account: new Account(),
  Product: new Resource({ rootKey: 'product', uri: '/products' }),
  SpecialProduct: new SpecialProduct(),
  ProductType: new Resource({ rootKey: 'product_type', uri: '/product-types' }),
  Finances: {
    Adjustment: new Resource({
      rootkey: 'data',
      uri: '/finances/adjustments',
    }),
  },
  GiftCard: new Resource({ rootKey: 'gift_card', uri: '/gift_cards' }),
  Lead: new Resource({ rootKey: 'lead', uri: '/leads' }),
  Message: new Resource({ rootKey: 'data', uri: '/contacts' }),
  VariantChange: new ScopedResource({
    rootKey: 'rules',
    scope: '/line_items',
    uri: '/change-variant',
  }),
  Variant: new ScopedResource({
    rootKey: 'variant',
    scope: '/products',
    uri: '/variants',
  }),
  LineItem: new LineItem('line_item', '/line_items'),
  Oban: new Resource({ rootKey: 'oban', uri: '/oban_jobs' }),
  OrderLog: new Resource({ rootKey: 'order-log', uri: 'orders-logs' }),
  Order: new Order({ rootKey: 'order', uri: '/orders' }),
  OrderAdjustment: new ScopedResource({
    rootKey: 'adjustment',
    scope: '/orders',
    uri: '/adjustments',
  }),
  OrderStoreCredit: new ScopedResource({
    rootKey: 'store-credit',
    scope: '/orders',
    uri: '/store-credits',
  }),
  OrderTimeline: new Order({
    rootKey: 'order_timeline',
    uri: '/order_timeline_events',
  }),
  Payment: new Payment(),
  Responsible: new Resource({ rootKey: 'responsible', uri: '/responsibles' }),
  Refund: new Refund({ rootKey: 'refund', uri: '/refunds' }),
  RefundLog: new ScopedResource({
    rootKey: 'refund-log',
    scope: '/refunds',
    uri: '/logs',
  }),
  State: new Resource({ rootKey: 'state', uri: '/states' }),
  StoreCredit: new ScopedResource({
    rootKey: 'store_credit',
    scope: '/users',
    uri: '/store-credits',
  }),
  StoreCreditLog: new StoreCreditLog({
    rootKey: 'store_credit_log',
    scope: '/store-credits',
    uri: '/logs',
  }),
  TimeOfDay: new Resource({ rootKey: 'time_of_day', uri: '/times-of-day' }),
  User: new Resource({ rootKey: 'user', uri: '/users' }),
  UserStoreCredit: new UserStoreCredit({
    rootKey: 'store_credit',
    scope: '/users',
    uri: '/store-credits-total',
  }),
};

export default API;
