import React from 'react';

const LogIcon = ({ logType }) => {
  let icon = null;

  switch (logType) {
    case 'error':
      icon = (
        <i
          className="fa fa-exclamation-circle"
          style={{ color: '#f13434' }}
        ></i>
      );
      break;
    case 'payment':
      icon = <i className="fa fa-dollar-sign" style={{ color: 'green' }}></i>;
      break;
    case 'cart':
      icon = (
        <i className="fa fa-cart-shopping" style={{ color: '#c96480' }}></i>
      );
      break;
    default:
      icon = null;
  }

  return icon;
};

export default LogIcon;
