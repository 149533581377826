import React from 'react';

import './styles.scss';

// Source: https://www.bootdey.com/snippets/view/timeline-events#html
const Timeline = ({
  keyPrefix,
  timelineTitle,
  items,
  eventTitle,
  eventDescription,
  eventDate,
  insideCard,
}) => (
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className={`${insideCard ? 'card' : ''}`}>
          <div className={`${insideCard ? 'card-body' : ''}`}>
            {timelineTitle && <h6 className="card-title">{timelineTitle}</h6>}
            <div id="content">
              <ul className="timeline">
                {items.map((item) => {
                  const title = eventTitle && eventTitle(item);
                  const description =
                    eventDescription && eventDescription(item);

                  return (
                    <li
                      key={`${keyPrefix}-timeline-${item.id}-${title}`}
                      className="event"
                      data-date={eventDate(item)}
                    >
                      {title && <h3>{eventTitle(item)}</h3>}
                      {description}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Timeline;
