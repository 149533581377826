export const REFUND_REASONS = {
  cancelation: 'Cancelamento',
  expiration: 'Expiração',
  additional_request_removal: 'Remoção de pedido adicional',
};

export const OPERATIONS = {
  credit: 'Crédito',
  debit: 'Débito',
  refund: 'Devolução',
  inactivation: 'Desativação',
  expiration: 'Expiração',
};
