/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const devSlice = createSlice({
  name: 'dev',
  initialState: {
    debug: false,
  },
  reducers: {
    setDebugMode(state, action) {
      state.debug = action.payload;
    },
  },
});

export const devActions = devSlice.actions;

export default devSlice;
