import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';

const AddVariant = ({ onAdd }) => {
  const [product, setProduct] = useState(null);
  const [variant, setVariant] = useState(null);

  const fetchProducts = async () => {
    const response = await API.Product.all({ page_size: 50 });
    return response.data.data;
  };

  const { data: products, isLoading: isLoadingProducts } = useQuery(
    [`order-factory-products`],
    fetchProducts,
  );

  const fetchVariants = async () => {
    const response = await API.Variant.all(product.id);
    return response.data.data;
  };

  const { data: variants, isLoading: isLoadingVariants } = useQuery(
    [`order-factory-variants-${product?.id}`],
    fetchVariants,
    {
      enabled: product !== null,
    },
  );

  const buildParams = () => {
    const title =
      product.title === variant.title
        ? variant.title
        : `${product.title} - ${variant.title}`;

    return {
      type: 'variant',
      variant_id: variant.id,
      key: new Date().getTime(),
      title,
    };
  };

  if (isLoadingProducts || !products) {
    return <Loading />;
  }

  return (
    <>
      <h5>Produtos</h5>
      <Form.Select
        aria-label="Produtos"
        onChange={(e) => {
          const id = parseInt(e.target.value, 10);

          if (id > 0) {
            const selectedProduct = products.find((p) => p.id === id);
            setProduct(selectedProduct);
          } else {
            setProduct(null);
          }
        }}
      >
        <option value={0}>Escolha o produto</option>
        {products
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((p) => (
            <option key={p.id} value={p.id}>
              {p.title}
            </option>
          ))}
      </Form.Select>

      {isLoadingVariants && <Loading />}

      {variants && (
        <div className="mt-3">
          <h5>Variantes</h5>
          <Form.Select
            aria-label="Variantes"
            onChange={(e) => {
              const id = parseInt(e.target.value, 10);
              if (id > 0) {
                const selectedVariant = variants.find((v) => v.id === id);
                setVariant(selectedVariant);
              } else {
                setVariant(null);
              }
            }}
          >
            <option value={0}>Escolha a variante</option>
            {variants.map((v) => (
              <option key={v.id} value={v.id}>
                {v.title}
              </option>
            ))}
          </Form.Select>

          <Button
            variant="primary"
            className="w-100 mt-3"
            type="submit"
            disabled={variant === null}
            onClick={() => {
              onAdd(buildParams());
              setVariant(null);
              setProduct(null);
            }}
          >
            <i className="fa fa-plus"></i> Variante
          </Button>
        </div>
      )}
    </>
  );
};

export default AddVariant;
