import React from 'react';

import Row from 'react-bootstrap/Row';

const CustomFields = ({ lineItem, variant }) => (
  <Row className="card mb-3">
    <div className="card-header">
      <i className="fa-solid fa-list"></i> &nbsp; Campos personalizados
    </div>
    <div className="card-body">
      {Object.keys(lineItem.custom_fields).map((key) => (
        <p>
          <strong>
            {variant.custom_fields.find((f) => f.name === key).label}
          </strong>
          : {lineItem.custom_fields[key]}
        </p>
      ))}
    </div>
  </Row>
);

export default CustomFields;
