import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import DateRangePicker from './components/DateRangePicker';
import IncomeTable from './components/IncomeTable';
import SummaryCards from './components/SummaryCards';

import './components/styles.scss';

const Finances = () => {
  const title = 'Finanças';

  const [currentMonthKey, setCurrentMonthKey] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const buildTimeRange = (monthKey, year) => {
    const monthNumber = parseInt(monthKey, 10) + 1;

    const startDate = `${year}-${monthNumber
      .toString()
      .padStart(2, '0')}-01T00:00:00`;

    const lastDayOfMonth = new Date(year, monthNumber, 0).getDate();
    const endDate = `${year}-${monthNumber
      .toString()
      .padStart(2, '0')}-${lastDayOfMonth}T23:59:59`;

    return [startDate, endDate];
  };

  const fetchAdjustments = async (startDate, endDate) => {
    const body = await API.Finances.Adjustment.all({
      type: 'payment',
      start_date: startDate,
      end_date: endDate,
    });

    return body.data;
  };

  const { data, isLoading, refetch } = useQuery(
    ['finances-adjustments'],
    async () => {
      const [startDate, endDate] = buildTimeRange(currentMonthKey, currentYear);
      const result = await fetchAdjustments(startDate, endDate);
      return result;
    },
  );

  useEffect(() => {
    refetch();
  }, [currentMonthKey, currentYear]);

  const adjustments = data?.data;
  const formattedTotalValue = data?.formatted.total_value;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="finances-component">
      <h1>{title}</h1>
      <div className="filters-section">
        <div className="search-bar">
          <i className="fa fa-search"></i>
          <p>Pesquise em {title}</p>
        </div>
        <DateRangePicker
          currentMonthKey={currentMonthKey}
          setCurrentMonthKey={setCurrentMonthKey}
          setCurrentYear={setCurrentYear}
          currentYear={currentYear}
        />
      </div>
      <SummaryCards total={formattedTotalValue} />
      <IncomeTable adjustments={adjustments} />
    </div>
  );
};

export default Finances;
