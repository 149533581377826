import client from '../client';

export default class Refund {
  checkoutUrl(orderId) {
    return client.get(
      `/orders/${orderId}/checkout-url/new?current_url=${window.location.href}`,
      {},
    );
  }

  paymentNotification(params) {
    return client.post(`/payment-notifications`, params);
  }
}
