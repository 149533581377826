import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';
import StoreCreditRow from 'pages/users/components/StoreCreditRow';
import Timeline from 'components/Timeline/Timeline';
import { alert, confirm } from 'lib/notifications';
import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import { NamedRoutes } from 'config/routes/named-routes';
import { OPERATIONS } from 'lib/constants';

const StoreCreditsTab = ({ resource }) => {
  const [amount, setAmount] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [showTimeline, setShowTimeline] = useState(false);
  const [statement, setStatement] = useState();

  const fetchStoreCredits = async () => {
    const result = await API.StoreCredit.all(resource.id);
    const { data: body } = result;
    return body.data;
  };

  const fetchUserStoreCreditsTotal = async () => {
    const body = await API.UserStoreCredit.all(resource.id);
    return body.data;
  };

  const { data: storeCreditsTotal, refetch: refetchTotal } = useQuery(
    ['user-store-credits-total', resource.id],
    fetchUserStoreCreditsTotal,
  );

  const {
    data: storeCredits,
    isLoading,
    refetch,
  } = useQuery(['user-store-credits', resource.id], fetchStoreCredits);

  const onDeleteHandler = (storeCredit) => {
    confirm({
      title: 'Atenção',
      text: 'Você tem certeza que deseja inativar estes créditos? Esta operação não poderá ser desfeita.',
      onConfirm: () => {
        API.StoreCredit.destroy(resource.id, storeCredit.id);
        refetch();
      },
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  const openStatementModal = (e) => {
    e.preventDefault();

    if (!statement) {
      API.UserStoreCredit.statement(resource.id).then((response) => {
        const { data: body } = response;
        setStatement(body.data);
        setShowTimeline(true);
      });
    } else {
      setShowTimeline(true);
    }
  };

  const buildTimelineEventTitle = (item) => (
    <span>&nbsp;{OPERATIONS[item.operation]}</span>
  );

  const buildOrderLink = (number) => (
    <a href={NamedRoutes.order_path(number)}>pedido {number}</a>
  );

  const buildParagraph = (storeCreditLog) => {
    const {
      operation,
      amount: decimalAmount,
      order: orderNumber,
    } = storeCreditLog;
    const formattedAmount = storeCreditLog.formatted.amount;
    const plural = decimalAmount > 1;

    switch (operation) {
      case 'credit':
        if (plural) {
          return <span>{formattedAmount} foram adicionados na sua conta.</span>;
        }

        return <span>{formattedAmount} foi adicionado na sua conta.</span>;
      case 'debit':
        if (plural) {
          return (
            <span>
              {formattedAmount} foram retirados da sua conta e aplicados
              no&nbsp;
              {buildOrderLink(orderNumber)}.
            </span>
          );
        }

        return (
          <span>
            {formattedAmount} foi retirado da sua conta e aplicado no&nbsp;
            {buildOrderLink(orderNumber)}.
          </span>
        );
      case 'refund':
        if (plural) {
          return (
            <span>
              {formattedAmount} foram retirados do {buildOrderLink(orderNumber)}{' '}
              e devolvidos para você.
            </span>
          );
        }

        return (
          <span>
            {formattedAmount} foi retirado do {buildOrderLink(orderNumber)} e
            devolvido para você.
          </span>
        );

      case 'inactivation':
        if (plural) {
          return <span>{formattedAmount} foram desativados da sua conta.</span>;
        }

        return <span>{formattedAmount} foi desativado da sua conta.</span>;

      case 'expiration':
        if (plural) {
          return <span>{formattedAmount} expiraram e foram desativados.</span>;
        }

        return <span>{formattedAmount} expirou e foi desativado.</span>;

      default:
        return '';
    }
  };

  const buildTimelineEventDescription = (storeCreditLog) => (
    <p>{buildParagraph(storeCreditLog)}</p>
  );

  return (
    <div className="store-credits-tab">
      <div>
        {storeCreditsTotal && (
          <Col>
            {resource.first_name} possui
            <strong> {storeCreditsTotal.formatted_total} </strong>
            créditos disponíveis.
          </Col>
        )}

        {!storeCreditsTotal && (
          <Col>{resource.first_name} não possui créditos disponíveis.</Col>
        )}
      </div>
      <br />

      <Row>
        <Col>
          <Button variant="primary" type="submit" onClick={openStatementModal}>
            <i className="fa-solid fa-timeline"></i>
            &nbsp;Linha do tempo
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Label>Valor (R$)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Valor"
            defaultValue={amount}
            onChange={(e) => setAmount(e.currentTarget.value)}
          />
        </Col>
        <Col>
          <Form.Label>Data de expiração</Form.Label>
          <Form.Control
            type="date"
            defaultValue={expirationDate}
            onChange={(e) => setExpirationDate(e.currentTarget.value)}
          />
        </Col>
        <Col>
          <Button
            className="mt-32"
            variant="primary"
            type="submit"
            onClick={(e) => {
              e.preventDefault();

              if (amount > 0) {
                API.StoreCredit.create(resource.id, {
                  amount: parseInt(amount, 10),
                  expires_at: expirationDate,
                  origin: 'manual_input',
                }).then(() => {
                  setAmount('');
                  setExpirationDate('');
                  refetch();
                  refetchTotal();
                });
              } else {
                alert({
                  text: 'Não é possível criar créditos sem um valor definido. Escolha um valor maior do que zero',
                });
              }
            }}
          >
            <i className="fa fa-plus"></i> Adicionar
          </Button>
        </Col>
      </Row>

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th></th>
            <th>Valor</th>
            <th>Valor remanescente</th>
            <th>Expira em</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {storeCredits.map((storeCredit) => (
            <StoreCreditRow
              key={storeCredit.id}
              storeCredit={storeCredit}
              onDeleteHandler={onDeleteHandler}
            />
          ))}
        </tbody>
      </Table>

      {showTimeline && (
        <Modal
          className="store-credit-modal"
          title="Linha do tempo"
          onClose={() => setShowTimeline(false)}
        >
          {statement && statement.length > 0 && (
            <Timeline
              keyPrefix="store-credit-timeline"
              items={statement}
              eventTitle={buildTimelineEventTitle}
              eventDate={(item) =>
                formatDateTimeUsingSlashes(item.inserted_at, true)
              }
              eventDescription={buildTimelineEventDescription}
            />
          )}

          {!statement ||
            (statement.length === 0 && (
              <span>Não há nenhum evento na linha do tempo</span>
            ))}

          <section className="form-actions">
            <Button variant="secondary" onClick={() => setShowTimeline(false)}>
              Fechar
            </Button>
          </section>
        </Modal>
      )}
    </div>
  );
};

export default StoreCreditsTab;
