import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import Switch from 'components/Form/Switch';
import AdjustmentRow from './AdjustmentRow';

const AdjustmentsTable = ({ order, lastUpdate }) => {
  const [showPaymentInformation, setShowPaymentInformation] = useState(false);

  useEffect(() => {
    refetch();
  }, [lastUpdate]);

  const fetchAdjustments = async () => {
    const result = await API.OrderAdjustment.all(order.id);
    const { data: body } = result;
    return body.data;
  };

  const {
    data: adjustments,
    isLoading,
    refetch,
  } = useQuery('order-adjustments', fetchAdjustments);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Switch
        label="Exibir informações de pagamento"
        name="required"
        onChange={(e) => {
          setShowPaymentInformation(e.target.checked);
        }}
        checked={showPaymentInformation}
      />
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Valor</th>
            <th>Método</th>
            <th>Tipo</th>
            <th>Data</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {adjustments.map((adjustment) => (
            <AdjustmentRow
              key={adjustment.id}
              adjustment={adjustment}
              showPaymentInformation={showPaymentInformation}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default AdjustmentsTable;
