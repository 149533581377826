import React from 'react';

import './styles.scss';

const button = ({ onClick, iconClass, active, name }) => (
  <div className={`action-button ${active ? 'active' : ''}`} onClick={onClick}>
    <i className={iconClass}></i>
    {name && <div className="button-name">{name}</div>}
  </div>
);

export default button;
