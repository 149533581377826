import React from 'react';

import { LINE_ITEM_STATUSES } from 'lib/status';

import '../style.scss';

const OrderLineItemRow = ({ resource }) => {
  const order = resource;

  const showChildrenLineItems = (lineItem) => {
    if (!lineItem.children || lineItem.children.length === 0) {
      return null;
    }

    const activeChildrenLineItem = lineItem.children.filter(
      (item) => item.status !== 'canceled',
    );

    if (!activeChildrenLineItem || activeChildrenLineItem.length === 0) {
      return null;
    }

    return (
      <>
        <div className="line-item-children">
          {activeChildrenLineItem.map((child) => (
            <div>
              + {child.quantity} x {child.title} (
              {child.billing.price_with_discount.formatted}) ={' '}
              {child.billing.price_with_discount_x_quantity.formatted}{' '}
            </div>
          ))}
          <div className="additional-requests-total">
            Custo adicional total:{' '}
            {lineItem.billing.children_total_price.formatted}
          </div>
        </div>
        <div>Preço total: {lineItem.billing.total.formatted}</div>
      </>
    );
  };

  return (
    <div className="order-line-item-row">
      {order.line_items.map((lineItem) => (
        <div className="line-item" key="{lineItem.id}">
          <div>
            <img
              className="line-item-image"
              src={lineItem.image_url}
              alt={lineItem.title}
            />
            <br />
            <span
              className={`badge ${LINE_ITEM_STATUSES[lineItem.status].klass}`}
            >
              {LINE_ITEM_STATUSES[lineItem.status].name}
            </span>
          </div>
          <div className="line-item-details">
            <div>
              {lineItem.type === 'gift_card' && (
                <strong>{lineItem.title.split('- R$ ')[0]}</strong>
              )}
              {lineItem.type !== 'gift_card' && (
                <strong>{lineItem.title}</strong>
              )}
            </div>

            {lineItem.billing.has_discount && (
              <div>
                Preço: de <s>{lineItem.billing.price.formatted}</s> por{' '}
                {lineItem.billing.price_with_discount.formatted}
              </div>
            )}

            {!lineItem.billing.has_discount && (
              <div>Preço: {lineItem.billing.price_with_discount.formatted}</div>
            )}

            {showChildrenLineItems(lineItem)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderLineItemRow;
