import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Button } from 'react-bootstrap';

import API from 'api';

import Loading from 'components/Loading/Loading';
import { alert, confirm, confirmWithCustomHtml } from 'lib/notifications';

const AdditionalRequests = ({ lineItem, order, refetch }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const additionalRequests = lineItem.children;

  const fetchAdjustments = async () => {
    const result = await API.OrderAdjustment.all(order.id);
    const { data: body } = result;
    return body.data;
  };

  const { data: adjustments, isLoading } = useQuery(
    'order-adjustments',
    fetchAdjustments,
  );

  if (isLoading) {
    return <Loading />;
  }

  const orderHasPayment = adjustments.some(
    (adjustment) => adjustment.type === 'payment',
  );

  const BRL = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const saveAdditionalRequest = () => {
    API.Order.updateAdditionalRequest(order.id, {
      order: {
        line_item_id: selectedItem.id,
        quantity: selectedItem.quantity,
        title: selectedItem.title,
        price: parseFloat(selectedItem.billing.price.in_decimal),
      },
    }).then(() => {
      alert({
        text: 'Pedido adicional alterado com sucesso!',
        icon: 'success',
        callback: () => {
          setSelectedItem(null);
          refetch();
        },
      });
    });
  };

  const newAdditionalRequestSection = () => {
    if (order.status === 'closed' || order.status === 'expired') {
      return null;
    }

    if (
      lineItem.status === 'completed' ||
      lineItem.status === 'canceled' ||
      lineItem.status === 'unfulfilled'
    ) {
      return null;
    }

    return (
      <>
        <br />
        <Button
          className="float-end"
          variant="primary"
          onClick={() => {
            confirmWithCustomHtml({
              title: 'Novo pedido adicional',
              onConfirm: (values) => {
                const params = {
                  parent_id: lineItem.id,
                  type: 'additional_request',
                  price: values.price,
                  title: values.title,
                  quantity: values.quantity,
                  image_url: values.image_url || '',
                };
                API.Order.addToCart(order.id, params).then(() => {
                  alert({
                    text: 'Pedido adicional criado com sucesso!',
                    icon: 'success',
                    callback: () => {
                      setSelectedItem(null);
                      refetch();
                    },
                  });
                });
              },
              html: [
                '<hr />',
                order.status === 'ready'
                  ? '<p>Atenção: o pedido irá <strong>retornar para o status aguardando pagamento <br /></p>'
                  : '',
                '<table style="margin: 0 auto;">',
                '<tr><td>Quantidade</td><td><input style="text-align: right;" class="swal2-input" min="1" type="number" id="additional-request-quantity" value="1" /></td></tr>',
                '<tr><td>Título</td><td><input type="text" class="swal2-input" id="additional-request-title" /></td><tr>',
                '<tr><td>Preço</td><td><input style="text-align: right" class="swal2-input" type="number" min="0" id="additional-request-price" value="0" /></td><tr>',
                '</table>',
              ]
                .filter((n) => n)
                .join(''),
              preConfirm: () => ({
                quantity: parseInt(
                  document.getElementById('additional-request-quantity').value,
                  10,
                ),
                title: document.getElementById('additional-request-title')
                  .value,
                price: parseFloat(
                  document.getElementById('additional-request-price').value,
                ),
                image_url: '',
              }),
            });
          }}
        >
          <i className="fa fa-plus"></i>
        </Button>
      </>
    );
  };

  if (additionalRequests.length === 0) {
    return (
      <>
        <div>Não há pedidos adicionais</div>
        {newAdditionalRequestSection()}
      </>
    );
  }

  const showLineItem = (item) => (
    <tr>
      <td className="text-center">{item.quantity}</td>
      <td>{item.title}</td>
      <td>{item.billing.price_with_discount.formatted}</td>
      <td>{item.billing.price_with_discount_x_quantity.formatted}</td>
      <td>
        {!selectedItem && (
          <>
            {!orderHasPayment && (
              <i
                className="fa fa-pencil edit-item"
                onClick={() => setSelectedItem(item)}
              ></i>
            )}
            &nbsp;
            {order.status !== 'closed' && order.status !== 'expired' && (
              <i
                className="far fa-trash-alt remove-item"
                onClick={() => {
                  if (orderHasPayment) {
                    confirmWithCustomHtml({
                      title: 'Atenção',
                      onConfirm: (values) => {
                        API.Order.removeFromCart(
                          order.id,
                          item.id,
                          values.refundStrategy,
                        ).then(() => {
                          refetch();
                        });
                      },
                      html:
                        'Essa alteração vai diminuir o valor do pedido, e isso dará início a um processo de devolução. ' +
                        '<br /><br /> ' +
                        'Caso deseje continuar, é preciso escolhar qual a estratégia de devolução que será aplicada:' +
                        '<br /> <br />' +
                        '<select class="form-select" id="additional-request-refund-strategy">' +
                        '  <option value="store_credits_only">Devolver com créditos na loja</option>' +
                        '  <option value="refund_using_the_same_payment_method">Devolver com o mesmo método de pagamento</option>' +
                        '</select>',
                      preConfirm: () => ({
                        refundStrategy: document.getElementById(
                          'additional-request-refund-strategy',
                        ).value,
                      }),
                    });
                  } else {
                    confirm({
                      title: 'Atenção',
                      text: 'Você tem certeza que deseja excluir? Esta operação não poderá ser desfeita. O preço irá mudar, um novo link de pagamento precisa ser enviado por email.',
                      onConfirm: () => {
                        API.Order.removeFromCart(order.id, item.id).then(() => {
                          refetch();
                        });
                      },
                    });
                  }
                }}
              ></i>
            )}
          </>
        )}
      </td>
    </tr>
  );

  const editLineItem = (item) => (
    <tr>
      <td>
        <input
          className="text-center"
          type="number"
          value={selectedItem.quantity}
          min={1}
          onChange={(e) =>
            setSelectedItem({
              ...selectedItem,
              quantity: e.target.value,
            })
          }
        />
      </td>
      <td>
        <input
          className="text-center"
          type="text"
          value={selectedItem.title}
          onChange={(e) =>
            setSelectedItem({
              ...selectedItem,
              title: e.target.value,
            })
          }
        />
      </td>
      <td>
        <input
          className="text-center"
          type="number"
          min={0}
          value={selectedItem.billing.price.in_decimal}
          onChange={(e) => {
            const price = parseFloat(e.currentTarget.value);

            setSelectedItem({
              ...selectedItem,
              billing: {
                ...selectedItem.billing,
                price: {
                  ...selectedItem.billing.price,
                  in_decimal: price,
                  in_cents: Math.trunc(price * 100),
                  formatted: BRL.format(price),
                },
              },
            });
          }}
        />
      </td>
      <td>
        {BRL.format(
          selectedItem.billing.price.in_decimal * selectedItem.quantity,
        )}
      </td>
      <td>
        <i
          className="fa fa-save save-item"
          onClick={() => {
            const oldPrice =
              item.billing.price_with_discount_x_quantity.in_cents;
            const newPrice =
              selectedItem.quantity * selectedItem.billing.price.in_cents;

            let message = '';

            if (oldPrice < newPrice) {
              message = 'O preço do pedido irá aumentar. ';
            } else if (oldPrice > newPrice) {
              message = 'O preço do pedido irá diminuir. ';
            }

            if (message === '') {
              saveAdditionalRequest();
            } else {
              confirm({
                title: 'Atenção',
                text: `${message}Deseja continuar? Um novo link de pagamento precisa ser enviado por email.`,
                onConfirm: () => {
                  saveAdditionalRequest();
                },
              });
            }
          }}
        ></i>{' '}
        <i
          className="fa fa-xmark cancel-changes"
          onClick={() => {
            setSelectedItem(null);
          }}
        ></i>
      </td>
    </tr>
  );

  const activeAdditionalRequests = additionalRequests.filter(
    (item) => item.status !== 'canceled',
  );

  return (
    <section className="additional-requests-section">
      <table className="table table-borderless table-condensed table-hover">
        <thead>
          <tr>
            <th>Quantidade</th>
            <th>Pedido adicional</th>
            <th>Valor unitário</th>
            <th>Valor total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {activeAdditionalRequests.map((item) =>
            selectedItem && item.id === selectedItem.id
              ? editLineItem(item)
              : showLineItem(item),
          )}
        </tbody>
      </table>
      {newAdditionalRequestSection()}
    </section>
  );
};

export default AdditionalRequests;
