import React from 'react';
import Table from 'react-bootstrap/Table';

import TooltipIcon from 'components/TooltipIcon/TooltipIcon';

const PriceSummary = ({ order }) => {
  const { billing } = order;

  const abs = (formattedValue) => formattedValue.replace('-R', 'R');

  const buildBalanceDueText = (value) => {
    if (value > 0) {
      return 'Saldo a receber:';
    }

    if (value < 0) {
      return 'Saldo a devolver:';
    }

    return 'Saldo devedor:';
  };

  const buildBalanceDueTooltipText = (value, formattedValue) => {
    if (value > 0) {
      return `O cliente deve nos pagar ${formattedValue}`;
    }

    if (value < 0) {
      return `Nós devemos ${abs(formattedValue)} para o cliente`;
    }

    return '';
  };

  const columnWithTooltip = ({ columnText, tooltipText, condition = true }) => (
    <td className="d-flex">
      <div className="ps-0">{columnText}</div>
      {condition && (
        <TooltipIcon
          className="ps-0 text-muted"
          icon="fa-solid fa-circle-info ms-2"
          text={tooltipText}
        />
      )}
    </td>
  );

  return (
    <Table className="borderless-table" hover>
      <tbody>
        <tr>
          <td>Preço do pedido:</td>
          <td className="text-end">{billing.price.formatted}</td>
        </tr>

        <tr>
          {columnWithTooltip({
            columnText: 'Subtotal:',
            tooltipText:
              'Subtotal é o preço após descontos e sem os itens cancelados do pedido',
          })}
          <td className="text-end">{billing.subtotal.formatted}</td>
        </tr>

        <tr>
          {columnWithTooltip({
            columnText: buildBalanceDueText(billing.balance_due.in_cents),
            tooltipText: buildBalanceDueTooltipText(
              billing.balance_due.in_cents,
              billing.balance_due.formatted,
            ),
            condition: billing.balance_due.in_cents !== 0,
          })}
          <td className="text-end">{abs(billing.balance_due.formatted)}</td>
        </tr>

        <tr>
          <td className="d-flex">
            <div className="ps-0">Total pago:</div>
          </td>
          <td className="text-end">{billing.value_paid.formatted}</td>
        </tr>

        <tr>
          <td colSpan={2} className="text-end">
            <i className="ms-3 fa fa-credit-card secondary-icon" />
            &nbsp;Pix ou cartão: {billing.value_paid_with_money.formatted}
          </td>
        </tr>

        <tr>
          <td colSpan={2} className="text-end">
            <i className="ms-3 fa fa-coins secondary-icon" />
            &nbsp;Créditos: {billing.value_paid_with_store_credits.formatted}
          </td>
        </tr>

        {billing.value_refunded.in_cents > 0 && (
          <tr>
            <td className="d-flex">
              <div className="ps-0">Total devolvido</div>
            </td>
            <td className="text-end">{billing.value_refunded.formatted}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default PriceSummary;
