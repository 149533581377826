/* eslint-disable no-bitwise */
export const weekDaysString = (weekDays) => {
  const days = {
    monday: { text: 'Segunda', binary: '0100000' },
    tuesday: { text: 'Terça', binary: '0010000' },
    wednesday: { text: 'Quarta', binary: '0001000' },
    thursday: { text: 'Quinta', binary: '0000100' },
    friday: { text: 'Sexta', binary: '0000010' },
    saturday: { text: 'Sábado', binary: '0000001' },
    sunday: { text: 'Domingo', binary: '1000000' },
  };

  const includeDay = (weekDay) => {
    const weekDayData = days[weekDay];
    const intFromBinary = parseInt(weekDayData.binary, 2);
    return (weekDays & intFromBinary) === intFromBinary
      ? weekDayData.text
      : null;
  };

  return [
    includeDay('sunday'),
    includeDay('monday'),
    includeDay('tuesday'),
    includeDay('wednesday'),
    includeDay('thursday'),
    includeDay('friday'),
    includeDay('saturday'),
  ]
    .filter((n) => n)
    .join(', ');
};
