import React from 'react';

import './styles.scss';

const ActionButton = ({ icon, onClick, text }) => (
  <div
    className="action-button-component d-flex flex-column align-items-center"
    onClick={onClick}
  >
    <div className="action-button-icon">
      <i className={`fa ${icon}`} />
    </div>
    <div className="action-button-subtitle">{text}</div>
  </div>
);

export default ActionButton;
