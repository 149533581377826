import API from 'api';

import UserForm from 'pages/users/components/UserForm';
import Users from 'pages/users/users';

const UsersController = {
  basePath: '/usuarios',
  form: UserForm,
  page: Users,
  resourceAPI: API.User,
};

export default UsersController;
