const menuItems = [
  {
    name: 'Dashboard',
    icon: 'tachometer-alt',
    path: '/',
  },
  {
    name: 'Pedidos',
    icon: 'list-alt',
    path: '/pedidos',
  },
  {
    name: 'Reembolsos',
    icon: 'exchange',
    path: '/devolucoes',
  },
  {
    name: 'Usuários',
    icon: 'user-friends',
    path: '/usuarios',
  },
  {
    name: 'Produtos',
    icon: 'glass-cheers',
    path: '/produtos',
  },
  {
    name: 'Calendário',
    icon: 'calendar-alt',
    path: '/calendario',
  },
  {
    name: 'Mensagens',
    icon: 'envelope-open-text',
    path: '/mensagens',
  },
  {
    name: 'Leads',
    icon: 'handshake',
    path: '/leads',
  },
  {
    name: 'Cartão-presente',
    icon: 'fa-solid fa-gift',
    path: '/gift-cards',
  },
  {
    name: 'Financeiro',
    icon: 'hand-holding-dollar',
    path: '/finances',
  },
  {
    name: 'Dev',
    icon: 'code',
    path: '/dev',
  },
];

export default menuItems;
