import React, { useState } from 'react';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import Filters from 'components/Filters/Filters';
import TooltipIcon from 'components/TooltipIcon/TooltipIcon';
import { formatDateTimeUsingSlashes } from 'lib/date-utils';

const sortingFields = {
  inserted_at: { direction: 'desc' },
  recipient_name: { direction: 'asc' },
  recipient_email: { direction: 'asc' },
};

const GiftCard = () => {
  const [filters, setFilters] = useState({});

  const filterFields = [
    {
      attribute: 'recipient_name',
      type: 'text',
      label: 'Destinatário',
      id: 'gift-card-recipient-name-filter',
      klass: '',
    },
    {
      attribute: 'recipient_email',
      type: 'text',
      label: 'Email',
      id: 'gift-card-recipient-email-filter',
      klass: '',
    },
    {
      attribute: 'code',
      type: 'text',
      label: 'Código',
      id: 'gift-card-code-filter',
      klass: '',
    },
  ];

  return (
    <>
      <Filters fields={filterFields} applyFilters={setFilters} />
      <hr />

      <CrudList
        title="Cartões-presente"
        columns={{
          recipient_name: { text: 'Destinatário' },
          recipient_email: { text: 'Email' },
          code: {
            text: 'Código',
            render: (resource) => (
              <div>
                <div className="d-flex">
                  <span
                    className={`${resource.redeemed_at ? 'text-success' : ''}`}
                  >
                    {resource.code}
                  </span>

                  {resource.redeemed_at && (
                    <TooltipIcon
                      text={`Resgatado em ${formatDateTimeUsingSlashes(
                        resource.redeemed_at,
                      )} por ${resource.user.first_name} ${
                        resource.user.last_name
                      } (${resource.user.email})`}
                      icon="fa fa-check"
                      className="ms-2 text-success cursor-pointer"
                    />
                  )}
                </div>
              </div>
            ),
          },
          dates: {
            text: 'Datas',
            render: (resource) => (
              <>
                <div>
                  <strong>Criado em:</strong>{' '}
                  {formatDateTimeUsingSlashes(resource.inserted_at)}
                </div>
                <div>
                  <strong>Expira em:</strong>{' '}
                  {formatDateTimeUsingSlashes(resource.expires_at)}
                </div>
                {resource.remeeded_at && (
                  <div>
                    <strong>Resgatado em:</strong>
                    {formatDateTimeUsingSlashes(resource.remeeded_at)}
                  </div>
                )}
                {resource.scheduled_date && (
                  <div>
                    <strong>Envio agendado para:</strong>
                    {formatDateTimeUsingSlashes(resource.scheduled_date)}
                  </div>
                )}
              </>
            ),
          },
          sender: {
            text: 'Enviado por',
            render: (resource) => (
              <div>
                {resource.sender && (
                  <div>
                    <strong>Nome:</strong> {resource.sender.first_name}{' '}
                    {resource.sender.last_name} <br />
                    <strong>Email:</strong> {resource.sender.email}
                  </div>
                )}
              </div>
            ),
          },
          subject: {
            text: 'Enviado?',
            render: (resource) => (resource.sent ? 'Sim' : 'Não'),
          },
        }}
        resourceAPI={API.GiftCard}
        sortingFields={sortingFields}
        filters={filters}
        hideId
        hideTitle
        hideEditButton
        hideNewButton
        hideDeleteButton
      />
    </>
  );
};

export default GiftCard;
