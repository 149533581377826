import React from 'react';
import './styles.scss';

import IncomeTableLine from './IncomeTableLine';

export default function IncomeTable({ adjustments }) {
  if (!adjustments.length) {
    return (
      <div className="income-table-container d-flex justify-content-center p-5">
        <strong>Não há entradas para esse período.</strong>
      </div>
    );
  }
  return (
    <div className="income-table-container">
      <div className="income-table">
        <table className="table">
          <thead>
            <tr>
              <th>Pedido</th>
              <th>Cliente</th>
              <th>Line Items</th>
              <th>Status</th>
              <th>Data de criação</th>
              <th>Data de pagamento</th>
              <th>Valor</th>
              <th>Método</th>
            </tr>
          </thead>
          <tbody className="income-table-content">
            {adjustments.map((adjustment) => (
              <IncomeTableLine adjustment={adjustment} key={adjustment.id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
