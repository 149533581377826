import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import Storage from 'lib/storage';

import { confirmWithCustomHtml } from 'lib/notifications';
import { ORDER_STATUSES } from 'lib/status';

import './styles.scss';

const Favorites = ({
  orderParams,
  orderConfig,
  setOrderParams,
  setOrderConfig,
}) => {
  const [favorites, setFavorites] = useState([]);
  const [favoriteName, setFavoriteName] = useState('');
  const [canSave, setCanSave] = useState(false);

  const fetchUsers = async () => {
    const response = await API.User.all({ page_size: 10000 });
    return response.data.data;
  };

  const { data: users, isLoading: isLoadingUsers } = useQuery(
    [`users-without-pagination`],
    fetchUsers,
  );

  useEffect(() => {
    if (users) {
      const listFromStorage = Storage.get('favoritedOrders', {
        parseFromJson: true,
      });

      if (listFromStorage) {
        const userIds = users.map((u) => u.id);
        setFavorites(
          listFromStorage.filter((f) => userIds.includes(f.orderConfig.userId)),
        );
      }
    }
  }, [users]);

  const saveToFavoritesList = () => {
    const newFavorite = {
      name: favoriteName,
      orderParams,
      orderConfig,
    };

    const hash = btoa(JSON.stringify({ orderConfig, orderParams }));
    newFavorite.hash = hash;

    const newFavoritesList = [...favorites, newFavorite];
    updateFavoriteList(newFavoritesList);
    setFavoriteName('');
  };

  const updateFavoriteList = (newList) => {
    Storage.set('favoritedOrders', newList, { parseToJson: true });
    setFavorites(newList);
  };

  const buildFavoriteCard = (favorite, user) => {
    if (!user) {
      return null;
    }

    const parentLineItems = favorite.orderParams.filter(
      (p) => p.type === 'variant' || p.type === 'gift_card',
    );

    const getChildren = (parentKey) =>
      favorite.orderParams.filter((p) => p.parent_key === parentKey);

    return (
      <div
        className={`favorite ${favorite.name ? 'saved' : ''}`}
        key={favorite.name || 'current-favorite'}
      >
        <div className="avatar">
          <img
            className="rounded-circle img-fluid"
            src={user.avatar}
            alt="user avatar"
          />
        </div>

        {favorite.name && <div className="favorite-name">{favorite.name}</div>}

        {!favorite.name && (
          <div className="relative mt-2">
            <div
              className={`cursor-pointer favorite-badge ms-1 favorite-star ${
                canSave ? 'star-on-the-right' : ''
              }`}
              onClick={() => {
                if (!canSave) {
                  setCanSave(true);
                } else if (favoriteName === '') {
                  setCanSave(false);
                } else {
                  saveToFavoritesList();
                  const favoritedOrders = Storage.get('favoritedOrders', {
                    parseFromJson: true,
                    defaultValue: [],
                  });
                  setFavorites(favoritedOrders);
                  setCanSave(false);
                }
              }}
            >
              <i className="fa-regular fa-star favorite-icon"></i>
            </div>

            <div
              className={`favorite-name-input ${canSave ? 'show-input' : ''}`}
            >
              <Form.Control
                type="text"
                aria-describedby="Nome do favorito"
                onChange={(e) => {
                  setFavoriteName(e.target.value);
                }}
                placeholder="Nome do favorito"
                value={favoriteName}
              />
            </div>
          </div>
        )}

        {parentLineItems.length > 0 && (
          <table className="borderless-table w-100 fs-7">
            <tbody>
              {parentLineItems.map((orderParam) => (
                <tr key={orderParam.key}>
                  <td key={orderParam.variant_id}>
                    ● {orderParam.title}
                    {getChildren(orderParam.key).map((child) => (
                      <div key={child.key} className="ms-3">
                        + {child.quantity} x {child.title}
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <div className="d-flex mt-3 flex-row-reverse icons-row">
          {favorite.orderConfig.includeRequestChange && (
            <div className="include-request-change-logo">
              <i className="fa-regular fa-comment-dots ms-2"></i>
            </div>
          )}
          <div className="payment-method-logo">
            {favorite.orderConfig.paymentMethod === 'pix' && (
              <i className="ms-1 fa-brands fa-pix"></i>
            )}
            {favorite.orderConfig.paymentMethod === 'credit_card' && (
              <i className="mx-1 far fa-credit-card"></i>
            )}
            &nbsp;|
          </div>

          <div className="status-logo">
            <span
              className={`badge ${
                ORDER_STATUSES[favorite.orderConfig.status].klass
              }`}
            >
              {ORDER_STATUSES[favorite.orderConfig.status].name}
            </span>
            &nbsp;|
          </div>
        </div>

        {favorite.name && (
          <div className="d-flex mt-3">
            <hr />
            <Button
              variant="light"
              size="sm"
              onClick={() => {
                confirmWithCustomHtml({
                  title: 'Tem certeza que deseja remover?',
                  onConfirm: () => {
                    const newFavoritesList = favorites.filter(
                      (f) => f !== favorite,
                    );
                    updateFavoriteList(newFavoritesList);
                  },
                });
              }}
            >
              <i className="fa fa-trash-can" /> Remover
            </Button>

            <Button
              className="ms-3"
              variant="light"
              size="sm"
              onClick={() => {
                confirmWithCustomHtml({
                  title:
                    'Isso irá sobreescrever as configurações atuais, deseja continuar?',
                  onConfirm: () => {
                    setOrderParams(favorite.orderParams);
                    setOrderConfig(favorite.orderConfig);
                  },
                });
              }}
            >
              <i className="fa-solid fa-thumbtack"></i> Usar esse
            </Button>
          </div>
        )}
      </div>
    );
  };

  const buildCurrentOrderCard = () => {
    const currentOrder = {
      orderParams,
      orderConfig,
    };

    const user = users.find((u) => u.id === orderConfig.userId);

    return buildFavoriteCard(currentOrder, user);
  };

  if (isLoadingUsers || !users) {
    return <Loading />;
  }

  if (favorites.length === 0 && orderParams.length === 0) {
    return (
      <div className="favorites-component">
        <Alert variant="warning">Monte seu pedido antes de favoritar</Alert>
      </div>
    );
  }

  const currentStateHash = btoa(JSON.stringify({ orderConfig, orderParams }));
  const isCurrentOrderFavorited = favorites.some(
    (f) => f.hash === currentStateHash,
  );

  return (
    <div className="favorites-component">
      {favorites.map((favorite) => {
        const user = users.find((u) => u.id === favorite.orderConfig.userId);

        return buildFavoriteCard(favorite, user);
      })}

      {orderParams.length > 0 &&
        !isCurrentOrderFavorited &&
        buildCurrentOrderCard()}
    </div>
  );
};

export default Favorites;
