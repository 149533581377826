/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import API from 'api';

export const buildStepsList = (orderConfig, orderParams) => {
  const stepsList = [];

  stepsList.push({
    key: 'create_order',
    status: 'initial',
    text: {
      initial: 'Criar pedido',
      in_progress: 'Criando pedido...',
      completed: 'Pedido criado',
    },
    run: async ({ body, initialLineItem }) => {
      const response = await API.Order.create(body);
      const order = response?.data;

      if (order) {
        const lineItemMap = {};
        lineItemMap[order.line_items[0].id] = initialLineItem.key;
        return { ...order, lineItemMap };
      }

      return null;
    },
  });

  const parentLineItemsToAddToCart = orderParams.filter(
    (p) => p.type === 'variant' || p.type === 'gift_card',
  );
  if (parentLineItemsToAddToCart.length > 1) {
    stepsList.push({
      key: 'add_to_cart',
      status: 'initial',
      text: {
        initial: 'Adicionar itens ao pedido',
        in_progress: 'Adicionando itens...',
        completed: 'Itens adicionados ao pedido',
      },
      run: async ({ order, orderParams: params }) => {
        const { lineItemMap } = order;
        const parentLineItems = getTopLevelLineItems(params);
        let newOrder = null;
        let response = null;

        for (const li of parentLineItems) {
          const body = cleanLineItem(li);
          response = await API.Order.addToCart(order.id, body);
          newOrder = response?.data;

          if (!newOrder) {
            throw new Error('Error adding line item to cart');
          }

          // From the response, find the line item that is not yet mapped (it will be
          // the one that has just been added to cart) and map it into the lineItemMap.
          const newLineItem = newOrder.line_items.find(
            (l) => !lineItemMap[l.id],
          );
          lineItemMap[newLineItem.id] = li.key;
        }

        if (newOrder) {
          // Inject lineItemMap on order object so next step can use it
          return { ...newOrder, lineItemMap };
        }

        return null;
      },
    });
  }

  const additionalRequests = orderParams.filter(
    (p) => p.type === 'additional_request',
  );

  if (additionalRequests.length > 0) {
    stepsList.push({
      key: 'add_additional_requests',
      status: 'initial',
      text: {
        initial: 'Adicionar pedidos adicionais',
        in_progress: 'Adicionando pedidos adicionais...',
        completed: 'Pedidos adicionados',
      },
      run: async ({ order, orderParams: params }) => {
        const { lineItemMap } = order;
        let newOrder = { ...order };
        let response = null;
        // for each line item, get its key from the lineItemMap, finds all line items
        // that are children of it, and add them to the order.
        for (const li of variantLineItems(order)) {
          const parentKey = lineItemMap[li.id];
          const children = params.filter((p) => p.parent_key === parentKey);

          for (const child of children) {
            const additionalRequestParams = cleanLineItem(child);
            response = await API.Order.addToCart(newOrder.id, {
              ...additionalRequestParams,
              price: parseFloat(additionalRequestParams.price),
              image_url: '',
              parent_id: li.id,
            });
            newOrder = response?.data;

            if (!newOrder) {
              throw new Error('Error adding additional request to cart');
            }
          }
        }

        return newOrder;
      },
    });
  }

  stepsList.push({
    key: 'add_user',
    status: 'initial',
    text: {
      initial: 'Adicionar usuário ao pedido',
      in_progress: 'Adicionando usuário...',
      completed: 'Usuário adicionado',
    },
    run: async ({ order, config }) => {
      const response = await API.Order.addUser(order.id, config.userId);
      return response?.data;
    },
  });

  if (
    ['placed', 'waiting_for_payment', 'ready', 'closed'].includes(
      orderConfig.status,
    )
  ) {
    stepsList.push({
      key: 'place_order',
      status: 'initial',
      text: {
        initial: 'Requisitar pedido',
        in_progress: 'Requisitando pedido...',
        completed: 'Pedido requisitado',
      },
      run: async ({ order }) => {
        let response = await API.State.all();
        const saoPaulo = response.data.data.find((s) => s.name === 'São Paulo');
        const lineItemParams = [];

        variantLineItems(order).forEach((li) => {
          lineItemParams.push({ ...placeOrderLineItemParams, id: li.id });
        });

        response = await API.Order.placeOrder(order.id, {
          ...placeOrderParams,
          state_id: saoPaulo.id,
          line_items: lineItemParams,
        });

        return response?.data;
      },
    });
  }

  if (['waiting_for_payment', 'ready', 'closed'].includes(orderConfig.status)) {
    if (orderConfig.includeRequestChange) {
      stepsList.push({
        key: 'request_change',
        status: 'initial',
        text: {
          initial: 'Solicitar alteração no pedido',
          in_progress: 'Solicitando alteração...',
          completed: 'Alteração no pedido solicitada',
        },
        run: async ({ order }) => {
          const responses = await Promise.all(
            variantLineItems(order).map(async (li) =>
              API.Order.requestChanges(order.id, {
                ...requestChangesParams,
                line_item_id: li.id,
              }),
            ),
          );

          if (responses.some((r) => !r)) {
            throw new Error('Error requesting changes');
          }

          return responses.reverse().pop()?.data;
        },
      });
    }

    const eligibleLineItems = orderParams.filter((p) => p.type === 'variant');
    if (eligibleLineItems.length > 0) {
      stepsList.push({
        key: 'schedule_variant',
        status: 'initial',
        text: {
          initial: 'Agendar itens do pedido',
          in_progress: 'Agendando itens...',
          completed: 'Itens agendados, pedido aguardando pagamento',
        },
        run: async ({ order }) => {
          const responses = await Promise.all(
            variantLineItems(order).map(async (li) =>
              API.Order.scheduleVariant(order.id, {
                ...scheduleVariantParams,
                line_item_id: li.id,
              }),
            ),
          );

          return responses.reverse().pop()?.data;
        },
      });
    }
  }

  if (['ready', 'closed'].includes(orderConfig.status)) {
    stepsList.push({
      key: 'add_payment_method',
      status: 'initial',
      text: {
        initial: 'Adicionar método de pagamento',
        in_progress: 'Adicionando método de pagamento...',
        completed: 'Método de pagamento adicionado',
      },
      run: async ({ order, config }) => {
        const response = await API.Order.addPaymentMethod(
          order.id,
          config.paymentMethod,
        );
        return response?.data;
      },
    });

    stepsList.push({
      key: 'ready',
      status: 'initial',
      text: {
        initial: 'Pagar pedido',
        in_progress: 'Pagando pedido...',
        completed: 'Pedido pronto',
      },
      run: async ({ order }) => {
        const response = await API.Order.makeOrderReady(order.id, {
          comment: 'Pedido pago',
        });

        return response?.data;
      },
    });
  }

  if (['closed'].includes(orderConfig.status)) {
    stepsList.push({
      key: 'closed',
      status: 'initial',
      text: {
        initial: 'Finalizar pedido',
        in_progress: 'Finalizando pedido...',
        completed: 'Pedido finalizado',
      },
      run: async ({ order }) => {
        const response = await API.Order.closeOrder(order.id);
        return response?.data;
      },
    });
  }

  return stepsList;
};

export const getTopLevelLineItems = (params) =>
  params.filter((p) => p.type === 'variant' || p.type === 'gift_card');

export const cleanLineItem = (li) => {
  const newLineItem = { ...li };
  delete newLineItem.key;
  delete newLineItem.parent_key;

  return newLineItem;
};

export const placeOrderParams = {
  street_address: 'Rua das flores',
  address_number: 'S/N',
  city: 'São Paulo',
  state_id: 25,
  zip_code: '09090-900',
};

export const placeOrderLineItemParams = {
  participants: [
    {
      name: 'Selina Kyle',
      email: 'selina.kyle@mmd.com',
      cpf: '123.456.789-09',
    },
    {
      name: 'Bruce Wayne',
      email: 'bruce.wayne+batman@mmd.com',
      cpf: '354.982.390-84',
    },
  ],
  booking: {
    start_date: '2024-12-09T19:15:52Z',
    from_time: '08:00',
    to_time: '18:00',
    comment: 'Meu sonho fazer esse date, por favor caprichem',
    week_days: 78,
  },
  custom_fields: {
    team_name: 'Team Rocket',
  },
};

export const requestChangesParams = {
  comment:
    'O melhor dia disponível na verdade é na próxima segunda, dia 13/12/2024',
};

export const scheduleVariantParams = {
  comment: 'Encontramos uma ótima data, no dia 13/12/2024',
  scheduled_for: '2024-12-01T19:44:45',
};

export const buildBodyToCreateOrder = (initialLineItem) => {
  let body = cleanLineItem(initialLineItem);

  if (body.type === 'gift_card') {
    body = {
      gift_card: body,
    };
  }

  return body;
};

const variantLineItems = (order) =>
  order.line_items.filter((li) => li.type === 'variant');
