import React, { useState } from 'react';

import { keyPrefix } from './keyPrefix';

const EditableTask = ({ task, attributes, updateTask }) => {
  const [currentTask, setCurrentTask] = useState({ ...task });

  const onChange = (e, attr) => {
    const newCurrentTask = { ...currentTask };
    newCurrentTask[attr.name] = e.target.value;
    // Change the local state with the new data
    setCurrentTask(newCurrentTask);

    // Change the parent component's state with the new data
    updateTask(newCurrentTask);
  };

  const buildTask = () =>
    attributes.map((attr, attrIndex) => {
      const key = `${keyPrefix()}-task-item-${attrIndex}`;
      const value = attr.value
        ? attr.value(currentTask)
        : currentTask[attr.name];

      if (attr.type === 'check') {
        return (
          <input
            key={key}
            type="checkbox"
            disabled
            checked={!!currentTask.done}
          />
        );
      }

      if (attr.type === 'text' || attr.type === 'textarea') {
        return (
          <div className="task-edit-fields" key={key}>
            <label>{attr.title}:</label>
            <input
              type="text"
              value={value}
              onChange={(e) => {
                onChange(e, attr);
              }}
            />
          </div>
        );
      }
      if (attr.type === 'number') {
        return (
          <div className="task-edit-fields" key={key}>
            <label>{attr.title}:</label>
            <input
              type="number"
              value={value || 0}
              step="any"
              min="0"
              onChange={(e) => {
                onChange(e, attr);
              }}
            />
          </div>
        );
      }

      return null;
    });

  return <>{buildTask()}</>;
};

export default EditableTask;
