import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import API from 'api';

import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';
import { alert } from 'lib/notifications';

const ActivationTab = ({ resource }) => (
  <Row>
    <Col>
      <hr />
      <div className="title">Token de ativação</div>

      {resource.activation_token && (
        <>
          <span className="hint">
            Caso precise enviar um email manualmente, adicione um link para
            https://makemydate.com.br/ativar/{'<token>'}
          </span>

          <ClipboardCopy
            copyText={resource.activation_token.id}
            ariaLabel="Token"
          />
        </>
      )}

      <div className="text-center">
        <Button
          variant="primary"
          onClick={() => {
            API.Account.resendAccountActivationEmail(resource.email);
            alert({
              text: 'Email enviado com sucesso',
              icon: 'success',
            });
          }}
        >
          <i className="fa fa-envelope"></i>
          &nbsp;Enviar email de ativação
        </Button>
      </div>
    </Col>
  </Row>
);

export default ActivationTab;
