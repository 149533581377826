import React from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/TopBarButton/Button';
import ContextButtons from 'components/ContextButtons/ContextButtons';
import { storeActions } from 'store';
import { useAuth } from 'contexts/AuthContext';

const ButtonsArea = () => {
  const { logout } = useAuth();
  const dispatch = useDispatch();

  return (
    <div id="buttons-area">
      <ContextButtons />
      <Button
        iconClass="fa fa-sign-out-alt"
        name="Sair"
        onClick={() => {
          const url = encodeURI(window.location.pathname);

          dispatch(storeActions.page.showLoading());
          logout();
          window.location.href = url;
        }}
      />
    </div>
  );
};

export default ButtonsArea;
