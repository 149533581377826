import React from 'react';

const LogContextMenu = ({ log }) => {
  const badges = [];

  if (log.comment.includes('Processo de devolução criado')) {
    const refundId = log.extra_information[0]?.value;
    badges.push({ url: `/devolucoes/${refundId}/editar`, text: 'Reembolso' });
  }

  if (log.comment.includes('vinculado ao pedido')) {
    const userId = log.extra_information[0]?.value;
    badges.push({ url: `/usuarios/${userId}/editar`, text: 'Usuário' });
  }

  return badges.map(({ url, text }) => (
    <a href={url} key={url}>
      <span className="badge mx-1 bg-secondary">{text}</span>
    </a>
  ));
};

export default LogContextMenu;
