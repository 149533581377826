/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';

import Loading from 'components/Loading/Loading';
import LoadingIcon from 'components/Loading/LoadingIcon';
import Modal from 'components/Modal/Modal';

import { consoleLog } from 'lib/debug';

import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';
import {
  buildBodyToCreateOrder,
  buildStepsList,
} from './order-generator-utils';

import './styles.scss';

const GenerationModal = ({ onClose, onComplete, orderConfig, orderParams }) => {
  const [steps, setSteps] = useState([]);
  const [status, setStatus] = useState('initial');
  const [currentOrder, setCurrentOrder] = useState(null);

  const debugMode = useSelector((state) => state.dev.debug);

  useEffect(() => {
    const stepsList = buildStepsList(orderConfig, orderParams);
    setSteps(stepsList);
  }, []);

  const updateStep = (step, newStep) => {
    setSteps((previousSteps) =>
      previousSteps.map((s) => (s.key === step.key ? newStep : s)),
    );
  };

  const runWithStatus = async (step, callback) => {
    let result = null;
    consoleLog({ title: 'Running step:', text: step.key, debugMode });

    try {
      updateStep(step, { ...step, status: 'in_progress' });

      result = await callback();
      consoleLog({ title: 'Result', text: result, debugMode });

      updateStep(step, { ...step, status: result ? 'completed' : 'error' });
    } catch (error) {
      consoleLog({ title: 'Error', text: error, debugMode });
      updateStep(step, { ...step, status: 'error' });
    }

    return result;
  };

  const generateOrder = async (options = {}) => {
    const config = options.orderConfig || {};
    const newOrderParams = [...options.orderParams];
    const initialLineItem = newOrderParams.shift();
    const body = buildBodyToCreateOrder(initialLineItem);

    consoleLog({
      title: 'Create order with this line item:',
      text: initialLineItem,
      debugMode,
    });
    consoleLog({
      title: 'Then, add the following line items to the order:',
      table: newOrderParams,
      debugMode,
    });
    consoleLog({
      title: 'Orderconfig: ',
      table: config,
      debugMode,
    });

    const params = {
      body,
      config,
      initialLineItem,
      orderParams: newOrderParams,
    };

    let order = {};
    for (const step of steps) {
      order = await runWithStatus(step, async () => {
        if (order === null) {
          throw new Error(`Order is null when starting step ${step.key}`);
        }

        return step.run({ ...params, order });
      });
    }

    return order;
  };

  if (steps.length === 0) {
    return <Loading className="generation-modal-loading" />;
  }

  return (
    <Modal title="Geração de pedido" className="generation-modal">
      {status === 'initial' && (
        <p>
          Aperte o cinto, vamos gerar o seu pedido. Clique em{' '}
          <strong>iniciar</strong> quando estiver pronto.
        </p>
      )}
      {status === 'in_progress' && (
        <p>Aguarde, estamos gerando o seu pedido...</p>
      )}
      {status === 'completed' && <p>Parabéns, seu pedido foi criado!</p>}

      <div className="d-flex flex-column">
        {steps.map((step, index) => (
          <div className="d-flex mb-2" key={step.key}>
            {step.status === 'completed' && (
              <div className="circle completed">
                <i className="fa fa-check" />
              </div>
            )}

            {step.status === 'error' && (
              <div className="circle error">
                <i className="fa fa-xmark" />
              </div>
            )}

            {step.status === 'in_progress' && (
              <div className="circle in_progress">
                <LoadingIcon />
              </div>
            )}

            {step.status === 'initial' && (
              <div className="circle">{index + 1}</div>
            )}

            <div className={`ms-2 step-name ${step.status}`}>
              {step.text[step.status] || step.text.initial}
            </div>
          </div>
        ))}
      </div>

      <section className="form-actions">
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
        <Button
          disabled={status === 'in_progress'}
          variant="warning"
          onClick={() => {
            steps.forEach((step) => {
              updateStep(step, { ...step, status: 'initial' });
            });
            setCurrentOrder(null);
            setStatus('in_progress');

            generateOrder({ orderParams, orderConfig }).then((order) => {
              setCurrentOrder(order);

              if (order) {
                setStatus('completed');
                onComplete();
              }
            });
          }}
        >
          {status === 'completed' ? 'Gerar novamente' : 'Iniciar'}
        </Button>
        {status === 'completed' && (
          <Button
            variant="light"
            onClick={() =>
              window.open(
                `${window.location.origin}/pedidos/${
                  currentOrder.number || currentOrder.id
                }/editar`,
              )
            }
          >
            Ver o pedido
          </Button>
        )}

        {status === 'completed' && currentOrder && (
          <div className="mt-3">
            <ClipboardCopy copyText={currentOrder.id} ariaLabel="Order ID" />
          </div>
        )}
      </section>
    </Modal>
  );
};

export default GenerationModal;
