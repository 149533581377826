import React from 'react';

import { formatDateTimeUsingSlashes, parseToUTCDate } from 'lib/date-utils';
import { ORDER_STATUSES } from 'lib/status';

const LogExtraInformation = ({ extraInfo }) => {
  const extraInformationField = (field, indentLevel = 0) => {
    if (!field.value && !field.text) {
      return null;
    }

    const isDateTime = field.type === 'datetime';
    const isStatus = field.type === 'status';
    const isParticipants = field.text === 'Participantes';
    const isSchedulingInformation = field.text === 'Informações de agendamento';
    const isOther =
      !isDateTime && !isStatus && !isParticipants && !isSchedulingInformation;

    return (
      <span>
        <span className={`ms-${indentLevel}`}>-&nbsp;</span>
        <strong>{field.text}</strong>:&nbsp;
        {isDateTime && formatDateTimeUsingSlashes(parseToUTCDate(field.value))}
        {isStatus && ORDER_STATUSES[field.value].name}
        {isParticipants &&
          JSON.parse(field.value)
            .map((p) => p.name)
            .join(', ')}
        {isSchedulingInformation && (
          <>
            <br />
            {Object.entries(JSON.parse(field.value)).map(([k, v]) => (
              <div key={`log-extra-information-${k}-${v}`}>
                {extraInformationField({ text: k, value: v }, '3')}
                <br />
              </div>
            ))}
          </>
        )}
        {isOther && field.value}
      </span>
    );
  };

  if (!extraInfo) {
    return null;
  }

  return (
    <div className="log-extra-informtion-component">
      {extraInformationField(extraInfo)}
    </div>
  );
};

export default LogExtraInformation;
