import React from 'react';
import { useQuery } from 'react-query';

import API from 'api';

const ObanContextBar = ({ args }) => {
  const fetchRefund = async () => {
    const response = await API.Refund.find(args.refund_id);
    const { id } = response.data.data;

    return {
      url: `/devolucoes/${id}/editar`,
      text: `Reembolso #${id}`,
    };
  };

  const { data: refundLink } = useQuery(
    [`oban-context-bar-refund-${args.refund_id}`],
    fetchRefund,
    {
      enabled: args && args.refund_id !== undefined && args.refund_id !== null,
    },
  );

  const buildLink = (link) => (
    <a href={link.url}>
      <span className="badge mx-1 bg-secondary">{link.text}</span>
    </a>
  );

  const orderNumber = args && args.params?.data?.object?.metadata?.order_number;
  const userId = args && args.params?.data?.object?.metadata?.user_id;

  return (
    <div className="oban-context-bar">
      {refundLink && buildLink(refundLink)}
      {orderNumber &&
        buildLink({
          text: `Pedido ${orderNumber}`,
          url: `/pedidos/${orderNumber}/editar`,
        })}
      {userId &&
        buildLink({
          text: 'Usuário',
          url: `/usuarios/${userId}/editar`,
        })}
    </div>
  );
};

export default ObanContextBar;
