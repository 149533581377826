// Every time we use routesFor, we store information about the routes inside
// this NamedRoutes object. This is usefull if we need to build the url to a specific
// resource. For example, if you want to add a lik to navigate to the page where
// you can edit a user, you can build the url with:
//
// NamedRoutes.user_path('BS001');
//
// In the same fashion, you can link to the list of users:
//
// NamedRoutes.users_path;
//
// That is possible because, in the `routes.js`, we used the `routesFor` for
// a resource named `user`.
//
// For each resource, we will store three named routes:
//
// * <resourceName>_path: this is a function that receives an id and builds the
//   url with `/<basePath>/<id>`.
//
// * <resourceName pluralized>_path: this is a string build with `/<basePath>`.
//
// * new_<resourceName>_path: this is a string built with `/<basePath>/novo`
export const NamedRoutes = {};
