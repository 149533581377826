import { FORM_STATES } from '../components/Form/formStates';

export const confirmFormStateBeforeRedirect = (formState, callback) => {
  if (formState !== FORM_STATES.unchanged) {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      'As alterações serão perdidas. Deseja continuar?',
    );
    if (confirm) {
      callback();
    }
  } else {
    callback();
  }
};
