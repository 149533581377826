const contextMenuForProducts = [
  {
    name: 'Produtos',
    iconClass: 'fa fa-list',
    path: '/produtos',
  },
  {
    name: 'Tipos',
    iconClass: 'fa fa-theater-masks',
    path: '/produtos/tipos-de-produtos',
  },
  {
    name: 'Responsáveis',
    iconClass: 'fa fa-user-shield',
    path: '/produtos/responsaveis',
  },
  {
    name: 'Períodos',
    iconClass: 'fa fa-clock',
    path: '/produtos/horas-do-dia',
  },
];

export const PAGE_BUTTONS = {
  '/produtos/.*': contextMenuForProducts,
};
