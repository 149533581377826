import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

const AddAdditionalRequest = ({ onAdd, variantLineItems }) => {
  const defaultAdditionalRequest = {
    type: 'additional_request',
    quantity: 1,
    title: '',
    price: 0,
  };
  const [additionalRequest, setAdditionalRequest] = useState(
    defaultAdditionalRequest,
  );
  const [selectedLineItem, setSelectedLineItem] = useState(null);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setAdditionalRequest({ ...additionalRequest, [name]: value });
  };

  return (
    <>
      <h5>Pedido adicional</h5>

      {variantLineItems.length === 0 && (
        <Alert variant="warning">
          Adicione um item do tipo variante no pedido
        </Alert>
      )}

      {variantLineItems.length > 0 && (
        <>
          <span>Item do tipo variante:</span>
          <Form.Select
            aria-label="Itens de pedido"
            onChange={(e) => {
              const key = e.target.value;
              const selected = variantLineItems.find(
                (p) => `${p.key}` === `${key}`,
              );
              setSelectedLineItem(selected);
            }}
            value={selectedLineItem ? selectedLineItem.key : 0}
          >
            <option>Escolha a variante</option>
            {variantLineItems.map((p) => (
              <option key={`additional-request-${p.key}`} value={p.key}>
                {p.title}
              </option>
            ))}
          </Form.Select>
        </>
      )}

      {selectedLineItem && (
        <div className="mt-3">
          <Row>
            <Col>
              <span>Quantidade:</span>
              <Form.Control
                placeholder="Quantidade"
                type="number"
                name="quantity"
                onChange={onChangeHandler}
                value={additionalRequest.quantity}
                min={1}
              />
            </Col>

            <Col>
              <span>Item do pedido:</span>
              <Form.Control
                placeholder="Item do pedido"
                type="text"
                name="title"
                onChange={onChangeHandler}
                value={additionalRequest.title}
              />
            </Col>

            <Col>
              <span>Preço (R$):</span>
              <Form.Control
                placeholder="Preço"
                type="number"
                name="price"
                onChange={onChangeHandler}
                value={additionalRequest.price}
                step=".01"
                min={0}
              />
            </Col>
            <Col>
              <Button
                variant="primary"
                className="mt-4"
                type="submit"
                onClick={() => {
                  const params = {
                    ...additionalRequest,
                    key: new Date().getTime(),
                    parent_key: selectedLineItem.key,
                  };
                  onAdd(params);
                  setSelectedLineItem(null);
                  setAdditionalRequest(defaultAdditionalRequest);
                }}
              >
                <i className="fa fa-plus"></i> Adicionar pedido
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default AddAdditionalRequest;
