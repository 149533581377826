import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Switch from 'components/Form/Switch';

import { confirm } from 'lib/notifications';

import './styles.scss';

const ParticipantField = ({ index, field, onChange }) => {
  const [currentField, setCurrentField] = useState(field);

  const onChangeHandler = (event) => {
    const value =
      event.target.name === 'required'
        ? event.target.checked
        : event.target.value;

    const newField = {
      ...currentField,
      [event.target.name]: value,
    };

    setCurrentField(newField);
    onChange(newField, index);
  };

  const onDeleteHandler = () => {
    const newField = { ...currentField, deleted: true };
    setCurrentField(newField);
    onChange(newField, index);
  };

  return (
    <Accordion.Item
      eventKey={index}
      className={`participant-field ${
        currentField.deleted ? 'participant-field-deleted' : ''
      }`}
    >
      <Accordion.Header>
        #{index + 1}
        {currentField.label && currentField.label !== '' ? ' - ' : ''}
        {currentField.label}
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          <Col>
            <Input
              label="Label"
              name="label"
              onChange={onChangeHandler}
              placeholder="Texto que será apresentado"
              value={currentField.label}
            />
          </Col>
          <Col>
            <Input
              label="Nome interno"
              name="name"
              onChange={onChangeHandler}
              placeholder="ex.: user_email"
              value={currentField.name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              label="Tipo do campo"
              name="type"
              onChange={onChangeHandler}
              options={[
                { text: 'Texto', value: 'text' },
                { text: 'Email', value: 'email' },
                { text: 'Senha', value: 'password' },
              ]}
              value={currentField.type}
            />
          </Col>
          <Col>
            <Select
              label="Tipo do validação"
              name="validation_type"
              onChange={onChangeHandler}
              options={[
                { text: 'Sem validação', value: null },
                { text: 'Email', value: 'email' },
                { text: 'CPF', value: 'cpf' },
              ]}
              value={currentField.validation_type}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Switch
              label="O preenchimento do campo é obrigatório?"
              name="required"
              onChange={onChangeHandler}
              checked={currentField.required}
            />

            <div className="delete-participant-field">
              <i
                className="far fa-trash-alt"
                onClick={() => {
                  confirm({
                    title: 'Atenção',
                    text: 'Você tem certeza que deseja excluir?',
                    onConfirm: () => onDeleteHandler(index),
                  });
                }}
              ></i>
            </div>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default ParticipantField;
