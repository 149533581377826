import React from 'react';
import './styles.scss';

export default function summaryCards({ total }) {
  const iconColors = [
    { backgroundColor: '#9cdf5a45', color: '#1dd21d' },
    { backgroundColor: '#9CC3FF45', color: '#1ab0f9' },
    { backgroundColor: '#FFEBB045', color: '#FFBF00' },
    { backgroundColor: '#FFB0B045', color: '#FD2525' },
  ];

  return (
    <div className="summary-cards">
      <div className="summary-card">
        <div className="summary-card-icon">
          <i className="fa fa-user" style={iconColors[0]}></i>
        </div>
        <div className="summary-card-content">
          <strong>123</strong>
          <p>Clientes</p>
        </div>
      </div>
      <div className="summary-card">
        <div className="summary-card-icon">
          <i className="fa fa-receipt" style={iconColors[1]}></i>
        </div>
        <div className="summary-card-content">
          <strong>145</strong>
          <p>Pedidos</p>
        </div>
      </div>
      <div className="summary-card">
        <div className="summary-card-icon">
          <i className="fa fa-dollar-sign" style={iconColors[2]}></i>
        </div>
        <div className="summary-card-content">
          <strong>{total}</strong>
          <p>Receita</p>
        </div>
      </div>
      <div className="summary-card">
        <div className="summary-card-icon">
          <i className="fa fa-money-check-dollar" style={iconColors[3]}></i>
        </div>
        <div className="summary-card-content">
          <strong>R$ 325.00</strong>
          <p>Ticket Médio</p>
        </div>
      </div>
    </div>
  );
}
