import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useQuery } from 'react-query';

import API from 'api';

import CloseRefundModal from 'pages/refunds/components/CloseRefundModal';
import ContextButtons from 'pages/refunds/components/ContextButtons';
import Loading from 'components/Loading/Loading';

import { alert, confirm } from 'lib/notifications';
import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import { NamedRoutes } from 'config/routes/named-routes';
import { REFUND_STATUSES } from 'lib/status';
import { REFUND_REASONS } from 'lib/constants';

import './styles.scss';

const RefundForm = ({ resource, onClose }) => {
  const [refund, setRefund] = useState(resource);
  const [movingToClosed, setMovingToClosed] = useState(false);

  const fetchRefundLogs = async () => {
    const result = await API.RefundLog.all(refund.id);
    const { data: body } = result;
    return body.data;
  };

  const reload = (response) => {
    const newRefund = response.data.data;
    if (newRefund) {
      setRefund(newRefund);
      refetch();
    } else {
      API.Refund.find(refund.id).then((r) => {
        setRefund(r.data.data);
        refetch();
      });
    }
  };

  const {
    data: logs,
    isLoading,
    refetch,
  } = useQuery('refund-logs', fetchRefundLogs);

  if (isLoading) {
    return <Loading />;
  }

  const highlightWords = (text) => {
    const words = ['manually_refunded', 'manual'];
    let result = text;
    words.forEach((word) => {
      result = result.replace(
        new RegExp(word, 'g'),
        `<span class="highlight-word">${word}</span>`,
      );
    });

    return result;
  };

  return (
    <div className="refund-form">
      <h1>Devolução de valores</h1>

      <ul>
        <li>
          <strong>ID</strong>: {refund.id}
        </li>
        <li>
          <strong>Pedido: </strong>
          <a
            href={NamedRoutes.order_path(refund.order_number)}
            alt={refund.order_number}
          >
            {refund.order_number}
          </a>
        </li>
        <li>
          <strong>Preço do pedido: </strong>
          {refund.billing.formatted.total_price}
        </li>
        <li>
          <strong>Saldo a devolver: </strong>
          {refund.billing.formatted.value_to_refund.replace('-R', 'R')} <br />
          <ul>
            <li>Store credits: {refund.billing.formatted.store_credits}</li>
            <li>Pagamentos: {refund.billing.formatted.payments}</li>
          </ul>
        </li>
        <li>
          <strong>Status:</strong> {REFUND_STATUSES[refund.status].name}
        </li>
        <li>
          <strong>Motivo:</strong> {REFUND_REASONS[refund.reason]}
        </li>
      </ul>

      <hr />

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Email</th>
            <th>Comentário</th>
            <th>Data</th>
            <th>Detalhes</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log.id}>
              <td>{log.user_email}</td>
              <td>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: highlightWords(log.comment),
                  }}
                />

                {log.comment.startsWith('Erro ao requisitar') &&
                  log.data.manually_refunded === false && (
                    <ContextButtons refetch={refetch} refundLog={log} />
                  )}
              </td>
              <td>{formatDateTimeUsingSlashes(log.inserted_at)}</td>
              <td>
                <pre
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: highlightWords(JSON.stringify(log.data, null, 2)),
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <hr />

      {refund.status !== 'completed' &&
        refund.status !== 'closed' &&
        movingToClosed && (
          <CloseRefundModal
            refund={refund}
            onClose={() => {
              setMovingToClosed(false);
            }}
            reload={reload}
          />
        )}

      <section className="form-actions">
        <Button variant="secondary" onClick={onClose}>
          Voltar
        </Button>

        {refund.status === 'in_progress' && (
          <Button
            variant="warning"
            type="submit"
            onClick={() => {
              confirm({
                title: `Devolução - ID #${refund.id}`,
                text: 'Tem certeza que deseja completar o processo de devolução? Faça as transferências monetárias necessárias e lembre-se de atualizar os adjustments na aba "movimentações financeiras" do pedido antes de continuar.',
                onConfirm: () => {
                  API.Refund.complete(refund.id)
                    .then((response) => {
                      reload(response);
                    })
                    .catch((error) => {
                      alert({
                        title: 'Erro',
                        text: `Não foi possível completar a devolução: ${error.message}`,
                      });
                    });
                },
              });
            }}
          >
            Completar processo
          </Button>
        )}

        {refund.status !== 'completed' && refund.status !== 'closed' && (
          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              setMovingToClosed(true);
            }}
          >
            Fechar processo
          </Button>
        )}
      </section>
    </div>
  );
};

export default RefundForm;
