import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'components/Form/Select';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import API from 'api';

import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';
import CustomFields from 'components/CustomFields';
import Input from 'components/Form/Input';
import Loading from 'components/Loading/Loading';
import Switch from 'components/Form/Switch';
import ParticipantFields from 'components/ParticipantFields';
import TextArea from 'components/Form/TextArea';
import VariantForm from 'pages/products/components/Products/VariantForm';

import { alert, confirm } from 'lib/notifications';
import { storeActions } from 'store';
import { defaultAdditionalInformation } from './constants';

const ProductForm = ({ onSave, onClose, resource }) => {
  const [optionProductTypes, setOptionProductTypes] = useState({});
  const [participantFields, setParticipantFields] = useState(
    resource?.participant_fields || [],
  );
  const [customFields, setCustomFields] = useState(
    resource?.custom_fields || [],
  );
  const dispatch = useDispatch();

  const newProduct = {
    custom_fields: [],
    hidden: true,
    participant_fields: [],
    product_types: [],
    variants: [],
  };

  const product = resource || newProduct;

  const { register, handleSubmit } = useForm({
    defaultValues: product,
  });

  const onSubmit = (data) => {
    const params = {
      ...data,
      participant_fields: participantFields.filter((field) => !field.deleted),
      custom_fields: customFields.filter((field) => !field.deleted),
    };
    params.product_types = Object.keys(optionProductTypes)
      .filter((key) => optionProductTypes[key])
      .map((key) => ({ id: key }));

    params.country_code = 'br';
    onSave(params);
  };

  const productHasType = (id) =>
    !!product.product_types.find((dt) => dt.id === id);

  const fetch = async (res, params = {}) => {
    const result = await res.all(params);
    const { data: body } = result;

    const types = {};
    body.data.forEach((productType) => {
      types[productType.id] = productHasType(productType.id);
    });
    setOptionProductTypes(types);

    return body.data;
  };

  const { data: productTypes, isLoading } = useQuery('product-types', () =>
    fetch(API.ProductType),
  );

  if (!productTypes || isLoading) {
    return <Loading />;
  }

  const defaultTab =
    product.variants.length > 0 ? product.variants[0].label : 'new-variant';

  const onDeleteProduct = () => {
    confirm({
      title: 'Atenção',
      text: 'Você tem certeza que deseja excluir este produto? Esta operação não poderá ser desfeita.',
      onConfirm: () => {
        API.Product.destroy(product.id)
          .then(() => {
            dispatch(storeActions.form.setFormStateToUnchanged());
            const callback = () => {
              onClose();
            };
            alert({
              text: 'Produto excluído com sucesso',
              icon: 'success',
              callback,
            });
          })
          .catch(() => {
            alert({ text: 'Não foi possível excluir o produto' });
          });
      },
    });
  };

  const compare = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            {resource && resource.id && (
              <>
                <Form.Label>ID</Form.Label>
                <ClipboardCopy copyText={resource.id} ariaLabel="Product ID" />
              </>
            )}

            <Input
              label="Nome"
              register={register('title', { required: true })}
            />

            <TextArea
              label="Descrição"
              register={register('description', { required: true })}
            />

            <Row>
              <Col>
                <Input
                  label="Link para documentação"
                  register={register('documentation')}
                />
              </Col>
              <Col>
                <Input
                  label="Meta descrição (SEO)"
                  maxLength={160}
                  register={register('meta_description')}
                />
              </Col>
              <Col>
                <Select
                  label="Tipo de line item"
                  options={[
                    { text: 'Variante', value: 'variant' },
                    { text: 'Cartão-presente', value: 'gift_card' },
                  ]}
                  register={register('line_item_type', { required: true })}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: product.id ? '0' : '12px' }}>
                <hr />
                <h4>O que pedir para cada participante?</h4>
                <ParticipantFields
                  fields={participantFields}
                  onChange={(fields) => setParticipantFields(fields)}
                />
                <hr />
              </Col>
            </Row>
          </Col>

          <Col>
            <Input
              label="Slug"
              register={register('slug', { required: true })}
            />

            <Row className="mb-0">
              <Col xs={2}>
                <Input
                  label="Score"
                  type="number"
                  register={register('score', { required: true })}
                />
              </Col>
              <Col>
                <Input
                  label="Imagem"
                  register={register('image_url', { required: true })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="mb-3">Status</h5>

                <Switch
                  label="Ativo?"
                  disabled={!resource || resource.variants.length === 0}
                  register={register('active')}
                />

                <Switch
                  label="Esconder na busca?"
                  disabled={!resource || resource.variants.length === 0}
                  register={register('hidden')}
                />

                <div className="special-product-container">
                  <Switch
                    label="Produto especial?"
                    disabled={!resource || resource.variants.length === 0}
                    register={register('special_product')}
                  />

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        Dica: para que um produto seja{' '}
                        <a
                          href="https://www.notion.so/makemydate/Documenta-o-4afd35bf466840e78cf1a91aa1251ec4?pvs=4#95aa1521d16847aebd47ce06f6c6b33e"
                          target="_blank"
                        >
                          especial
                        </a>
                        , ele precisa ter ao menos uma variante
                      </Tooltip>
                    }
                  >
                    <div
                      className="special-product-information"
                      onClick={() =>
                        window.open(
                          'https://www.notion.so/makemydate/Documenta-o-4afd35bf466840e78cf1a91aa1251ec4?pvs=4#95aa1521d16847aebd47ce06f6c6b33e',
                          '_blank',
                        )
                      }
                    >
                      <i className="fa-solid fa-info"></i>
                    </div>
                  </OverlayTrigger>
                </div>
              </Col>
              <Col>
                <h5 className="mb-3">Tipos do produto</h5>

                {productTypes.map((productType) => (
                  <Switch
                    key={productType.id}
                    label={productType.name}
                    checked={optionProductTypes[productType.id]}
                    onChange={() => {
                      const types = { ...optionProductTypes };
                      types[productType.id] = !types[productType.id];
                      setOptionProductTypes(types);
                    }}
                  />
                ))}
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: product.id ? '74px' : '0' }}>
                <hr />
                <h4>Perguntas extras</h4>
                <CustomFields
                  fields={customFields}
                  onChange={(fields) => setCustomFields(fields)}
                />
                <hr />
              </Col>
            </Row>
          </Col>
        </Row>

        <section className="form-actions">
          <Button variant="danger" type="button" onClick={onDeleteProduct}>
            <i className="fa fa-trash"></i>&nbsp; Excluir
          </Button>

          <Button variant="secondary" onClick={onClose}>
            Voltar
          </Button>

          <Button variant="primary" type="submit">
            <i className="fa fa-save"></i>&nbsp; Salvar produto
          </Button>
        </section>
      </Form>

      {product.id && product.variants.length > 0 && (
        <>
          <br />
          <h2>Variantes</h2>
          <hr />
        </>
      )}

      {product.id && (
        <Form.Group className="mb-3">
          <Tabs defaultActiveKey={defaultTab} className="mb-3">
            {product.variants.sort(compare).map((variant) => (
              <Tab
                key={variant.label}
                eventKey={variant.label}
                title={`${variant.title} #${variant.id}`}
              >
                <VariantForm
                  product={product}
                  variant={variant}
                  onClose={onClose}
                />
              </Tab>
            ))}

            <Tab eventKey="new-variant" title={<i className="fa fa-plus"></i>}>
              <VariantForm
                product={product}
                variant={{
                  additional_information: defaultAdditionalInformation,
                }}
                onClose={onClose}
              />
            </Tab>
          </Tabs>
        </Form.Group>
      )}
    </>
  );
};

export default ProductForm;
