/* eslint-disable no-bitwise */
import Resource from '../resource';

export default class SpecialProduct extends Resource {
  constructor() {
    super({ rootKey: 'product', uri: '/products' });
  }

  all(params = {}) {
    return this.client.get('/special-products', { params });
  }

  find(id) {
    return this.client.get(`/special-products/${id}`);
  }
}
