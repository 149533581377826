import API from 'api';

import ResponsibleForm from 'pages/products/components/Responsibles/ResponsibleForm';
import Responsibles from 'pages/products/components/Responsibles/Responsibles';

const ResponsiblesController = {
  basePath: '/produtos/responsaveis',
  form: ResponsibleForm,
  page: Responsibles,
  resourceAPI: API.Responsible,
};

export default ResponsiblesController;
