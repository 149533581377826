import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';

import { ORDER_STATUSES } from 'lib/status';
import { weekDaysString } from 'lib/week-days';
import { formatDateTime, formatDateTimeUsingSlashes } from 'lib/date-utils';
import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';

const LogDetails = ({ data, onClose }) => {
  const getSafeValue = (value) => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };

  const fetchUsers = async () => {
    const response = await API.User.all({ page_size: 10000 });
    return response.data.data;
  };

  const { data: users, isLoading: isLoadingUsers } = useQuery(
    [`users-without-pagination`],
    fetchUsers,
  );

  if (isLoadingUsers || !users) {
    return <Loading />;
  }

  // Takes an array of hash like [{name: "John", email: "john@gmail.com"}]
  // and transforms it into a list of values, like this:
  // [["John", "john@gmail.com"], [...]]
  const builTableRowsForArray = (value) =>
    value.map((listItem) => Object.keys(listItem).map((k) => listItem[k]));

  const isDateTime = (dateTimeString) => {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z)?$/;
    return regex.test(dateTimeString);
  };

  const formatKey = (key, value) => {
    if (key === 'ID do usuário') {
      return (
        <a
          href={`/usuarios/${value}/editar`}
          key={`${key}-${value}`}
          target="_blank"
        >
          {key}
        </a>
      );
    }

    return key;
  };

  const formatValue = (key, value) => {
    if (key === 'Dias da semana') {
      return weekDaysString(value);
    }

    if (isDateTime(value)) {
      return formatDateTime(value);
    }

    if (
      [
        'ID do usuário',
        'ID do item',
        'ID do log',
        'ID do cartão presente',
      ].includes(key)
    ) {
      return (
        <div className="w-75">
          <ClipboardCopy copyText={value} ariaLabel={key} />
        </div>
      );
    }

    if (key === 'Status anterior' || key === 'Novo status') {
      return (
        <span className={`badge ${ORDER_STATUSES[value].klass}`}>
          {ORDER_STATUSES[value].name}
        </span>
      );
    }

    return value;
  };

  const buildMainSection = () => {
    const { lineItemId, log, order } = data;

    const lineItem = order?.line_items?.find((item) => item.id === lineItemId);

    const logUser = log.user ? users.find((u) => u.id === log.user.id) : null;

    return (
      <Row className="mb-2">
        {logUser && (
          <Col>
            <div className="card">
              <div className="card-header">
                <i className="fa-solid fa-user"></i> &nbsp; Usuário
              </div>
              <div className="card-body">
                <div className="p-3 user-box">
                  <div className="avatar mb-3">
                    <img
                      className="rounded-circle img-fluid"
                      src={logUser.avatar}
                      alt="user avatar"
                    />
                  </div>
                  <div>{`${logUser.first_name} ${logUser.last_name}`}</div>
                  <div>{logUser.email}</div>
                </div>
              </div>
            </div>
          </Col>
        )}

        {lineItem && lineItem.type === 'gift_card' && (
          <Col>
            <div className="card">
              <div className="card-header">
                <i className="fa-solid fa-gift"></i> &nbsp; Cartão-presente
              </div>
              <div className="card-body">
                <p>
                  Para {lineItem.recipient_name} ({lineItem.recipient_email})
                </p>
                <textarea className="w-100 p-3" disabled>
                  {lineItem.message}
                </textarea>
              </div>
            </div>
          </Col>
        )}

        {lineItem && lineItem.children?.length > 0 && (
          <Col>
            <div className="card">
              <div className="card-header">
                <i className="fa-solid fa-cart-shopping"></i> &nbsp; Pedidos
                adicionais
              </div>
              <div className="card-body">
                {lineItem.children.map((child) => (
                  <p key={child.id}>
                    {child.quantity} x {child.title}
                  </p>
                ))}
              </div>
            </div>
          </Col>
        )}
      </Row>
    );
  };

  const { log } = data;

  return (
    <Modal className="log-details-modal" title={data.label} onClose={onClose}>
      {buildMainSection()}

      <div className="card">
        <div className="card-header">
          <i className="fa-solid fa-database"></i> &nbsp; Logs
          <div className="text-muted small mb-0 float-end me-5 attempted-at">
            <i className="far fa-clock"></i>&nbsp;
            {formatDateTimeUsingSlashes(log.inserted_at)}
          </div>
        </div>
        <div className="card-body">
          <table className="table table-hover w-100 fs-7">
            <tbody>
              <tr>
                <td>ID do log</td>
                <td>{formatValue('ID do log', log.id)}</td>
              </tr>
              <tr>
                <td>Comentário</td>
                <td>{log.comment}</td>
              </tr>
              <tr>
                <td>Criado em</td>
                <td>{formatDateTime(log.inserted_at)}</td>
              </tr>

              {log.extra_information.map((info) => {
                const value = getSafeValue(info.value);

                if (!value) {
                  return null;
                }

                let type = null;
                if (typeof value === 'string' || typeof value === 'number') {
                  type = 'single-value';
                }

                if (!type && Array.isArray(value)) {
                  type = 'list';
                }

                if (!type && typeof value === 'object') {
                  type = 'hash';
                }

                return (
                  <tr key={`${log.id}-${info.value}`}>
                    <td>{formatKey(info.text, value)}</td>
                    {type === 'single-value' && (
                      <td>{formatValue(info.text, value)}</td>
                    )}

                    {type === 'list' && (
                      <table className="table table-bordered mb-0">
                        <thead>
                          <tr>
                            {Object.keys(value[0]).map((k) => (
                              <th scope="col">{k}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {builTableRowsForArray(value).map((row) => (
                            <tr>
                              {row.map((cell) => (
                                <td>{cell}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    {type === 'hash' && (
                      <table className="table table-bordered mb-0">
                        <tbody>
                          {Object.keys(value).map((k) => (
                            <tr>
                              <td>{formatKey(k, value[k])}</td>
                              <td>{formatValue(k, value[k])}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <section className="form-actions">
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
      </section>
    </Modal>
  );
};

export default LogDetails;
