import React, { useState } from 'react';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import Filters from 'components/Filters/Filters';
import { formatDate } from 'lib/date-utils';
import { NamedRoutes } from 'config/routes/named-routes';
import { REFUND_STATUSES } from 'lib/status';
import { REFUND_REASONS } from 'lib/constants';

const Refunds = () => {
  const [filters, setFilters] = useState({});

  const filterFields = [
    {
      attribute: 'order_number',
      type: 'text',
      label: 'Número do pedido',
      id: 'order-number-filter',
      klass: 'order-number-filter-container',
    },
    {
      attribute: 'status',
      type: 'select',
      label: 'Status',
      id: 'status-filter',
      klass: 'status-filter-container',
      options: [
        { text: 'Todos', value: '' },
        { text: 'Criado', value: 'created' },
        { text: 'Em andamento', value: 'in_progress' },
        { text: 'Concluído', value: 'completed' },
        { text: 'Fechado', value: 'closed' },
      ],
    },
  ];

  const sortingFields = {
    number: { direction: 'off' },
    status: { direction: 'off' },
    protocol: { direction: 'off' },
    inserted_at: { direction: 'desc' },
  };

  return (
    <>
      <Filters fields={filterFields} applyFilters={setFilters} />
      <hr />

      <CrudList
        title="Reembolsos"
        columns={{
          id: {
            text: 'ID',
            render: (resource) => (
              <a href={NamedRoutes.refund_path(resource.id)} alt={resource.id}>
                {resource.id}
              </a>
            ),
          },
          order_number: {
            text: 'Número do pedido',
            render: (resource) => (
              <a
                href={NamedRoutes.order_path(resource.order_number)}
                alt={resource.order_number}
              >
                {resource.order_number}
              </a>
            ),
          },
          price: {
            text: 'Preço do pedido',
            render: (resource) => resource.billing.formatted.total_price,
          },
          value_to_refund: {
            text: 'Falta devolver',
            render: (resource) => resource.billing.formatted.value_to_refund,
          },
          status: {
            text: 'Status',
            render: (resource) => (
              <span
                className={`badge ${REFUND_STATUSES[resource.status].klass}`}
              >
                {REFUND_STATUSES[resource.status].name}
              </span>
            ),
          },
          reason: {
            text: 'Motivo',
            render: (resource) => REFUND_REASONS[resource.reason],
          },
          inserted_at: {
            text: 'Criado em',
            render: (resource) => formatDate(resource.inserted_at),
          },
        }}
        resourceAPI={API.Refund}
        hideId
        hideTitle
        hideDeleteButton
        hideNewButton
        filters={filters}
        sortingFields={sortingFields}
      />

      <br />
      <br />
      <hr />
      <br />

      <p>Algumas considerações importantes:</p>

      <ul>
        <li>
          Quando um pedido é cancelado mas não havia nenhum pagamento feito, não
          iniciamos um processo de devolução.
        </li>
        <li>
          Se todos os pagamentos do pedido foram feitos com store credits, assim
          que o processo de refund é iniciado os créditos são devolvidos
          automaticamente e o processo de refund é concluído.
        </li>
        <li>
          Caso existam pagamentos (pode ou não ter store credits aplicados), o
          processo de refund é criado assim que o pedido é cancelado, mas nada
          acontece automaticamente. Apenas quando o admin mover o processo para
          <strong> em andamento </strong> é que os créditos são devolvidos
          automaticamente e os pagamentos ficam pendentes, porque devolvê-los
          exige intervenção humana.
        </li>
        <li>
          Antes de finalizar um processo de devolução, é obrigatório informar
          qual foi o método de pagamento utilizado. Certifique-se de adicionar
          toda e qualquer informação que julgar relevante no campo de texto.
        </li>
      </ul>
    </>
  );
};

export default Refunds;
