import React, { useContext, useState, useMemo } from 'react';

import API from 'api';

import Storage from 'lib/storage';
import { publicClient } from 'api/client';
import { decodeJwt } from 'lib/jwt';
import {
  setAccessToken,
  removeAccessToken,
  removeRefreshToken,
  setRefreshToken,
} from 'api/token-service';

const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const login = async (email, password) => {
    setLoading(true);

    try {
      // Sign in request must not use interceptors, so we cannot use the
      // regular axios API used in all other requests.
      const response = await publicClient.post('session/new', {
        email,
        password,
      });
      const accessToken = response.data.access_token;
      const refreshToken = response.data.refresh_token;

      if (!response.data.user.admin) {
        window.location.reload();
      }

      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      const userId = decodeJwt(accessToken).sub;
      const findResponse = await API.User.find(userId);

      const body = findResponse.data;
      const user = body.data;

      updateProfileDataInMemory(user);

      return { loggedIn: true };
    } catch (error) {
      setLoading(false);
      logout();
      return { loggedIn: false, error };
    }
  };

  const logout = () => {
    removeAccessToken();
    removeRefreshToken();
    removeCurrentUser();
  };

  const updateProfileDataInMemory = (user) => {
    const data = {
      id: user.id,
      avatar: user.avatar,
      firstName: user.first_name,
      lastName: user.last_name,
    };
    const options = { parseToJson: true };
    Storage.set('user', data, options);
  };

  const getCurrentUser = () =>
    Storage.get('user', { parseFromJson: true, defaultValue: null });

  const removeCurrentUser = () => Storage.remove('user');

  const currentUser = getCurrentUser();

  const value = useMemo(
    () => ({
      login,
      logout,
      currentUser,
      updateProfileDataInMemory,
    }),
    [currentUser],
  );

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
