import React from 'react';

import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import { capitalize } from 'lib/string';
import { ORDER_STATUSES } from 'lib/status';

import './styles.scss';

export default function IncomeTableLine({ adjustment }) {
  return (
    <tr>
      <td>
        <a href={`/pedidos/${adjustment.order.number}/editar`}>
          {adjustment.order.number}
        </a>
      </td>
      <td>
        <img src={adjustment.order.user.avatar} alt="Avatar" />
        {`${
          adjustment.order.user.first_name
        } ${adjustment.order.user.last_name.charAt(0)}.`}
      </td>
      <td className="line-items">
        TODO
        {/* {adjustment.order.product.title} -{' '}
        {adjustment.order.billing.formatted.price}
        {adjustment.order.comment && <small>{adjustment.order.comment}</small>} */}
      </td>
      <td>
        <span className={`status-${adjustment.order.status.replace('_', '-')}`}>
          {ORDER_STATUSES[adjustment.order.status].name}
        </span>
      </td>
      <td>{formatDateTimeUsingSlashes(adjustment.order.inserted_at)}</td>
      <td>{formatDateTimeUsingSlashes(adjustment.paid_at)}</td>
      <td>
        <strong>{adjustment.formatted.value}</strong>
      </td>
      <td>{capitalize(adjustment.method, '_')}</td>
    </tr>
  );
}
