import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Input from 'components/Form/Input';
import Select from 'components/Form/Select';

const AddressTab = ({ register, resource, states }) => (
  <>
    <Row>
      <Col>
        <Input
          label="Endereço"
          placeholder="Endereço"
          register={register('street_address')}
        />
      </Col>
      <Col>
        <Input
          label="Número"
          placeholder="Número"
          register={register('address_number')}
        />
      </Col>
      <Col>
        <Input
          label="Complemento"
          placeholder="Complemento"
          register={register('address_complement')}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Input
          label="Cidade"
          placeholder="Cidade"
          register={register('city')}
        />
      </Col>
      <Col>
        <Select
          label="Estado"
          value={resource.state_id}
          options={states.map((state) => ({
            text: state.name,
            value: state.id,
          }))}
        />
      </Col>
      <Col>
        <Input label="CEP" placeholder="CEP" register={register('zip_code')} />
      </Col>
    </Row>
  </>
);

export default AddressTab;
