import React from 'react';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NamedRoutes } from 'config/routes/named-routes';

const MessageForm = ({ onSave, onClose, resource }) => {
  const { handleSubmit } = useForm({
    defaultValues: resource || {},
  });

  const onSubmit = (data) => {
    onSave(data);
  };

  const buildLabel = (title, text, url) => {
    const link = (
      <a href={url} alt={text}>
        {text}
      </a>
    );

    const buildText = () => (url ? link : text);

    return (
      <div key={title + text}>
        <Form.Label>
          <strong>{title}: </strong>
          {buildText()}
        </Form.Label>
        <br />
      </div>
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Row>
          <Col>
            {buildLabel(
              'Nome',
              resource.name,
              resource.user_id ? NamedRoutes.user_path(resource.user_id) : null,
            )}
            {buildLabel('Email', resource.email)}
            {resource.user &&
              buildLabel(
                'Usuário',
                `${resource.user.first_name} ${resource.user.last_name} <${resource.user.email}>`,
              )}
            {buildLabel('Assunto', resource.subject)}
            <Form.Group className="mb-3">
              <Form.Label>Mensagem</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                readOnly
                value={resource.message}
              />
            </Form.Group>
          </Col>
        </Row>

        <section className="form-actions">
          <Button variant="secondary" onClick={onClose}>
            Voltar
          </Button>
        </section>
      </Form.Group>
    </Form>
  );
};

export default MessageForm;
