/* eslint-disable react/no-array-index-key */
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import Plan from './Plan';

import './styles.scss';

const Plans = ({ order, lineItem, onClose }) => {
  const fetchPossibleVariants = async () => {
    const result = await API.VariantChange.all(lineItem.id);
    return result.data;
  };

  const { data: plans, isLoading } = useQuery(
    ['change-variant-preview', lineItem.id],
    () => fetchPossibleVariants(lineItem.id),
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <p className="current-monetary-situation">
        {order.billing.value_paid && (
          <span>O usuário pagou {order.billing.value_paid?.formatted}</span>
        )}
        {plans.length > 0 && (
          <span>
            &nbsp;e possui&nbsp;
            {plans[0]?.formatted?.available_store_credits} em créditos
          </span>
        )}
      </p>

      <section className="plans-container">
        {plans.length > 0 &&
          plans.map((plan, index) => (
            <Plan
              plan={plan}
              index={index}
              key={index}
              lineItem={lineItem}
              onClose={onClose}
            />
          ))}

        {plans.length === 0 && (
          <Alert variant="warning">
            Não há nenhuma outra variante para este produto
          </Alert>
        )}
      </section>

      <section className="form-actions">
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
      </section>
    </>
  );
};

export default Plans;
