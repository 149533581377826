/* eslint-disable no-console */

/*
 * Use devSlice from Redux to control the debug mode. To know more, see
 * the dev page.
 */

export const consoleLog = (options = {}) => {
  const { title, text, table, debugMode } = options;

  if (debugMode) {
    if (title && text) {
      console.log(title, text);
    } else if (title) {
      console.log(title);
    } else if (text) {
      console.log(text);
    }

    if (table) {
      console.table(table);
    }
  }
};
