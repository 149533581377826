import React from 'react';
import Row from 'react-bootstrap/Row';
import { useQuery } from 'react-query';

import API from 'api';

import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';
import Loading from 'components/Loading/Loading';
import { formatDateTime } from 'lib/date-utils';

const GiftCardOverview = ({ lineItem, buildLabel }) => {
  const fetchGiftCard = async (giftCardId) => {
    if (giftCardId) {
      const response = await API.GiftCard.find(giftCardId);
      const { data: body } = response;

      return body.data;
    }

    return null;
  };

  const { data: giftCard, isLoading } = useQuery(['find-gift-card'], async () =>
    fetchGiftCard(lineItem.gift_card_id),
  );

  return (
    <Row>
      <div className="card px-0 mb-3">
        <div className="card-header">
          <i className="fa fa-hand-holding-dollar"></i> &nbsp; Dados do
          cartão-presente
        </div>
        <div className="card-body">
          <div className="card-title">
            <label className="form-label">
              <strong>#{lineItem.number}</strong>
            </label>
          </div>
          {lineItem.redeemed_at && (
            <span className="badge mx-1 bg-success">Resgatado</span>
          )}

          {!lineItem.redeemed_at && (
            <span className="badge mx-1 bg-warning">Resgate pendente</span>
          )}

          {giftCard && giftCard.sent && (
            <span className="badge mx-1 bg-success">Email enviado</span>
          )}

          {giftCard && !giftCard.sent && (
            <span className="badge mx-1 bg-warning">
              Envio de email pendente
            </span>
          )}

          <hr />

          <div className="card-title">
            {buildLabel(
              'Para',
              `${lineItem.recipient_name} <${lineItem.recipient_email}>`,
            )}
          </div>

          {lineItem.redeemed_at && (
            <div className="card-title">
              {buildLabel('Resgatado em', formatDateTime(lineItem.redeemed_at))}
            </div>
          )}

          {lineItem.is_scheduled && (
            <div className="card-title">
              {buildLabel(
                'Envio agendado para',
                formatDateTime(lineItem.scheduled_date),
              )}
            </div>
          )}

          <div>
            <strong>Mensagem:</strong> <br />
            <textarea
              className="mt-2 w-100"
              disabled
              value={lineItem.message}
            ></textarea>
          </div>
          {isLoading && <Loading />}
          {giftCard && (
            <>
              <hr />
              <h4>Cartão-presente</h4>

              <div className="card-title">{buildLabel('ID', giftCard.id)}</div>

              <div className="card-title">
                {buildLabel('Código', giftCard.code)}
              </div>

              <div className="card-title">
                {buildLabel('Expira em', formatDateTime(giftCard.expires_at))}
              </div>
            </>
          )}
        </div>
      </div>

      <strong>ID do item de pedido:</strong>
      <ClipboardCopy copyText={lineItem.id} ariaLabel="LineItem ID" />
    </Row>
  );
};

export default GiftCardOverview;
