import React from 'react';

// Builds a table specific to show logs:
//
// Columns example:
//
// [
//   {
//      header: 'Valor',
//      attribute: '',
//      size: 15,
//   },
//   {
//      header: 'Operação',
//      attribute: 'operation',
//      size: 15,
//      transform: (value) => value === 'debit' ? 'Débito' : 'Crédito',
//   },
//   {
//      header: 'Pedido',
//      attribute: 'order',
//      size: 20,
//   },
//   {
//      header: 'Data',
//      attribute: 'inserted_at',
//      size: 32,
//      transform: (value) => formatDateTime(value),
//   },
// ]
//
// Items is an array of objects from which we are going to fetch data to
// populate the table.
export const buildLogsTable = (columns, items) => (
  <table className="table table-borderless">
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={column.header}>{column.header}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {items.map((item) => (
        <tr>
          {columns.map((column) => {
            const value = item[column.attribute];
            const formattedValue = column.transform
              ? column.transform(value)
              : value;

            return <td key={item[column.attribute]}>{formattedValue}</td>;
          })}
        </tr>
      ))}
    </tbody>
  </table>
);
