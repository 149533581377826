import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';

import API from 'api';

import Modal from 'components/Modal/Modal';
import { alert } from 'lib/notifications';

const CloseRefundModal = ({ refund, onClose, reload }) => {
  const [reason, setReason] = useState('');

  const onClickHandler = () => {
    if (reason.length === 0) {
      alert({ text: 'Digite um motivo antes de prosseguir' });
    } else {
      API.Refund.destroy(refund.id, { refund: { reason } }).then((response) => {
        onClose();
        reload(response);
      });
    }
  };

  return (
    <Modal
      title={`Fechando processo de devolução - ${refund.order_number}`}
      onClose={onClose}
    >
      <Form.Label htmlFor={`close-refund-reason-${refund.id}`}>
        Motivo
      </Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        id={`close-refund-comment-${refund.id}`}
        aria-describedby="Fechando processo de devolução"
        onChange={(e) => {
          setReason(e.currentTarget.value);
        }}
        onKeyDown={(e) => {
          setReason(e.currentTarget.value);
        }}
      />

      <section className="form-actions">
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>

        <Button variant="primary" onClick={onClickHandler}>
          Concluir
        </Button>
      </section>
    </Modal>
  );
};

export default CloseRefundModal;
