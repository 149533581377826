/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';

import API from 'api';

import { buildLogsTable } from 'lib/logs-table';
import {
  yearMonthDayStringToFormatedUTCDate,
  formatDateTimeUsingSlashes,
} from 'lib/date-utils';
import { OPERATIONS } from 'lib/constants';

const OPENED_STATE = 'opened';
const CLOSED_STATE = 'closed';

const StoreCreditRow = ({ storeCredit, onDeleteHandler }) => {
  const [state, setState] = useState(CLOSED_STATE);
  const [logs, setLogs] = useState();

  const onShowLogsHandler = () => {
    if (!logs) {
      API.StoreCreditLog.all(storeCredit.id).then((response) => {
        const { data: body } = response;
        setLogs(body.data);
      });
    }

    setState(OPENED_STATE);
  };

  const onHideLogsHandler = () => {
    setState(CLOSED_STATE);
  };

  const toggleLogs = () =>
    state === OPENED_STATE ? onHideLogsHandler() : onShowLogsHandler();

  const buildLogs = () =>
    buildLogsTable(
      [
        {
          header: 'Valor',
          attribute: 'formatted',
          size: 15,
          transform: (formatted) => formatted.amount,
        },
        {
          header: 'Operação',
          attribute: 'operation',
          size: 15,
          transform: (value) => {
            if (
              value === 'debit' ||
              value === 'expiration' ||
              value === 'inactivation'
            ) {
              return (
                <span className="negative-operation">{OPERATIONS[value]}</span>
              );
            }

            if (value === 'credit' || value === 'refund') {
              return (
                <span className="positive-operation">{OPERATIONS[value]}</span>
              );
            }

            return OPERATIONS[value];
          },
        },
        {
          header: 'Pedido',
          attribute: 'order',
          size: 20,
        },
        {
          header: 'Data',
          attribute: 'inserted_at',
          size: 32,
          transform: (value) => formatDateTimeUsingSlashes(value),
        },
      ],
      logs,
    );

  const buildStoreCreditRow = () => (
    <tr>
      <td>
        <span
          className={`badge bg-${storeCredit.active ? 'success' : 'secondary'}`}
        >
          {storeCredit.active ? 'Ativo' : 'Inativo'}
        </span>
      </td>
      <td>{storeCredit.formatted.amount}</td>
      <td>{storeCredit.formatted.remaining_amount}</td>
      <td>
        {storeCredit.expires_at
          ? yearMonthDayStringToFormatedUTCDate(storeCredit.expires_at)
          : 'Não expira'}
      </td>
      <td style={{ minWidth: '25px', textAlign: 'center' }}>
        <i
          className="fas fa-clipboard-list cursor-pointer"
          onClick={toggleLogs}
          title="Logs"
        ></i>
      </td>
      <td style={{ minWidth: '25px', textAlign: 'center' }}>
        {storeCredit.active && (
          <i
            className="far fa-trash-alt cursor-pointer"
            onClick={() => onDeleteHandler(storeCredit)}
            title="Inativar"
          ></i>
        )}
      </td>
    </tr>
  );

  const logsContainer = () => (
    <tr>
      <td colSpan={6} style={{ textAlign: 'center' }}>
        {buildLogs()}
      </td>
    </tr>
  );

  return (
    <React.Fragment key={storeCredit.id}>
      {buildStoreCreditRow()}
      {state === OPENED_STATE && logs && logsContainer()}
    </React.Fragment>
  );
};

export default StoreCreditRow;
