import React from 'react';
import { useAuth } from 'contexts/AuthContext';

import Breadcrumb from 'components/TopBar/Breadcrumb';
import ButtonsArea from 'components/TopBar/ButtonsArea';
import UserDisplay from 'components/TopBar/UserDisplay';

import './styles.scss';

const TopBar = () => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <div></div>;
  }

  const userName = `${currentUser.firstName} ${currentUser.lastName}`;

  return (
    <header className="top-bar">
      <UserDisplay userName={userName} userId={currentUser.id} />
      <Breadcrumb />
      <ButtonsArea />
    </header>
  );
};

export default TopBar;
