import React from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';

import { storeActions } from 'store';

const Input = (props) => {
  const { containerClass, label, onChange, register, type, ...attrs } = props;

  const dispatch = useDispatch();

  return (
    <Form.Group className={containerClass || 'mb-3'}>
      {label !== '' && <Form.Label>{label}</Form.Label>}
      <Form.Control
        {...attrs}
        {...register}
        type={type || 'text'}
        onChange={(e) => {
          dispatch(storeActions.form.setFormStateToModified());

          if (onChange) {
            onChange(e);
          }
        }}
      />
    </Form.Group>
  );
};

export default Input;
