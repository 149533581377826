/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { FORM_STATES } from 'components/Form/formStates';

const formSlice = createSlice({
  name: 'form',
  initialState: {
    formState: FORM_STATES.unchanged,
  },
  reducers: {
    setFormStateToModified(state) {
      state.formState = FORM_STATES.modified;
    },

    setFormStateToUnchanged(state) {
      state.formState = FORM_STATES.unchanged;
    },
  },
});

export const formActions = formSlice.actions;

export default formSlice;
