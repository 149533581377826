import API from 'api';

import MessageForm from 'pages/messages/components/MessageForm';
import Messages from 'pages/messages/messages';

const MessagesController = {
  basePath: '/mensagens',
  form: MessageForm,
  page: Messages,
  resourceAPI: API.Message,
};

export default MessagesController;
