import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { LINE_ITEM_STATUSES } from 'lib/status';

import API from 'api';

import { alert, confirm } from 'lib/notifications';
import TooltipIcon from 'components/TooltipIcon/TooltipIcon';
import Modal from 'components/Modal/Modal';
import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';
import ActionButton from '../shared/ActionButton';
import GiftCardOverview from '../overview/GiftCardOverview';
import PriceSummary from '../price-summary/PriceSummary';
import VariantOverview from '../overview/VariantOverview';
import RefundPreview from '../refund/RefundPreview';

import '../style.scss';

const OverviewTab = ({ buildLabel, lineItems, order, user, refetch }) => {
  const [refundState, setRefundState] = useState(false);
  const [refundList, setRefundList] = useState([]);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [cancellationState, setCancellationState] = useState(false);

  const orderActions = [];

  if (
    order.status === 'waiting_for_payment' &&
    order.billing.balance_due.in_cents > 0
  ) {
    orderActions.push({
      icon: 'fa-envelope',
      text: 'Email de pagamento',
      onClick: () => {
        API.Order.sendPaymentLinkByEmail(order.id).then(() => {
          alert({ text: 'Email enviado com sucesso!', icon: 'success' });
        });
      },
    });
  }

  const lineItemEligibleForRefund = lineItems.some(
    (item) => item.status === 'created' || item.status === 'booked',
  );

  const isOrderStatusEligibleForRefund =
    order.status === 'placed' ||
    order.status === 'waiting_for_payment' ||
    order.status === 'ready';

  if (
    order.billing.value_paid.in_cents > 0 &&
    lineItemEligibleForRefund &&
    isOrderStatusEligibleForRefund &&
    !refundState
  ) {
    orderActions.push({
      icon: 'fa-right-left',
      text: 'Solicitar devolução',
      onClick: () => {
        setRefundList([]);

        alert({
          text: 'Escolha os itens que quiser devolver e clique em "Devolver itens"',
          callback: () => {
            setRefundState(true);
          },
        });
      },
    });
  }

  if (
    order.billing.value_paid.in_cents === 0 &&
    lineItemEligibleForRefund &&
    !cancellationState
  ) {
    orderActions.push({
      icon: 'fa-cart-arrow-down',
      text: 'Remover do carrinho',
      onClick: () => {
        setCancellationState(true);
      },
    });
  }

  if (refundState && refundList.length > 0) {
    orderActions.push({
      icon: 'fa-rotate-left',
      text: 'Devolver itens',
      onClick: () => {
        setShowRefundModal(true);
      },
    });
  }

  if (refundState) {
    orderActions.push({
      icon: 'fa-rectangle-xmark',
      text: 'Cancelar devolução',
      onClick: () => {
        setRefundList([]);
        setRefundState(false);
      },
    });
  }

  if (cancellationState) {
    orderActions.push({
      icon: 'fa-rectangle-xmark',
      text: 'Anular cancelamento',
      onClick: () => {
        setCancellationState(false);
      },
    });
  }

  const buildLineItemLine = (lineItem) => (
    <>
      {refundState && (
        <div className="me-3">
          <Form.Check
            disabled={['completed', 'canceled', 'unfulfilled'].includes(
              lineItem.status,
            )}
            type="checkbox"
            onChange={(e) => {
              if (e.target.checked && !refundList.includes(lineItem.id)) {
                const newList = [...refundList, lineItem.id];
                setRefundList(newList);
              } else {
                const newList = refundList.filter((id) => id !== lineItem.id);
                setRefundList(newList);
              }
            }}
          />
        </div>
      )}

      {cancellationState && (
        <div className="me-3">
          <div
            className="remove-from-cart-button"
            onClick={() =>
              confirm({
                title: 'Cancelar item',
                text: 'Deseja cancelar este item?',
                onConfirm: () => {
                  API.Order.removeFromCart(order.id, lineItem.id, null)
                    .then(() => {
                      alert({
                        text: 'Item cancelado com sucesso!',
                        icon: 'success',
                        callback: () => {
                          setCancellationState(false);
                          refetch();
                        },
                      });
                    })
                    .catch((e) => {
                      alert({
                        text: `Não foi possível cancelar o item: ${e.message}`,
                      });
                    });
                },
              })
            }
          >
            <i
              className="fa fa-trash-alt"
              alt="Remover do carrinho"
              title="Remover do carrinho"
            ></i>
          </div>
        </div>
      )}
      <img
        className="overview-line-item-image"
        src={lineItem.image_url}
        alt={lineItem.title}
      />
      {lineItem.title}

      {lineItem.type === 'variant' && (
        <span>&nbsp;- {lineItem.billing.total.formatted}</span>
      )}

      <span
        className={`ml-10px badge ${LINE_ITEM_STATUSES[lineItem.status].klass}`}
      >
        {LINE_ITEM_STATUSES[lineItem.status].name}
      </span>

      {lineItem.has_change_request && (
        <TooltipIcon
          text="Este item possui pedidos de mudança pendentes"
          icon="fa-regular fa-comment-dots ms-2"
        />
      )}
    </>
  );

  const productData = () => (
    <Col className="col-4 order-column" style={{ height: 'fit-content' }}>
      <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <i className="fa fa-user"></i> &nbsp; Dados do cliente
          </Accordion.Header>
          <Accordion.Body>
            <div className="border-bottom p-3 d-flex flex-row align-items-center">
              <div className="avatar">
                <img
                  className="rounded-circle img-fluid"
                  src={user.avatar}
                  alt="user avatar"
                />
              </div>
              <strong className="ps-3">{`${user.first_name} ${user.last_name}`}</strong>
              <a
                className="ms-auto text-body"
                href={`/usuarios/${user.id}/editar`}
              >
                <i className="fa fa-arrow-right"></i>
              </a>
            </div>
            <div className="mt-3">
              <div className="d-flex flex-column">
                <strong className="pb-2">Contato</strong>
                <p>
                  <i className="fa fa-envelope"></i> {user.email}
                </p>
                <p>
                  <i className="fa fa-phone"></i> {user.phone_number}
                </p>
              </div>
              <br />
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {orderActions.length > 0 && (
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <i className="fa fa-fire-flame-curved"></i> &nbsp; Ações do pedido
            </Accordion.Header>
            <Accordion.Body>
              <div className="order-actions-card d-flex">
                {orderActions.map((action) => (
                  <ActionButton
                    key={action.text}
                    icon={action.icon}
                    text={action.text}
                    onClick={action.onClick}
                  />
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <i className="fa fa-comment-dollar"></i> &nbsp; Financeiro
          </Accordion.Header>
          <Accordion.Body>
            <PriceSummary order={order} />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <i className="fa-solid fa-gear"></i> &nbsp; Dados gerais
          </Accordion.Header>
          <Accordion.Body>
            <strong>ID do pedido:</strong>
            <ClipboardCopy copyText={order.id} ariaLabel="Order ID" />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Col>
  );

  if (lineItems.length === 0) {
    return (
      <Container className="overview-tab">
        <Row className="gx-5">
          <Col className="col-8 line-item-column">
            <Alert variant="warning">Não há itens nesse pedido</Alert>
          </Col>
          {productData()}
        </Row>
      </Container>
    );
  }

  return (
    <Container className="overview-tab">
      <Row className="gx-5">
        <Col className="col-8 line-item-column">
          {(refundState || cancellationState) && (
            <div className="line-item-container-for-refund">
              {lineItems.map((lineItem) => (
                <div
                  className="d-flex"
                  key={`overview-tab-build-line-item-${lineItem.id}`}
                >
                  <div className="line-item-line">
                    {buildLineItemLine(lineItem)}
                  </div>
                </div>
              ))}
            </div>
          )}

          {!refundState && !cancellationState && (
            <Accordion>
              {lineItems.map((lineItem, index) => (
                <Accordion.Item
                  key={`overview-tab-accordion-item-${lineItem.id}`}
                  eventKey={index}
                >
                  <Accordion.Header>
                    {buildLineItemLine(lineItem)}
                  </Accordion.Header>
                  <Accordion.Body>
                    {lineItem.type === 'variant' && (
                      <VariantOverview
                        order={order}
                        lineItem={lineItem}
                        buildLabel={buildLabel}
                        refetch={refetch}
                      />
                    )}

                    {lineItem.type === 'gift_card' && (
                      <GiftCardOverview
                        lineItem={lineItem}
                        buildLabel={buildLabel}
                      />
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </Col>
        {productData()}
      </Row>

      {showRefundModal && (
        <Modal title="Devolução de itens">
          <RefundPreview
            order={order}
            lineItems={lineItems}
            lineItemsIds={refundList}
            onClose={() => {
              setShowRefundModal(false);
              setRefundList([]);
              setRefundState(false);
              refetch();
            }}
          />
        </Modal>
      )}
    </Container>
  );
};

export default OverviewTab;
