import { setCookie, getCookie, removeCookie } from '../lib/cookies';
import Storage from '../lib/storage';

export const getAccessToken = () => Storage.get('access-token');
export const setAccessToken = (accessToken) =>
  Storage.set('access-token', accessToken);
export const removeAccessToken = () => Storage.remove('access-token');

export const getRefreshToken = () => getCookie('ruid');
export const setRefreshToken = (refreshToken) =>
  setCookie('ruid', refreshToken);
export const removeRefreshToken = () => removeCookie('ruid');
