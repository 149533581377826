import API from 'api';

import ProductTypeForm from 'pages/products/components/ProductTypes/ProductTypeForm';
import ProductTypes from 'pages/products/components/ProductTypes/ProductTypes';

const ProductTypesController = {
  basePath: '/produtos/tipos-de-produtos',
  form: ProductTypeForm,
  page: ProductTypes,
  resourceAPI: API.ProductType,
};

export default ProductTypesController;
