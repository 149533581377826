import React, { useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import './styles.scss';

const ClipboardCopy = ({ copyText, ariaLabel }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      const value = await navigator.clipboard.writeText(text);
      return value;
    }

    return document.execCommand('copy', true, text);
  };

  const handleCopyClick = () => {
    copyTextToClipboard(copyText).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2500);
    });
  };

  return (
    <InputGroup className="mb-3">
      <FormControl
        aria-label={ariaLabel}
        aria-describedby="basic-addon2"
        value={copyText}
        readOnly
      />
      <Button
        variant="outline-secondary"
        id="button-addon2"
        onClick={handleCopyClick}
      >
        Copiar
      </Button>
      {isCopied && (
        <div className="alert alert-success border-radius-5 w-100 mt-2 fade-in fade-out">
          Copiado para a área de transferência
        </div>
      )}
    </InputGroup>
  );
};

export default ClipboardCopy;
