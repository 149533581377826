import React, { useState } from 'react';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import Filters from 'components/Filters/Filters';
import { crop } from 'lib/string';
import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import { NamedRoutes } from 'config/routes/named-routes';

const Messages = () => {
  const [filters, setFilters] = useState({});

  const filterFields = [
    {
      attribute: 'name',
      type: 'text',
      label: 'Nome',
      id: 'message-name-filter',
    },
    {
      attribute: 'email',
      type: 'text',
      label: 'Email',
      id: 'message-email-filter',
    },
    {
      attribute: 'subject',
      type: 'text',
      label: 'Assunto',
      id: 'message-subject-filter',
    },
  ];

  return (
    <>
      <Filters fields={filterFields} applyFilters={setFilters} />
      <hr />

      <CrudList
        title="Mensagens"
        columns={{
          name: { text: 'Nome' },
          email: { text: 'Email' },
          subject: {
            text: 'Assunto',
            render: (resource) => (
              <a href={NamedRoutes.message_path(resource.id)} alt={resource.id}>
                {resource.subject}
              </a>
            ),
          },
          message: {
            text: 'Mensagem',
            render: (resource) => crop(resource.message, 50, '...'),
          },
          inserted_at: {
            text: 'Data',
            render: (resource) =>
              formatDateTimeUsingSlashes(resource.inserted_at),
          },
          user: {
            text: 'Usuário',
            render: (resource) => {
              const { user } = resource;
              if (user) {
                return `${user.first_name} ${user.last_name} <${user.email}>`;
              }

              return '';
            },
          },
        }}
        resourceAPI={API.Message}
        hideId
        hideTitle
        hideNewButton
        filters={filters}
      />
    </>
  );
};

export default Messages;
