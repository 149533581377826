import React from 'react';

import { formatDateTime } from 'lib/date-utils';
import { confirmWithMessage } from 'lib/notifications';

import API from 'api';

import './changes-requested.scss';

const ChangesRequested = ({ lineItem, order, onChange }) => {
  const changeRequests = lineItem.changes_requested;

  const buildBadge = (status) => {
    if (status === 'pending') {
      return <span className="badge mx-1 bg-warning">Pendente</span>;
    }
    if (status === 'approved') {
      return <span className="badge mx-1 bg-success">Aprovado</span>;
    }
    if (status === 'rejected') {
      return <span className="badge mx-1 bg-danger">Rejeitado</span>;
    }

    return '';
  };

  if (!changeRequests || changeRequests.length === 0) {
    return <p>Sem pedidos de mudança</p>;
  }

  return changeRequests.map((changeRequest) => (
    <div key={changeRequest.id} className="change-request">
      <div className="user-request d-flex">
        <img
          className="avatar user-avatar"
          src={order.user.avatar}
          alt={order.user.first_name}
        />
        <div className="card">
          <div className="card-header d-flex justify-content-between p-3">
            <p className="fw-bold mb-0">
              {order.user.first_name} {order.user.last_name}
            </p>
            <p className="text-muted small mb-0">
              <i className="far fa-clock"></i>&nbsp;
              {formatDateTime(changeRequest.inserted_at)}
            </p>
          </div>
          <div className="card-body">
            <p className="mb-0">{changeRequest.request}</p>

            <hr />
            {buildBadge(changeRequest.status)}

            {changeRequest.status === 'pending' && (
              <div
                className="float-end btn-group change-status"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    confirmWithMessage({
                      title: 'Aprovação de pedido de mudança',
                      text: 'Digite uma resposta para o pedido de mudança. Esta mensagem será enviada ao usuário.',
                      onConfirm: (response) => {
                        API.Order.updateChangeRequest(
                          order.id,
                          lineItem.id,
                          changeRequest.id,
                          'approved',
                          response,
                        ).then(() => {
                          onChange();
                        });
                      },
                    });
                  }}
                >
                  <i className="fa-regular fa-circle-check"></i>
                  Aprovar
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    confirmWithMessage({
                      title: 'Rejeição de pedido de mudança',
                      text: 'Digite uma resposta para o pedido de mudança. Esta mensagem será enviada ao usuário.',
                      onConfirm: (response) => {
                        API.Order.updateChangeRequest(
                          order.id,
                          lineItem.id,
                          changeRequest.id,
                          'rejected',
                          response,
                        ).then(() => {
                          onChange();
                        });
                      },
                    });
                  }}
                >
                  <i className="fa-regular fa-circle-xmark"></i> Rejeitar
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {changeRequest.response && (
        <div className="admin-response d-flex">
          <div className="card">
            <div className="card-header d-flex justify-content-between p-3">
              <p className="fw-bold mb-0">Admin</p>
              <p className="text-muted small mb-0">
                <i className="far fa-clock"></i>&nbsp;
                {formatDateTime(changeRequest.updated_at)}
              </p>
            </div>
            <div className="card-body">
              <p className="mb-0">{changeRequest.response}</p>
            </div>
          </div>
          <img
            className="avatar admin-avatar"
            src="https://res.cloudinary.com/makemydate/image/upload/v1638294831/Logo/apple-touch-icon_xnvi6u.png"
            alt="Make My Date"
          />
        </div>
      )}
    </div>
  ));
};

export default ChangesRequested;
