import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AddGiftCard = ({ onAdd }) => {
  const [value, setValue] = useState(100);

  const buildParams = () => ({
    type: 'gift_card',
    price: value,
    recipient_name: 'Selina Kyle',
    recipient_email: 'selina.kyle@mmd.com',
    message: 'Receive this gift card from me as a token of our friendship',
    key: new Date().getTime(),
    title: `Cartão-presente - R$ ${value}`,
  });

  return (
    <>
      <h5>Cartão presente</h5>

      <span>Preço (R$):</span>
      <Form.Control
        placeholder="Valor: R$ 0,00"
        type="number"
        onChange={(e) => {
          setValue(parseFloat(e.target.value));
        }}
        value={value}
        step=".01"
      />

      <Button
        variant="primary"
        className="w-100 mt-3"
        type="submit"
        onClick={() => {
          onAdd(buildParams());
        }}
      >
        <i className="fa fa-plus"></i> Cartão-presente
      </Button>
    </>
  );
};

export default AddGiftCard;
