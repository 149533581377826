import { formatDate } from 'lib/date-utils';
import { weekDaysString } from 'lib/week-days';

export const htmlForBookingLineItem = (text, lineItem) => {
  const { booking } = lineItem;
  const weekDays = weekDaysString(booking.week_days);
  const scheduledFor = lineItem.scheduled_date
    ? `<label>Atualmente agendado para ${formatDate(
        lineItem.scheduled_date,
      )}</label>`
    : '';

  const weekDaysMessage = weekDays
    ? `<p>
        <strong>Dias preferidos</strong>:
        ${weekDays
          .split(', ')
          .map(
            (weekDay) =>
              `<span class="badge mx-1 bg-secondary">${weekDay}</span>`,
          )
          .join('')}
      </p>`
    : '';

  const bookingMessage = booking.start_date
    ? `<p>
        <strong>Datas</strong>: a partir de ${formatDate(
          booking.start_date,
        )}, das
        ${booking.from_time} às ${booking.to_time}
      </p>`
    : '';

  return `
    <div style="text-align: left">
      <p>
        <label>${text}</label>
      </p>
      <p style="font-size: 13px">
        <p><strong>Item</strong>: ${lineItem.title}</p>
        ${weekDaysMessage}
        ${bookingMessage}
        ${scheduledFor}
      </p>
      <label>Comentário:</label>
      <textarea class="swal2-textarea" style="display: flex; margin: 15px 0; width: 100%; height: 50px;" id="swal2-comment"></textarea>
      <label>Dia e horário sugerido:</label>
      <input id="swal2-datetime" type="datetime-local" class="swal2-input" style="margin-left: 0">
    </div>
  `;
};
