import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ReactFlow, { Controls, Background } from 'reactflow';
import Row from 'react-bootstrap/Row';

import Switch from 'components/Form/Switch';
import { buildNodesAndEdges } from './plotter';
import DefaultNode from './DefaultNode';
import LogDetails from './LogDetails';
import OutputNode from './OutputNode';

import 'reactflow/dist/style.css';
import './styles.scss';

const nodeTypes = {
  default: DefaultNode,
  output: OutputNode,
};

const OrderFlow = ({ order, logs }) => {
  const [showWorkflow, setShowWorkflow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [modalData, setModalData] = useState({});
  const [animatedEdges, setAnimatedEdges] = useState(true);
  const [backgroundVariant, setBackgroundVariant] = useState('cross');
  const [showConfig, setShowConfig] = useState(false);

  if (!showWorkflow) {
    return (
      <>
        <Alert variant="warning">
          O fluxo visual ainda não foi carregado. Clique no botão abaixo para
          visualizar.
        </Alert>
        <Button
          variant="primary"
          onClick={() => setShowWorkflow(true)}
          type="button"
        >
          <i className="fa-solid fa-diagram-project"></i> Criar diagrama
        </Button>
      </>
    );
  }

  const lists = buildNodesAndEdges({
    order,
    logs,
    onClick: (data) => {
      setShowDetails(true);
      setModalData(data);
    },
    animatedEdges,
  });

  return (
    <div className="order-flow-component">
      <div
        className="mb-2 cursor-pointer text-primary float-end"
        onClick={() => setShowConfig(!showConfig)}
      >
        <i className="fa fa-gear" />
      </div>
      <div className={`mb-4 ${showConfig ? '' : 'd-none'}`}>
        <Row className="config-row">
          <Col xs={2}>
            <Form.Select
              className="background-selector"
              label="Estilo de fundo"
              value={backgroundVariant}
              onChange={(e) => setBackgroundVariant(e.target.value)}
            >
              <option value="cross">Fundo em cruz</option>
              <option value="lines">Fundo com linhas</option>
              <option value="dots">Sem fundo</option>
            </Form.Select>
          </Col>
          <Col>
            <Switch
              label="Linhas animadas"
              checked={animatedEdges}
              onChange={() => setAnimatedEdges(!animatedEdges)}
            />
          </Col>
        </Row>
      </div>
      <div style={{ width: '100vw', height: '100vh' }}>
        <ReactFlow
          nodes={lists.lineItemsNodes.concat(lists.ordersNodes)}
          edges={lists.edges}
          attributionPosition="bottom-left"
          nodeTypes={nodeTypes}
        >
          <Background color="#ccc" variant={backgroundVariant} />
          <Controls />
        </ReactFlow>

        {showDetails && (
          <LogDetails data={modalData} onClose={() => setShowDetails(false)} />
        )}
      </div>
    </div>
  );
};

export default OrderFlow;
