import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';

import Input from 'components/Form/Input';

const TimeOfDayForm = ({ onSave, onClose, resource }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: resource || {},
  });

  const onSubmit = (data) => {
    onSave(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        label="Nome"
        placeholder="Período"
        register={register('name', { required: true, maxLength: 30 })}
      />

      <Form.Group className="mb-3">
        <section className="form-actions">
          <Button variant="secondary" onClick={onClose}>
            Voltar
          </Button>

          <Button variant="primary" type="submit">
            Salvar
          </Button>
        </section>
      </Form.Group>
    </Form>
  );
};

export default TimeOfDayForm;
