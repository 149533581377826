import API from 'api';

import GiftCards from 'pages/gift-cards/giftCards';

const GiftCardsController = {
  basePath: '/gift-cards',
  form: null,
  page: GiftCards,
  resourceAPI: API.GiftCard,
};

export default GiftCardsController;
