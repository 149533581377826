import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import Loading from 'components/Loading/Loading';

const Logout = () => {
  const navigate = useNavigate();

  const { logout } = useAuth();
  const [searchParams] = useSearchParams();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    logout();

    navigate(url);
  }, url);

  if (!url) {
    const returnUrl = searchParams.get('url') || '/';
    setUrl(returnUrl);
  }

  return <Loading />;
};

export default Logout;
