import React from 'react';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import { NamedRoutes } from 'config/routes/named-routes';

const Responsibles = () => (
  <CrudList
    title="Responsáveis"
    columns={{
      name: {
        text: 'Nome',
        render: (resource) => (
          <a
            href={NamedRoutes.responsible_path(resource.id)}
            alt={resource.title}
          >
            {resource.name}
          </a>
        ),
      },
      slack_handle: { text: 'Slack' },
    }}
    resourceAPI={API.Responsible}
  />
);

export default Responsibles;
