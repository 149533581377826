export default class Storage {
  // Options accept these values:
  //
  // * parseFromJson (bool): if true, the result will be parsed from JSON using
  //   JSON.parse.
  //
  // * defaultValue: if the key is not found, the function will return this
  //   value.
  //
  // * storage: the storage to be used. It can be localStorage or sessionStorage.
  static get(key, options = {}) {
    const storage = options.storage || localStorage;

    try {
      const value = storage.getItem(key);

      if (options.parseFromJson && value) {
        return JSON.parse(value);
      }

      return value || options.defaultValue;
    } catch (_err) {
      return options.defaultValue;
    }
  }

  // Options accept these values:
  //
  // * parseToJson (bool): if true, the result will be parsed to JSON using
  //   JSON.stringify.
  static set(key, value, options = {}) {
    const storage = options.storage || localStorage;

    storage.setItem(key, options.parseToJson ? JSON.stringify(value) : value);
  }

  static remove(key, options = {}) {
    const storage = options.storage || localStorage;

    storage.removeItem(key);
  }
}
