import React, { useState } from 'react';
import API from 'api';
import CrudList from 'components/Crud/CrudList';
import Filters from 'components/Filters/Filters';
import { NamedRoutes } from 'config/routes/named-routes';

const Users = () => {
  const [filters, setFilters] = useState({});

  const filterFields = [
    {
      attribute: 'name',
      type: 'text',
      label: 'Nome ou sobrenome',
      id: 'user-name-filter',
      klass: '',
    },
    {
      attribute: 'email',
      type: 'text',
      label: 'Email',
      id: 'user-email-filter',
      klass: '',
    },
    {
      attribute: 'status',
      type: 'select',
      label: 'Status',
      id: 'user-status-filter',
      klass: '',
      options: [
        { text: 'Todos', value: '' },
        { text: 'Ativos', value: 'active' },
        { text: 'Inativos', value: 'inactive' },
      ],
    },
  ];

  return (
    <>
      <Filters fields={filterFields} applyFilters={setFilters} />
      <hr />

      <CrudList
        title="Usuários"
        columns={{
          first_name: {
            text: 'Nome',
            render: (resource) => (
              <a href={NamedRoutes.user_path(resource.id)} alt={resource.id}>
                {resource.first_name}
              </a>
            ),
          },
          last_name: { text: 'Sobrenome' },
          email: { text: 'Email' },
          status: {
            text: 'Status',
            render: (resource) => {
              if (resource.status === 'temporary_access') {
                return (
                  <span className="badge bg-warning">Acesso temporário</span>
                );
              }

              if (resource.status === 'active') {
                return <span className="badge bg-success">Ativo</span>;
              }

              if (resource.status === 'blocked') {
                return <span className="badge bg-danger">Bloqueado</span>;
              }

              if (resource.status === 'created') {
                return <span className="badge bg-warning">Criado</span>;
              }

              if (resource.status === 'inactive') {
                return <span className="badge bg-danger">Inativo</span>;
              }

              return null;
            },
          },
        }}
        resourceAPI={API.User}
        hideId
        hideDeleteButton
        hideNewButton
        hideTitle
        filters={filters}
      />
    </>
  );
};

export default Users;
