import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { buildUri } from 'lib/url';
import { confirm } from 'lib/notifications';
import { useTableBuilder } from './useTableBuilder';

import './styles.scss';
import './pagination.scss';

const CrudList = ({
  columns,
  hideDeleteButton,
  hideId,
  hideEditButton,
  hideNewButton,
  hideTitle,
  resourceAPI,
  resourceKey,
  title,
  rowDetails,
  stickyHeader,

  /*
    Example of filters:

    [
      {
        attribute: 'number',
        type: 'text',
        label: 'Número do pedido',
        id: 'order-number-filter',
        klass: 'order-number-filter-container',
      },
      {
        attribute: 'status',
        type: 'select',
        label: 'Status',
        id: 'status-filter',
        klass: 'status-filter-container',
        options: [{ text: 'Todos', value: '' }].concat(statusFilterOptions()),
      },
    ];
  */
  filters,

  /*
    Example of sortingFields:

    {
      number: { direction: 'off' },
      status: { direction: 'off' },
      inserted_at: { direction: 'desc' },
    };
  */
  sortingFields,
}) => {
  const [orderBy, setOrderBy] = useState(sortingFields || {});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const { renderColumn } = useTableBuilder(columns);

  useEffect(() => {
    refetch();
  }, [currentPage]);

  const buildSortingFields = () => {
    const fields = Object.keys(orderBy)
      .filter((key) => orderBy[key].direction !== 'off')
      .map((key) => `${key}:${orderBy[key].direction}`)
      .join(',');

    if (fields.length > 0) {
      return {
        order_by: fields,
      };
    }

    return {};
  };

  const buildQueryParameters = () => {
    const paginationParameters = { page: currentPage, page_size: 25 };
    const filtersParameters = filters || {};
    const sortingParameters = buildSortingFields();

    return {
      ...paginationParameters,
      ...filtersParameters,
      ...sortingParameters,
    };
  };

  const loadResourceList = async () => {
    setLoading(true);

    const result = await resourceAPI.all(buildQueryParameters());
    const { data: body } = result;
    setLoading(false);
    return body;
  };

  const {
    data: body,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery([`crud-page-${title}`, currentPage, filters, orderBy], () =>
    loadResourceList(),
  );

  const sortingArrows = {
    asc: 'sort-amount-down-alt',
    desc: 'sort-amount-up-alt',
    off: 'sort',
  };

  const buildTableHeader = (attribute) => {
    const sortingField = orderBy[attribute];
    const columnText = columns[attribute].text;

    if (sortingField) {
      const nextDirection = {
        asc: 'desc',
        desc: 'off',
        off: 'asc',
      };

      return (
        <th
          key={attribute}
          className="sortable-column-header"
          scope="col"
          onClick={() => {
            const newOrderBy = { ...orderBy };
            Object.keys(newOrderBy).forEach((key) => {
              if (key !== attribute) {
                newOrderBy[key].direction = 'off';
              }
            });
            newOrderBy[attribute] = {
              direction: nextDirection[orderBy[attribute].direction],
            };
            setOrderBy(newOrderBy);
          }}
        >
          <i
            className={`sorting-direction fa fa-${
              sortingArrows[sortingField.direction]
            }`}
          ></i>
          {columnText}
        </th>
      );
    }

    return (
      <th key={attribute} scope="col">
        {columnText}
      </th>
    );
  };

  if (isLoading || isRefetching) {
    return (
      <table className="table crud-table">
        <thead className={stickyHeader ? 'sticky' : ''}>
          <tr>
            {!hideId && <th scope="col">#</th>}
            {Object.keys(columns).map((attribute) =>
              buildTableHeader(attribute),
            )}
            <th scope="col" className="action-column"></th>
            <th scope="col" className="action-column"></th>
          </tr>
        </thead>
        <tbody>
          {[...Array(5)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={index}>
              {!hideId && (
                <td>
                  <Placeholder as="div" animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </td>
              )}
              {Object.keys(columns).map((attribute) => (
                <td key={attribute}>
                  <Placeholder
                    as="div"
                    animation="glow"
                    className="table-column-placeholder"
                  >
                    <Placeholder xs={12} />
                  </Placeholder>
                </td>
              ))}
              <td className="action-column">
                <Placeholder
                  as="div"
                  animation="glow"
                  className="table-column-placeholder"
                >
                  <Placeholder xs={12} />
                </Placeholder>
              </td>
              <td className="action-column">
                <Placeholder
                  as="div"
                  animation="glow"
                  className="table-column-placeholder"
                >
                  <Placeholder xs={12} />
                </Placeholder>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  const resourcesList = body.data;
  const pageCount = body.pagination ? body.pagination.total_pages : 1;

  const handlePageClick = (event) => {
    const nextPage = parseInt(event.selected, 10) + 1;
    setCurrentPage(nextPage);
  };

  const onEditHandler = (item) => {
    const uri = buildUri([
      location.pathname,
      item[resourceKey || 'id'],
      'editar',
    ]);
    navigate(uri);
  };

  const onNewHandler = () => {
    const uri = buildUri([location.pathname, 'novo']);
    navigate(uri);
  };

  const onDeleteHandler = (id) => {
    confirm({
      title: 'Atenção',
      text: 'Você tem certeza que deseja excluir? Esta operação não poderá ser desfeita.',
      onConfirm: () => {
        resourceAPI.destroy(id).then(() => refetch());
      },
    });
  };

  const showNewButton = () => {
    if (hideNewButton) {
      return null;
    }

    return (
      <section className="actions-container">
        <Button variant="primary" type="submit" onClick={onNewHandler}>
          <i className="fa fa-plus"></i> Novo
        </Button>
      </section>
    );
  };

  if (resourcesList.length === 0 && !loading) {
    return (
      <div>
        {!hideTitle && <h1 className="workspace-header">{title}</h1>}
        <div>Não encontramos nenhum resultado</div>
        {showNewButton()}
      </div>
    );
  }

  const columnsCount =
    Object.keys(columns).length +
    (!hideId ? 1 : 0) +
    (!hideEditButton ? 1 : 0) +
    (!hideDeleteButton ? 1 : 0);

  const RowDetails = rowDetails;

  return (
    <div>
      {!hideTitle && <h1 className="workspace-header">{title}</h1>}

      <table className="table crud-table">
        <thead className={stickyHeader ? 'sticky' : ''}>
          <tr>
            {!hideId && <th scope="col">#</th>}
            {Object.keys(columns).map((attribute) =>
              buildTableHeader(attribute),
            )}
            <th scope="col" className="action-column"></th>
            <th scope="col" className="action-column"></th>
          </tr>
        </thead>
        <tbody>
          {resourcesList.map((r) => (
            <>
              <tr key={r.id}>
                {!hideId && <th scope="row">{r.id}</th>}

                {Object.keys(columns).map((attribute) =>
                  renderColumn(r, attribute),
                )}

                {!hideEditButton && (
                  <td className="action-column">
                    {((resourceKey && r[resourceKey]) ||
                      (!resourceKey && r.id)) && (
                      <i
                        className="far fa-edit"
                        onClick={() => onEditHandler(r)}
                      ></i>
                    )}
                  </td>
                )}

                {!hideDeleteButton && (
                  <td className="action-column">
                    <i
                      className="far fa-trash-alt"
                      onClick={() => onDeleteHandler(r.id)}
                    ></i>
                  </td>
                )}
              </tr>
              {rowDetails && (
                <tr>
                  <td colSpan={columnsCount}>
                    <RowDetails resource={r} />
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>

      {pageCount > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
          forcePage={currentPage - 1}
        />
      )}

      {showNewButton()}
    </div>
  );
};

export default CrudList;
