import API from 'api';

import RefundForm from 'pages/refunds/components/RefundForm';
import Refunds from 'pages/refunds/refunds';

const RefundsController = {
  basePath: '/devolucoes',
  form: RefundForm,
  page: Refunds,
  resourceAPI: API.Refund,
};

export default RefundsController;
