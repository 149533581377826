import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Switch from 'components/Form/Switch';

import { storeActions } from 'store';
import { updateQueryString } from 'lib/query-string';
import CheckoutUrl from './components/payments/CheckoutUrl';
import Data from './components/data/Data';
import Oban from './components/oban/Oban';
import OrderFactory from './components/order-factory/OrderFactory';
import WebhookSimulator from './components/payments/WebhookSimulator';

const Dev = () => {
  const dispatch = useDispatch();
  const debugMode = useSelector((state) => state.dev.debug);

  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('oban');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location]);

  const handleSelect = (key) => {
    setActiveTab(key);
    updateQueryString(location, navigate, key);
  };

  return (
    <div>
      {debugMode && (
        <div className="w-100 bg-light mb-3 rounded p-1 px-2 text-center">
          <i className="fa-solid fa-bug text-muted"></i> Debug mode{' '}
          <strong>ON</strong> - verifique o console do seu navegador
        </div>
      )}

      <Tabs
        activeKey={activeTab}
        className="mb-3"
        defaultActiveKey="oban"
        onSelect={handleSelect}
      >
        <Tab
          eventKey="oban"
          title={
            <span>
              <i className="fa-solid fa-diagram-next"></i> Oban
            </span>
          }
        >
          <Oban />
        </Tab>

        <Tab
          eventKey="data"
          title={
            <span>
              <i className="fa fa-database"></i> Dados
            </span>
          }
        >
          <Data />
        </Tab>

        <Tab
          eventKey="payments"
          title={
            <span>
              <i className="fa-solid fa-money-check-dollar"></i> Pagamentos
            </span>
          }
        >
          <Row>
            <Col>
              <CheckoutUrl />
            </Col>
            <Col>
              <WebhookSimulator />
            </Col>
          </Row>
        </Tab>

        <Tab
          eventKey="order-factory"
          title={
            <span>
              <i className="fa-solid fa-industry"></i> Gerador de pedidos
            </span>
          }
        >
          <OrderFactory />
        </Tab>
      </Tabs>

      <hr />
      <Switch
        checked={debugMode}
        className="mt-3"
        label="Debug mode"
        name="required"
        onChange={(e) => {
          dispatch(storeActions.dev.setDebugMode(e.target.checked));
        }}
      />
    </div>
  );
};

export default Dev;
