/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import Loading from 'components/Loading/Loading';
import { storeActions } from 'store';
import { useAuth } from 'contexts/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);

  const { currentUser, login } = useAuth();

  useEffect(() => {
    if (url) {
      navigate(url);
    }
  }, [url]);

  useEffect(() => {
    // Add focus to the email input field on load
    const emailInput = document.querySelector('[data-email-input]');
    if (emailInput) {
      emailInput.focus();
    }
  }, []);

  const checkCredentials = async () => {
    const status = await login(email, password);

    if (status.loggedIn) {
      dispatch(storeActions.page.hideLoading());
      const returnUrl = searchParams.get('url') || '/';
      setUrl(returnUrl);
    } else {
      onLoginErrorHandler(status.error);
    }
  };

  const { isLoading, refetch } = useQuery(
    'user-credentials',
    checkCredentials,
    { enabled: false, retry: false },
  );

  if (url) {
    return null;
  }

  const showError = (message) => {
    const container = document.querySelector('.alert');
    if (container) {
      container.textContent = message;
      container.classList.remove('d-none');
    }
  };

  const onLoginErrorHandler = (e) => {
    if (e.response.status === 401 || e.response.status === 404) {
      showError('Email ou senha incorretos');
    } else {
      showError('Não foi possível fazer login');
    }
  };

  if (currentUser) {
    const returnUrl = searchParams.get('url') || '/';
    setUrl(returnUrl);
    return <Loading />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="row justify-content-md-center mt-3 vh-100 mt-5">
      <div className="col col-md-4"></div>
      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <h2 className="text-center mb-4">Entrar</h2>

            <div className="alert alert-danger border-radius-5 d-none"></div>

            <section className="form-group" id="email">
              <span>Email</span>
              <input
                className="form-control"
                type="email"
                required
                data-email-input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    document.querySelector('#password input').focus();
                  }
                }}
              />
            </section>

            <section className="form-group" id="password">
              <span>Senha</span>
              <input
                className="form-control"
                data-password-input
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    refetch();
                  }
                }}
              />
            </section>

            <p>&nbsp;</p>

            <button
              className="w-100 btn btn-primary"
              data-login-button
              type="button"
              onClick={() => {
                dispatch(storeActions.page.showLoading());

                setTimeout(() => refetch(), 1000);
              }}
            >
              Entrar
            </button>
          </div>
        </div>
      </div>
      <div className="col col-md-4"></div>
    </div>
  );
};

export default Login;
