import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

import API from 'api';

import { confirmWithMessage } from 'lib/notifications';

const ContextButtons = ({ refetch, refundLog }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const markAsResolved = () => {
    confirmWithMessage({
      title: 'Intervenção manual',
      text: 'Dê detalhes de como a transação foi feita, como os 4 últimos dígitos do cartão, a bandeira, etc.',
      onConfirm: (details) => {
        API.Refund.refundManually(refundLog, { details })
          .then(() => {
            refetch();
          })
          .catch((error) => {
            setErrorMessage(error.response.data.error);
          });
      },
    });
  };

  return (
    <div className="context-buttons">
      <span
        className="badge mx-1 bg-secondary cursor-pointer mt-4"
        onClick={() => {
          markAsResolved();
        }}
      >
        <i className="fa-solid fa-wrench"></i> &nbsp; Intervenção manual
      </span>

      {errorMessage && (
        <Alert variant="danger" className="mt-4">
          {errorMessage}
        </Alert>
      )}
    </div>
  );
};

export default ContextButtons;
