import React from 'react';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import { NamedRoutes } from 'config/routes/named-routes';

const TimesOfDays = () => (
  <CrudList
    title="Períodos do dia"
    columns={{
      name: {
        text: 'Nome',
        render: (resource) => (
          <a
            href={NamedRoutes.times_of_day_path(resource.id)}
            alt={resource.name}
          >
            {resource.name}
          </a>
        ),
      },
    }}
    resourceAPI={API.TimeOfDay}
  />
);

export default TimesOfDays;
