import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const KEYS_TRANSLATIONS = {
  product_category: 'Categoria',
  product_type: 'Tipo de date',
  product_recency: 'Primeiro encontro',
  max_budget: 'Orçamento',
  nature: 'Natureza',
  movement_preference: 'Movimento',
  confort_zone: 'Zona de conforto',
  brainy_preference: 'Pensativo',
  mood: 'Vibe',
  transport: 'Transporte',
  age_restrictions: 'Restrições de idade',
  drink_preference: 'Preferências sobre bebidas',
  extra: 'Extra',
  expectations: 'Expectativas',
};

const VALUES_TRANSLATIONS = {
  surprise: 'date surpresa',
  defined: 'date definido',
  extreme: 'radical',
  fun: 'divertido',
  romantic: 'romântico',
  'first date': 'sim',
  'we know each other very well': 'não',
  'we like nature': 'ok',
  'we do not like nature': 'não',
  movement: 'ok',
  'no movement': 'não',
  'in confort zone': 'na zona de conforto',
  'out of confort zone': 'fora da zona de conforto',
  brainy: 'com desafios que nos façam pensar',
  'not brainy': 'sem desafios que nos façam pensar',
  calm: 'tranquilo',
  agitated: 'agitado',
  car: 'temos carro',
  'no car': 'não temos carro',
  over18: 'não',
  under18: 'menores de 18 anos',
  drinker: 'nós bebemos',
  sober: 'nós não bebemos',
};

const questionnaireTab = ({ order, buildLabel }) => {
  const items = Object.keys(order.questionnaire || {}).map((key) => ({
    key,
    translatedKey: KEYS_TRANSLATIONS[key] || key,
    value: order.questionnaire[key],
    translatedValue:
      VALUES_TRANSLATIONS[order.questionnaire[key]] || order.questionnaire[key],
  }));

  const compare = (a, b) => {
    if (a.translatedKey < b.translatedKey) {
      return -1;
    }

    if (a.translatedKey > b.translatedKey) {
      return 1;
    }

    return 0;
  };

  return (
    <Container>
      <Row>
        <Col>
          {items
            .sort(compare)
            .map((item) =>
              buildLabel(item.translatedKey, item.translatedValue),
            )}
        </Col>
      </Row>
    </Container>
  );
};

export default questionnaireTab;
