import React from 'react';

const Calendar = () => (
  <div style={{ width: '100vw', height: '100vh' }}>
    <iframe
      title="Google Calendar"
      src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FSao_Paulo&bgcolor=%23ffffff&showPrint=0&showCalendars=0&hl=pt_BR&src=bWFrZW15ZGF0ZS5ickBnbWFpbC5jb20&src=ZW4uYnJhemlsaWFuI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%230B8043"
      style={{ borderWidth: '0' }}
      width="80%"
      height="80%"
    ></iframe>
  </div>
);

export default Calendar;
