import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import { confirm } from 'lib/notifications';
import PreviewStep from './PreviewStep';
import WaitingRefund from './WaitingRefund';

const RefundPreview = ({ order, lineItems, lineItemsIds, onClose }) => {
  const [strategy, setStrategy] = useState('store_credits_only');
  const [running, setRunning] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorDetails, setErrorDetails] = useState(null);
  const [refundId, setRefundId] = useState(null);

  const selectedLineItems = lineItems.filter((item) =>
    lineItemsIds.includes(item.id),
  );

  const fetchRefundPreview = async () => {
    const response = await API.Refund.preview(order.id, lineItemsIds, strategy);
    return response.data;
  };

  const {
    data: refundPreview,
    isLoading,
    refetch,
  } = useQuery(
    [`refund-preview-${order.id}-${lineItemsIds}-${strategy}`],
    fetchRefundPreview,
    { enabled: false },
  );

  const createRefund = () => {
    if (errorMessage) {
      setErrorMessage(null);
      setErrorDetails(null);
    }

    const params = {
      order_id: order.id,
      line_item_ids: lineItemsIds,
      reason: 'cancelation',
      strategy,
    };

    API.Refund.create(params)
      .then((response) => {
        setRefundId(response.data.data.id);
        setRunning(true);
      })
      .catch((error) => {
        setErrorMessage(`Error fetching checkout URL: ${error.message}`);
        setErrorDetails(error.config.data);
      });
  };

  if (running) {
    return <WaitingRefund onClose={onClose} refundId={refundId} />;
  }

  return (
    <div className="lh-3 p-4">
      <Row>
        <Col>
          <div>Você está prestes a devolver os seguintes itens:</div>

          <ul>
            {selectedLineItems.map((item) => (
              <li key={item.id}>{item.title}</li>
            ))}
          </ul>
        </Col>
      </Row>

      <Row>
        <Col>
          Escolha a estratégia de devolução:
          <Form.Select
            aria-label="Estratégia de devolução"
            onChange={(e) => setStrategy(e.target.value)}
          >
            <option value="store_credits_only">Apenas créditos na loja</option>
            <option value="refund_using_the_same_payment_method">
              Usar meio de pagamento original
            </option>
          </Form.Select>
        </Col>
      </Row>

      <section className="form-actions">
        <Button variant="dark" onClick={() => refetch()}>
          Preview
        </Button>

        <Button variant="danger" onClick={onClose}>
          Cancelar
        </Button>
      </section>

      {isLoading && <Loading />}

      {refundPreview && !isLoading && (
        <>
          <hr />

          <div>
            <strong>Créditos aplicados no pedido:</strong>{' '}
            {refundPreview.store_credits_applied_on_the_order.formatted}
          </div>

          <div>
            <strong>Valor a ser devolvido:</strong>{' '}
            {refundPreview.value_to_refund.formatted}
          </div>

          <h5 className="mt-3">Passos da devolução</h5>

          <ul className="mt-3">
            {refundPreview.refunds.map((step, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`step-${index}`}>
                <PreviewStep
                  type={step.type}
                  paymentMethod={step.method}
                  value={step.value.formatted}
                  metadata={step.metadata}
                />
              </li>
            ))}
          </ul>

          <Button
            className="w-100 mt-10"
            variant="warning"
            onClick={() => {
              confirm({
                title: 'Iniciar devolução',
                text: 'Tem certeza que deseja continuar?',
                onConfirm: () => {
                  createRefund();
                },
              });
            }}
          >
            Iniciar devolução
          </Button>

          {errorMessage && <div className="text-danger">{errorMessage}</div>}
          {errorDetails && (
            <pre>{JSON.stringify(JSON.parse(errorDetails), null, 2)}</pre>
          )}
        </>
      )}
    </div>
  );
};

export default RefundPreview;
