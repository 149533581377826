import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipIcon = ({ text, icon, ...args }) => (
  <OverlayTrigger placement="top" overlay={<Tooltip>{text}</Tooltip>}>
    <div {...args}>
      <i className={icon}></i>
    </div>
  </OverlayTrigger>
);

export default TooltipIcon;
