import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import Select from 'components/Form/Select';
import { confirm } from 'lib/notifications';

const Responsible = ({
  responsible,
  onResponsibleChange,
  responsiblesList,
  index,
  setHeaderErrors,
  onDelete,
}) => {
  const [id, setId] = useState(responsible.id || 0);
  const hasErrors = () => id <= 0;

  useEffect(() => {
    const value = buildResponsible();
    if (value) {
      onResponsibleChange(value, index);
    }

    setHeaderErrors(hasErrors());
  }, [id]);

  const buildResponsible = () =>
    responsiblesList.find((r) => r.id === parseInt(id, 10));

  return (
    <>
      <Select
        className={id <= 0 ? 'required' : ''}
        label="Nome"
        options={[{ text: 'Selecione', value: 0 }].concat(
          responsiblesList.map((resp) => ({
            text: resp.name,
            value: resp.id,
          })),
        )}
        value={id}
        onChange={(e) => setId(e.target.value)}
      />

      {!hasErrors() && (
        <Button
          variant="danger"
          type="button"
          className="btn-sm w-100"
          onClick={() => {
            confirm({
              title: 'Exclusão',
              text: 'Você tem certeza que deseja excluir? A operação só será efetivada depois que você salvar a opção.',
              onConfirm: () => {
                onDelete(buildResponsible(), index);
              },
            });
          }}
        >
          <i className="fa fa-trash"></i> Excluir
        </Button>
      )}
    </>
  );
};

export default Responsible;
