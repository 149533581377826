import React from 'react';
import Modal from 'react-bootstrap/Modal';

const modal = ({ title, children, onClose, fullscreen, className }) => (
  <Modal
    className={className}
    fullscreen={fullscreen}
    show
    onHide={onClose}
    backdrop="static"
    keyboard={false}
    centered
    autoFocus={false}
    enforceFocus={false}
  >
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>{children}</Modal.Body>
  </Modal>
);

export default modal;
