import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import { Button } from 'react-bootstrap';

import API from 'api';

import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import ObanContextBar from './ObanContextBar';

import './oban.scss';

const Oban = () => {
  const [currentState, setCurrentState] = useState(null);
  const [jobs, setJobs] = useState([]);
  const states = [
    'Available',
    'Cancelled',
    'Completed',
    'Discarded',
    'Executing',
    'Retryable',
    'Scheduled',
  ];

  useEffect(() => {
    if (currentState) {
      API.Oban.all({ state: currentState.toLowerCase() }).then((response) => {
        setJobs(response.data);
      });
    }
  }, [currentState]);

  return (
    <div className="oban">
      <div className="d-flex">
        <section className="oban-states me-3">
          <ListGroup>
            {states.map((state) => (
              <ListGroup.Item key={state}>
                <Button
                  className={state === currentState ? 'active' : ''}
                  onClick={() => {
                    setCurrentState(state);
                    setJobs([]);
                  }}
                >
                  {state}
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </section>
        <section className="oban-jobs">
          {jobs.length === 0 && currentState === null && (
            <div className="w-100 text-center">
              Escolha o estado de um job para ver a lista
            </div>
          )}

          {jobs.length === 0 && currentState !== null && (
            <div className="w-100 text-center">
              Não há jobs com o status <strong>{currentState}</strong> no
              momento
            </div>
          )}

          {jobs.length > 0 && (
            <Accordion defaultActiveKey="0">
              {jobs.map((job, index) => (
                <Accordion.Item key={job.id} eventKey={index}>
                  <Accordion.Header>
                    <div className="w-100">
                      <div className="float-start">
                        <span className="id">#{job.id}</span>&nbsp; | &nbsp;
                        <span className="worker">{job.worker}</span>
                        &nbsp;-&nbsp;
                        <span className="queue">{job.queue}</span>&nbsp; [
                        <span className="attempt">{job.attempt}</span> /{' '}
                        <span className="attempt">{job.max_attempts}</span>]
                      </div>
                      <div className="text-muted small mb-0 float-end me-5 attempted-at">
                        <i className="far fa-clock"></i>&nbsp;{' '}
                        {formatDateTimeUsingSlashes(job.attempted_at)}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="job-data">
                      <h5>Argumentos:</h5>
                      <pre className="args">
                        {JSON.stringify(job.args, null, 2)}
                      </pre>

                      <ObanContextBar args={job.args} />
                    </div>

                    <div className="job-data">
                      <h5>Erros:</h5>
                      <pre className="args">
                        {JSON.stringify(job.errors, null, 2)}
                      </pre>
                    </div>

                    <div className="job-data">
                      <h5>Datas e horas:</h5>

                      {job.attempted_at && (
                        <div>
                          <strong>Tentativa feita:</strong>{' '}
                          {formatDateTimeUsingSlashes(job.attempted_at)}
                        </div>
                      )}

                      {job.cancelled_at && (
                        <div>
                          <strong>Cancelado em:</strong>{' '}
                          {formatDateTimeUsingSlashes(job.cancelled_at)}
                        </div>
                      )}

                      {job.completed_at && (
                        <div>
                          <strong>Completo em:</strong>{' '}
                          {formatDateTimeUsingSlashes(job.completed_at)}
                        </div>
                      )}

                      {job.discarded_at && (
                        <div>
                          <strong>Descartado em:</strong>{' '}
                          {formatDateTimeUsingSlashes(job.discarded_at)}
                        </div>
                      )}
                    </div>

                    {['retryable', 'cancelled', 'discarded'].includes(
                      job.state,
                    ) && (
                      <div className="job-data">
                        <Button
                          variant="secondary"
                          size="md"
                          onClick={() => {
                            API.Oban.update(job.id).then(() => {
                              window.location.reload();
                            });
                          }}
                        >
                          <i className="fa-solid fa-rotate"></i>&nbsp; Retentar
                        </Button>
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </section>
      </div>
    </div>
  );
};

export default Oban;
