import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { resources } from 'config/routes/router';

import Calendar from 'pages/calendar/calendar';
import Dashboard from 'pages/dashboard/dashboard';
import Finances from 'pages/finances/finances';
import Login from 'pages/session/login';
import Logout from 'pages/session/logout';
import NotFound from 'pages/errors/not-found';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import ProductsData from 'pages/products/components/ProductsData/ProductsData';

import {
  GiftCardsController,
  LeadsController,
  MessagesController,
  DevController,
  OrdersController,
  ProductsController,
  ProductTypesController,
  RefundsController,
  ResponsiblesController,
  TimesOfDaysController,
  UsersController,
} from './config/routes/controllers';

const CustomRoutes = () => (
  <Routes>
    {/* Public routes */}
    <Route exact path="/login" element={<Login />} />
    <Route exact path="/logout" element={<Logout />} />
    <Route exact path="/404" element={<NotFound />} />

    {/* Private routes */}
    <Route
      exact
      path="/"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />

    {resources('product_type', ProductTypesController)}
    {resources('product', ProductsController)}
    {resources('lead', LeadsController, { only: 'index' })}
    {resources('message', MessagesController, { except: 'new' })}
    {resources('dev', DevController, { only: 'index' })}
    {resources('order', OrdersController, { except: 'new' })}
    {resources('refund', RefundsController, { except: 'new' })}
    {resources('responsible', ResponsiblesController)}
    {resources('times_of_day', TimesOfDaysController)}
    {resources('user', UsersController, { except: 'new' })}
    {resources('gift_card', GiftCardsController, { only: 'index' })}

    <Route
      exact
      path="/calendario"
      element={
        <PrivateRoute>
          <Calendar />
        </PrivateRoute>
      }
    />

    <Route
      exact
      path="/produtos/dados"
      element={
        <PrivateRoute>
          <ProductsData />
        </PrivateRoute>
      }
    />

    <Route
      exact
      path="/finances"
      element={
        <PrivateRoute>
          <Finances />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default CustomRoutes;
