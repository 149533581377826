import React from 'react';
import ReactJson from 'react-json-view';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import { alert } from 'lib/notifications';

const ProductsData = () => {
  const fetchData = async () => {
    const result = await API.Product.all({ full_list: true });
    const { data: body } = result;
    return body.data;
  };

  const {
    data: products,
    isLoading,
    isError,
  } = useQuery([`products-data`], fetchData);

  if (!products || isLoading) {
    return <Loading />;
  }

  if (isError) {
    alert({ text: 'Não foi possível carregar a lista de produtos' });
    return null;
  }

  return (
    <ReactJson
      src={products}
      name="products"
      indentWidt={2}
      displayObjectSize={false}
      sortKeys
      displayArrayKey={false}
      displayDataTypes={false}
    />
  );
};

export default ProductsData;
