import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import OrderFlow from '../order-flow/OrderFlow';

const OrderFlowTab = ({ order }) => {
  useEffect(() => {
    refetch();
  }, [order]);

  const fetchOrderLogs = async (orderId) => {
    const response = await API.OrderLog.all({ order_id: orderId });
    const { data: body } = response;
    return body.data;
  };

  const {
    data: logs,
    isLoading,
    refetch,
  } = useQuery(['logs', order.id], () => fetchOrderLogs(order.id));

  if (!order || isLoading) {
    return <Loading />;
  }

  return <OrderFlow order={order} logs={logs} />;
};

export default OrderFlowTab;
