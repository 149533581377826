import Resource from '../resource';

export default class Order extends Resource {
  findByNumber(number) {
    return this.client.get(`/order-by-number/${number}`);
  }

  addPaymentMethod(orderId, paymentMethod) {
    return this.client.put(`/orders/${orderId}/add_payment_method`, {
      order: { payment_method: paymentMethod },
    });
  }

  addToCart(orderId, params) {
    return this.client.put(`/orders/${orderId}/add_to_cart`, {
      order: params,
    });
  }

  addUser(orderId, userId) {
    return this.client.put(`/orders/${orderId}/add_user`, {
      order: { user_id: userId },
    });
  }

  closeOrder(orderId) {
    return this.client.put(`/orders/${orderId}/close_order`, { order: {} });
  }

  makeOrderReady(orderId, params) {
    return this.client.put(`/orders/${orderId}/make_order_ready`, {
      order: params,
    });
  }

  placeOrder(orderId, params) {
    return this.client.put(`/orders/${orderId}/place_order`, {
      order: params,
    });
  }

  requestChanges(orderId, params) {
    return this.client.put(`/orders/${orderId}/request_changes`, {
      order: params,
    });
  }

  removeFromCart(orderId, lineItemId, refundStrategy) {
    return this.client.put(`/orders/${orderId}/remove_from_cart`, {
      order: {
        line_item_id: lineItemId,
        refund_strategy: refundStrategy,
      },
    });
  }

  scheduleVariant(orderId, params) {
    return this.client.put(`/orders/${orderId}/schedule_variant`, {
      order: params,
    });
  }

  sendPaymentLinkByEmail(orderId) {
    return this.client.post(`/send_payment_link/${orderId}`);
  }

  updateAdditionalRequest(orderId, additionalRequest) {
    return this.client.put(
      `/orders/${orderId}/update_additional_request`,
      additionalRequest,
    );
  }

  updateChangeRequest(orderId, lineItemId, changeRequestId, status, response) {
    return this.client.put(`/orders/${orderId}/update_change_request`, {
      order: {
        line_item_id: lineItemId,
        change_request_id: changeRequestId,
        approved: status === 'approved',
        response,
      },
    });
  }
}
