/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useQuery } from 'react-query';

import API from 'api';

import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import Loading from 'components/Loading/Loading';
import Switch from 'components/Form/Switch';

import LogComment from '../order-log/LogComment';
import LogIcon from '../order-log/LogIcon';

const LogsTab = ({ order }) => {
  const [showAllDetails, setShowAllDetails] = useState(false);
  useEffect(() => {
    refetch();
  }, [order]);

  const fetchOrderLogs = async (orderId) => {
    const response = await API.OrderLog.all({ order_id: orderId });
    const { data: body } = response;
    return body.data;
  };

  const {
    data: logs,
    isLoading,
    refetch,
  } = useQuery(['logs', order.id], () => fetchOrderLogs(order.id));

  if (!order || isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="sticky-top bg-white py-2">
        <Switch
          label="Exibir todos os detalhes"
          onChange={() => setShowAllDetails(!showAllDetails)}
          checked={showAllDetails}
          style={{ position: 'sticky', top: 0 }}
        />
      </div>

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th></th>
            <th>Comentário</th>
            <th>Data</th>
            <th>Usuário</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log.id}>
              <td style={{ minWidth: '25px', textAlign: 'center' }}>
                <LogIcon logType={log.type} />
              </td>
              <td>
                <LogComment log={log} showAllDetails={showAllDetails} />
              </td>
              <td>{formatDateTimeUsingSlashes(log.inserted_at)}</td>
              <td>{log.user ? log.user.first_name : ''}</td>
              <td>{log.user ? log.user.email : ''}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default LogsTab;
