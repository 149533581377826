export const MONTHS = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
};

const moment = require('moment-timezone');

export const convertToTimezone = (date, tzString) => {
  const utcDate = moment.utc(date);
  return utcDate.clone().tz(tzString);
};

export const parseToUTCDate = (dateString) =>
  // eslint-disable-next-line new-cap
  new moment(dateString).utc().toDate();

export const parseToUTCString = (dateString) =>
  // eslint-disable-next-line new-cap
  new moment(dateString).utc().format();

export const yearMonthDayStringToFormatedUTCDate = (dateYyyyMmDd) => {
  const utcDate = moment(dateYyyyMmDd).utc().toDate();

  return `${utcDate.getDate()} de ${
    MONTHS[utcDate.getMonth()]
  } de ${utcDate.getFullYear()}`;
};

export const formatDate = (date) => {
  const brDate = convertToTimezone(date, 'America/Sao_Paulo').toDate();
  return `${brDate.getDate()} de ${
    MONTHS[brDate.getMonth()]
  } de ${brDate.getFullYear()}`;
};

export const convertToUTC = (date) => {
  const brDate = moment.tz(date, 'America/Sao_Paulo');
  return moment.utc(brDate);
};

export const formatDateTime = (date) => {
  const brDate = convertToTimezone(date, 'America/Sao_Paulo').toDate();
  const d = brDate.getDate();
  const m = MONTHS[brDate.getMonth()];
  const y = brDate.getFullYear();
  const h = brDate.getHours().toString().padStart(2, '0');
  const min = brDate.getMinutes().toString().padStart(2, '0');

  return `${d} de ${m} de ${y}, ${h}h${min}`;
};

export const formatDateTimeUsingSlashes = (date) => {
  const brDate = convertToTimezone(date, 'America/Sao_Paulo').toDate();
  const d = brDate.getDate().toString().padStart(2, '0');
  const m = (brDate.getMonth() + 1).toString().padStart(2, '0');
  const y = brDate.getFullYear();
  const h = brDate.getHours().toString().padStart(2, '0');
  const min = brDate.getMinutes().toString().padStart(2, '0');
  const sec = brDate.getSeconds().toString().padStart(2, '0');

  return `${d}/${m}/${y} ${h}:${min}:${sec}`;
};

export const parseDateAndTimeUsingSlashes = (
  date,
  eventDurationInHours = 1,
) => {
  const brDate = convertToTimezone(date, 'America/Sao_Paulo').toDate();
  const d = brDate.getDate().toString().padStart(2, '0');
  const m = (brDate.getMonth() + 1).toString().padStart(2, '0');
  const y = brDate.getFullYear();
  const hours = brDate.getHours();
  const startH = hours.toString().padStart(2, '0');
  const endH = (hours + eventDurationInHours).toString().padStart(2, '0');
  const min = brDate.getMinutes().toString().padStart(2, '0');

  return {
    startDate: `${y}-${m}-${d}`,
    endDate: `${y}-${m}-${d}`,
    startTime: `${startH}:${min}`,
    endTime: `${endH}:${min}`,
  };
};

export const addHours = (date, hoursToAdd) => {
  const newDate = new Date(date.getTime());
  newDate.setHours(date.getHours() + hoursToAdd);
  return newDate;
};
