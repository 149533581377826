import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import DeleteButton from './DeleteButton';

const OrderSummary = ({ onDelete, onGenerate, orderParams }) => {
  const parentLineItems = orderParams.filter(
    (p) => p.type === 'variant' || p.type === 'gift_card',
  );
  const getChildren = (parentKey) =>
    orderParams.filter((p) => p.parent_key === parentKey);

  return (
    <div className="card order-summary">
      <div className="card-header">
        <i className="fa-solid fa-receipt"></i> &nbsp; Resumo
      </div>
      <div className="card-body">
        <Row>
          <Col>
            {parentLineItems.length === 0 && (
              <span>Nenhum item no carrinho</span>
            )}
            {parentLineItems.length > 0 && (
              <table className="borderless-table w-100 fs-7">
                <tbody>
                  {parentLineItems.map((orderParam) => (
                    <tr key={orderParam.key}>
                      <td key={orderParam.variant_id}>
                        ● {orderParam.title}
                        {getChildren(orderParam.key).map((child) => (
                          <div key={child.key} className="ms-3">
                            + {child.quantity} x {child.title}
                          </div>
                        ))}
                      </td>
                      <td width="10px">
                        <DeleteButton
                          onClick={() => {
                            onDelete(orderParam);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <Button
              variant="warning"
              className="w-100 mt-3"
              disabled={orderParams.length === 0}
              type="submit"
              onClick={onGenerate}
            >
              <i className="fa fa-ghost"></i> Gerar pedido
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrderSummary;
