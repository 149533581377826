import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import menuItems from 'components/Navigation/menu';
import { getPathDomainFromUrl } from 'lib/url';

const Breadcrumb = () => {
  const navigate = useNavigate();

  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    setCurrentPath(getPathDomainFromUrl());
  }, [navigate]);

  const menuItem = menuItems.find((item) => item.path === currentPath);

  return (
    <div className="breadcrumb">
      <i className="far fa-window-restore"></i>
      {!menuItem && <span>{currentPath}&nbsp;</span>}
      {menuItem && (
        <a alt={menuItem.name} href={menuItem.path}>
          {menuItem.name}
        </a>
      )}
    </div>
  );
};

export default Breadcrumb;
