import React from 'react';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import { NamedRoutes } from 'config/routes/named-routes';

const ProductTypes = () => (
  <CrudList
    title="Tipo de produto"
    columns={{
      name: {
        text: 'Nome',
        render: (resource) => (
          <a
            href={NamedRoutes.product_type_path(resource.id)}
            alt={resource.name}
          >
            {resource.name}
          </a>
        ),
      },
    }}
    resourceAPI={API.ProductType}
  />
);

export default ProductTypes;
