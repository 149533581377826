import { configureStore } from '@reduxjs/toolkit';

import devSlice, { devActions } from './slices/devSlice';
import pageSlice, { pageActions } from './slices/pageSlice';
import formSlice, { formActions } from './slices/formSlice';

const store = configureStore({
  reducer: {
    page: pageSlice.reducer,
    form: formSlice.reducer,
    dev: devSlice.reducer,
  },
});

export default store;

export const storeActions = {
  page: pageActions,
  form: formActions,
  dev: devActions,
};
