import React from 'react';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import { formatDateTimeUsingSlashes } from 'lib/date-utils';

const Leads = () => (
  <CrudList
    title="Mensagens"
    columns={{
      name: { text: 'Nome' },
      email: { text: 'Email' },
      subject: {
        text: 'Status',
        render: (resource) => {
          if (resource.status === 1) {
            return 'Ativo';
          }

          return 'Inativo';
        },
      },
      inserted_at: {
        text: 'Data',
        render: (resource) => formatDateTimeUsingSlashes(resource.inserted_at),
      },
    }}
    resourceAPI={API.Lead}
    hideId
    hideTitle
    hideEditButton
    hideNewButton
  />
);

export default Leads;
