import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import API from 'api';

import AdjustmentsTab from 'pages/orders/components/tabs/AdjustmentsTab';
import Loading from 'components/Loading/Loading';
import LogsTab from 'pages/orders/components/tabs/LogsTab';
import OrderFlowTab from 'pages/orders/components/tabs/OrderFlowTab';
import OverviewTab from 'pages/orders/components/tabs/OverviewTab';
import QuestionnaireTab from 'pages/orders/components/tabs/QuestionnaireTab';
import TimelineTab from 'pages/orders/components/tabs/TimelineTab';

import { formatDateTimeUsingSlashes } from 'lib/date-utils';
import { getPathDomainFromUrl } from 'lib/url';
import { ORDER_STATUSES } from 'lib/status';
import { updateQueryString } from 'lib/query-string';

import './style.scss';
import 'pages/orders/balance-due.scss';

const OrderForm = ({ onSave, onClose, resource }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('order-overview');

  useEffect(() => {
    refetch();
  }, [resource]);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.keyCode === 27) {
        navigate(getPathDomainFromUrl());
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location]);

  const { register, handleSubmit } = useForm({
    defaultValues: resource || {},
  });

  const fetchOrder = async (resourceId) => {
    const response = await API.Order.find(resourceId);
    const { data: body } = response;
    return body.data;
  };

  const {
    data: order,
    isLoading,
    refetch,
  } = useQuery(['order', resource.id], () => fetchOrder(resource.id), {
    enabled: resource !== null && resource !== undefined,
  });

  const handleSelect = (key) => {
    setActiveTab(key);
    updateQueryString(location, navigate, key);
  };

  if (!order || isLoading) {
    return <Loading />;
  }

  const buildLabel = (title, text, url) => {
    const link = (
      <a href={url} alt={text}>
        {text}
      </a>
    );

    const buildText = () => (url ? link : text);

    return (
      <div key={title + text}>
        <Form.Label>
          <strong>{title}: </strong>
          {buildText()}
        </Form.Label>
        <br />
      </div>
    );
  };

  const formatStatus = () => ORDER_STATUSES[order.status].name;

  if (!order) {
    return null;
  }

  return (
    <div className="order-form">
      <Form onSubmit={handleSubmit(onSave)}>
        <Form.Group className="mb-3">
          <section className="order-header-container">
            <span className="back-button text-body" onClick={onClose}>
              <i className="fa fa-arrow-left"></i>
            </span>
            {order.number && (
              <h3 className="me-2">
                <strong className="me-2">#</strong>
                {order.number}
              </h3>
            )}
            <span
              className={`badge mx-1 ${ORDER_STATUSES[order.status].klass}`}
            >
              {formatStatus(order.status)}
            </span>
            {order.billing.balance_due === 0 && (
              <span className="badge mx-1 bg-success">Pago</span>
            )}
            {order.billing.balance_due < 0 && (
              <span className="badge mx-1 bg-danger">
                Devolução:{' '}
                {order.billing.balance_due.formatted.replace('-R', 'R')}
              </span>
            )}
            {order.billing.balance_due > 0 && (
              <span className="badge mx-1 bg-warning">
                Não pago: {order.billing.balance_due.formatted}
              </span>
            )}
          </section>
          <section className="mb-3 d-grid">
            <span>
              Criado em {formatDateTimeUsingSlashes(order.inserted_at)}
            </span>
          </section>

          <Tabs
            defaultActiveKey="order-overview"
            className="mb-3"
            activeKey={activeTab}
            onSelect={handleSelect}
          >
            <Tab eventKey="order-overview" title="Resumo">
              <OverviewTab
                order={order}
                register={register}
                lineItems={order.line_items}
                user={order.user}
                buildLabel={buildLabel}
                refetch={refetch}
              />
            </Tab>

            {Object.keys(order.questionnaire || {}).length > 0 && (
              <Tab eventKey="order-questionnaire" title="Questionário">
                <QuestionnaireTab order={order} buildLabel={buildLabel} />
              </Tab>
            )}

            <Tab eventKey="order-adjustments" title="Movimentações financeiras">
              <AdjustmentsTab
                register={register}
                order={order}
                reload={refetch}
              />
            </Tab>

            <Tab eventKey="logs" title="Logs">
              <LogsTab order={order} />
            </Tab>

            <Tab eventKey="order-flow" title="Fluxo visual">
              <OrderFlowTab order={order} />
            </Tab>

            <Tab eventKey="timeline" title="Linha do tempo">
              <TimelineTab order={order} />
            </Tab>
          </Tabs>
        </Form.Group>
      </Form>
    </div>
  );
};

export default OrderForm;
