import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

import Modal from 'components/Modal/Modal';
import Plans from '../change-variant/Plans';

const VariantDetails = ({ order, lineItem, buildLabel, onClose }) => {
  const [showVariantChangeModal, setShowVariantChangeModal] = useState(false);
  const { variant } = lineItem;

  const booleanText = (bool) => (bool ? 'sim' : 'não');
  const responsibles = variant.responsibles.map((r) => r.name).join(', ');
  const timesOfDay = variant.times_of_day.map((t) => t.name).join(', ');
  const formatLocation = (l) =>
    `* ${l.address} ${l.number || ''}
    ${l.complement || ''} ${l.reference || ''} ${l.city} ${l.state}
    ${l.country} - ${l.zip_code}`;

  const addresses = variant.locations.map((location) => (
    <p key={location}>{formatLocation(location)}</p>
  ));

  const discountTypes = {
    flat: 'Valor fixo',
    percentage: 'Porcentagem',
  };

  return (
    <Container>
      <Row>
        <Col>
          {buildLabel('variante escolhida', variant.title)}
          {buildLabel('Label', variant.label)}

          <Row>
            <Col>
              {order.billing.value_paid > 0 ? (
                <Alert variant="warning" className="w-75 p-1">
                  <Alert.Heading as="h5">
                    <i className="fa fa-exclamation-triangle mx-2"></i>
                    Atenção! Esse pedido <strong>já foi pago</strong>.
                  </Alert.Heading>
                  <p className="mb-1 mx-1">
                    Mudar a variante pode resultar em diferenças na cobrança.
                  </p>
                </Alert>
              ) : (
                ''
              )}

              {(order.status === 'waiting_for_payment' ||
                order.status === 'ready') && (
                <Button
                  variant="dark"
                  type="button"
                  className="mt-4"
                  onClick={() => {
                    setShowVariantChangeModal(true);
                  }}
                >
                  <i className="fa fa-edit"></i> Trocar de variante
                </Button>
              )}
            </Col>
          </Row>
          <br />
          {buildLabel('Reponsáveis', responsibles)}
          {buildLabel('Períodos do dia', timesOfDay)}
          <br />
          <Form.Label>
            <strong>Endereços:</strong>
          </Form.Label>
          {addresses}
        </Col>
        <Col>
          {buildLabel('Date na natureza', booleanText(variant.nature))}
          {buildLabel('Date inteligente', booleanText(variant.brainy))}
          {buildLabel('Movimento', booleanText(variant.movement))}
          {buildLabel('18+', booleanText(variant.over18))}
          {buildLabel(
            'Depende do clima',
            booleanText(variant.weather_dependent),
          )}
          {buildLabel('Precisa de carro', booleanText(variant.car_required))}
          {buildLabel(
            'Bom para 1o encontro',
            booleanText(variant.first_date_friendly),
          )}
          <br />
          {buildLabel(
            'Tipo de desconto',
            discountTypes[variant.billing.discount_type] || 'Nenhum',
          )}
          {buildLabel('Desconto', variant.billing.discount.formatted)}
        </Col>
      </Row>
      <Row>
        <Col>
          *Importante: esses dados da variante não refletem, necessariamente, o
          estado da variante no momento da compra. Ela pode ter sido alterada
          desde então.
        </Col>
      </Row>

      {showVariantChangeModal && (
        <Modal
          className="variant-change-modal"
          title="Troca de variante"
          onClose={onClose}
        >
          <Plans
            order={order}
            lineItem={lineItem}
            onClose={() => {
              setShowVariantChangeModal(false);
              onClose();
            }}
          />
        </Modal>
      )}
    </Container>
  );
};

export default VariantDetails;
