import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import ProductsData from 'pages/products/components/ProductsData/ProductsData';

const Data = () => {
  const [data, setData] = useState(null);

  if (!data) {
    return (
      <div>
        <Button onClick={() => setData('Dados carregados')}>
          Carregar dados
        </Button>
      </div>
    );
  }

  return <ProductsData />;
};

export default Data;
