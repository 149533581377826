import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Form } from 'react-bootstrap';

import API from 'api';

const AdjustmentsForm = ({ order, setLastUpdate, reload }) => {
  const [value, setValue] = useState(0);
  const [method, setMethod] = useState('pix');

  const onCreateAdjustmentHandler = () => {
    API.OrderAdjustment.create(order.id, {
      value,
      method,
    }).then(() => {
      setValue(0);
      setMethod('pix');
      setLastUpdate(Math.floor(Date.now() / 1000));
      reload();
    });
  };

  return (
    <Row>
      <Col>
        <Form.Group className="mb-3">
          <Form.Label>Valor (R$)</Form.Label>
          <Form.Control
            type="number"
            onChange={(e) => {
              setValue(parseInt(e.currentTarget.value, 10));
            }}
            value={value}
          />
        </Form.Group>
      </Col>

      <Col>
        <Form.Label>Método</Form.Label>
        <Form.Select
          aria-label="Método"
          value={method}
          onChange={(e) => {
            setMethod(e.currentTarget.value);
          }}
        >
          <option value="pix">PIX</option>
          <option value="stripe">Stripe</option>
          <option value="ted">TED</option>
          <option value="doc">DOC</option>
          <option value="cash">Dinheiro</option>
        </Form.Select>
      </Col>
      <Col>
        <Button
          variant="primary"
          type="button"
          className="mt-32"
          onClick={onCreateAdjustmentHandler}
        >
          Adicionar
        </Button>
      </Col>
    </Row>
  );
};

export default AdjustmentsForm;
