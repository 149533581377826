import React from 'react';

/*
 * This component is used to preview the refund before it's actually processed.
 * It should display the refund metadata, payment method, type and value.
 *
 * @param {Object} metadata - It contains payment information, like the last
 *   four digits of the credit card, the card brand, etc.
 *
 * @param {String} paymentMethod - The payment method used to process the
 *   refund. It can be 'credit_card', 'store_credit' or 'pix'.
 *
 * @param {String} type - The type of the refund. It can be 'credit', used when
 *   we are going to return values to the users, or 'unhold', when we are
 *   taking back store credits allocated into the order and putting them back
 *   into the users' account.
 *
 * @param {Object} value - The value of the refund.
 *
 */
const PreviewStep = ({ metadata, paymentMethod, type, value }) => {
  const typeTranslation = {
    credit: 'Devolução',
    unhold: 'Estorno de créditos',
  };
  const creditCard = () => (
    <>
      <span className="badge mx-1 bg-secondary">{typeTranslation[type]}</span>
      <span>
        Cartão de crédito&nbsp;
        {metadata && (
          <>
            {metadata.brand} <br /> **** **** **** {metadata.last4}
          </>
        )}
      </span>
      <br />
      <span>
        <strong>Valor:</strong>
        &nbsp;{value}
      </span>
    </>
  );

  const pix = () => (
    <>
      <span className="badge mx-1 bg-secondary">{typeTranslation[type]}</span>
      <span>PIX</span>
      <span>- {value}</span>
    </>
  );

  const storeCredits = () => (
    <>
      <span className="badge mx-1 bg-secondary">{typeTranslation[type]}</span>
      <span>Créditos na loja</span>
      <span>- {value}</span>
    </>
  );

  return (
    <>
      {paymentMethod === 'credit_card' && creditCard()}
      {paymentMethod === 'pix' && pix()}
      {paymentMethod === 'store_credits' && storeCredits()}
    </>
  );
};

export default PreviewStep;
