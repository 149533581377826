import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import Switch from 'components/Form/Switch';
import { ORDER_STATUSES } from 'lib/status';

const OrderConfiguration = ({
  allowRequestChanges,
  initialValues,
  onChange,
}) => {
  const [config, setConfig] = useState(initialValues);

  useEffect(() => {
    setConfig(initialValues);
  }, [initialValues]);

  const fetchUsers = async () => {
    const response = await API.User.all({ page_size: 10000 });
    return response.data.data;
  };

  const { data: users, isLoading: isLoadingUsers } = useQuery(
    [`users-without-pagination`],
    fetchUsers,
  );

  if (isLoadingUsers || !users) {
    return <Loading />;
  }

  const validStatuses = Object.keys(ORDER_STATUSES).filter(
    (key) => key !== 'expired',
  );

  const validPaymentMethods = [
    { id: 'credit_card', name: 'Cartão de crédito' },
    { id: 'pix', name: 'PIX' },
  ];

  const onChangeHandler = (e) => {
    const newConfig = {
      ...config,
      [e.target.name]:
        e.target.value === 'on' ? e.target.checked : e.target.value,
    };
    onChange(newConfig);
    setConfig(newConfig);
  };

  return (
    <div>
      <Row>
        <Col>
          <span>Usuário:</span>
          <Form.Select
            aria-label="Usuário do pedido"
            onChange={onChangeHandler}
            name="userId"
            value={config.userId}
          >
            {users.map((u) => (
              <option key={`order-configuration-user-${u.id}`} value={u.id}>
                {`${u.first_name} ${u.last_name}`}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <span>Status:</span>
          <Form.Select
            aria-label="Status do pedido"
            onChange={onChangeHandler}
            name="status"
            value={config.status}
          >
            {validStatuses.map((s) => (
              <option key={`order-configuration-status-${s}`} value={s}>
                {ORDER_STATUSES[s].name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <span>Método de pagamento:</span>
          <Form.Select
            aria-label="Método de pagamento"
            onChange={onChangeHandler}
            name="paymentMethod"
            value={config.paymentMethod}
          >
            {validPaymentMethods.map((pm) => (
              <option
                key={`order-configuration-payment-method-${pm.id}`}
                value={pm.id}
              >
                {pm.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      {allowRequestChanges && (
        <Row className="mt-3">
          <Col>
            <Switch
              label="Incluir um pedido de mudança"
              onChange={onChangeHandler}
              name="includeRequestChange"
              checked={config.includeRequestChange}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default OrderConfiguration;
