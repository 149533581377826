import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';

const PersonalDataTab = ({ register, resource }) => {
  const statusesToDisplay = () => {
    const statuses = [
      {
        text: 'Acesso temporário',
        value: 'temporary_access',
        showWhenStatusIs: ['temporary_access'],
      },
      {
        text: 'Ativo',
        value: 'active',
        showWhenStatusIs: ['active', 'blocked', 'inactive', 'temporary_access'],
      },
      {
        text: 'Bloqueado',
        value: 'blocked',
        showWhenStatusIs: [
          'active',
          'blocked',
          'created',
          'inactive',
          'temporary_access',
        ],
      },
      {
        text: 'Criado',
        value: 'created',
        showWhenStatusIs: ['created'],
      },
      {
        text: 'Inativo',
        value: 'inactive',
        showWhenStatusIs: [
          'active',
          'blocked',
          'created',
          'inactive',
          'temporary_access',
        ],
      },
    ];

    return statuses.filter((status) =>
      status.showWhenStatusIs.includes(resource.status),
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Label>ID</Form.Label>
          <ClipboardCopy copyText={resource.id} ariaLabel="User ID" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Email"
            placeholder="Email"
            register={register('email', { required: true })}
          />
        </Col>
        <Col>
          <Select
            label="Status"
            value={resource.status}
            register={register('status', { required: true })}
            options={statusesToDisplay().map((status) => ({
              text: status.text,
              value: status.value,
            }))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Nome"
            placeholder="Nome"
            register={register('first_name', { required: true })}
          />
        </Col>
        <Col>
          <Input
            label="Sobrenome"
            placeholder="Sobrenome"
            register={register('last_name', { required: true })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="CPF"
            placeholder="CPF"
            register={register('document', { required: true })}
          />
        </Col>
        <Col>
          <Input
            label="Telefone"
            placeholder="Telefone"
            register={register('phone_number', { required: true })}
          />
        </Col>
        <Col>
          <Input
            label="Data de nascimento"
            placeholder="Data de nascimento"
            register={register('birth_date', { required: true })}
          />
        </Col>
      </Row>
    </>
  );
};

export default PersonalDataTab;
