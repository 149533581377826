/* eslint-disable no-bitwise */
import Resource from '../resource';

export default class Refund extends Resource {
  preview(orderId, lineItemsIds, strategy) {
    return this.client.post('/refunds/preview', {
      refund: {
        order_id: orderId,
        line_item_ids: lineItemsIds,
        strategy,
      },
    });
  }

  complete(refundId) {
    return this.client.post(`/refunds/${refundId}/complete`, {});
  }

  refundManually(refundLog, params = {}) {
    return this.client.post(
      `/refunds/${refundLog.refund_id}/logs/${refundLog.id}/refund_manually`,
      {
        details: params.details,
        method: refundLog.data.instruction.method,
      },
    );
  }
}
