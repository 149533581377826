/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

import API from 'api';

import './styles.scss';

const Plan = ({ plan, lineItem, index, onClose }) => {
  const [selected, setSelected] = useState({});

  const onSelect = (e) => {
    e.preventDefault();

    if (!selected.variantId) {
      Swal.fire({
        text: 'Você precisa escolher qual das variantes você quer',
        icon: 'warning',
      });
    } else {
      const params = {
        store_credit_rule: selected.storeCreditRule,
        refund_rule: selected.refundRule,
      };

      API.VariantChange.update(lineItem.id, plan.variant.id, params).then(
        () => {
          Swal.fire({
            text: 'Variante alterada com sucesso!',
            icon: 'success',
          }).then(() => {
            onClose();
          });
        },
      );
    }
  };

  const validPossibilities = plan.possibilities.filter(
    (opt) =>
      opt.value_to_refund_in_store_credit !== 0 ||
      opt.value_to_refund_in_money !== 0 ||
      opt.value_to_be_paid_in_store_credit !== 0 ||
      opt.value_to_be_paid_in_money !== 0,
  );

  return (
    <div className={`plan card theme-${index % 3}`}>
      <div className="card-body">
        <div className="header-area">
          <h5 className="card-title text-center">{plan.variant.title}</h5>
          <h6 className="card-price text-center">{plan.formatted.price}</h6>
        </div>
        <hr />

        {validPossibilities.length === 0 && (
          <>
            <Form.Check
              name="no-valid-variant"
              type="radio"
              label="Opção 1"
              onClick={() => {
                setSelected({
                  variantId: plan.variant.id,
                  storeCreditRule: plan.possibilities[0].store_credit_rule,
                  refundRule: plan.possibilities[0].refund_rule,
                });
              }}
            />
            <ul className="variant-bullet-list">
              <li>Mudar sem nenhuma cobrança adicional</li>
            </ul>
          </>
        )}

        {validPossibilities.length > 0 &&
          validPossibilities.map((possibility, idx) => (
            <>
              <Form.Check
                key={idx}
                name={`available-variants-${plan.variant.id}`}
                type="radio"
                label={`Opção ${idx + 1}`}
                onClick={() => {
                  setSelected({
                    variantId: plan.variant.id,
                    storeCreditRule: possibility.store_credit_rule,
                    refundRule: possibility.refund_rule,
                  });
                }}
              />
              <ul className="variant-bullet-list">
                {possibility.value_to_refund_in_store_credit !== 0 && (
                  <li>
                    Devolver&nbsp;
                    {possibility.formatted.value_to_refund_in_store_credit}
                    &nbsp;em créditos
                  </li>
                )}

                {possibility.value_to_refund_in_money !== 0 && (
                  <li>
                    Devolver {possibility.formatted.value_to_refund_in_money}
                    &nbsp;em dinheiro
                  </li>
                )}

                {possibility.value_to_be_paid_in_store_credit !== 0 && (
                  <li>
                    Debitar&nbsp;
                    {possibility.formatted.value_to_be_paid_in_store_credit}
                    &nbsp;dos créditos
                  </li>
                )}

                {possibility.value_to_be_paid_in_money !== 0 && (
                  <li>
                    Cobrar {possibility.formatted.value_to_be_paid_in_money}
                  </li>
                )}
              </ul>
            </>
          ))}

        <br />
        <div className="d-grid">
          <a
            href="/"
            className="btn btn-primary text-uppercase"
            onClick={onSelect}
          >
            QUERO ESSA
          </a>
        </div>
      </div>
    </div>
  );
};

export default Plan;
