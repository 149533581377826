import React from 'react';

const userDisplay = ({ userName, userId }) => (
  <div className="userDisplay">
    <i className="fa fa-user-circle"></i>
    <a alt={userName} href={`/usuarios/${userId}/editar`}>
      {userName}
    </a>

    <span>&nbsp;/</span>
  </div>
);

export default userDisplay;
