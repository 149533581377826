import API from 'api';

import OrderForm from 'pages/orders/components/OrderForm';
import Orders from 'pages/orders/orders';

const OrdersController = {
  basePath: '/pedidos',
  form: OrderForm,
  page: Orders,
  resourceAPI: API.Order,
  resourceLoader: (number) => {
    if (isValidUUID(number)) {
      return API.Order.find(number);
    }
    return API.Order.findByNumber(number);
  },
};

const isValidUUID = (input) => {
  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/i;
  return uuidRegex.test(input);
};

export default OrdersController;
