import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import Filters from 'components/Filters/Filters';
import Storage from 'lib/storage';
import Switch from 'components/Form/Switch';

import { formatDate } from 'lib/date-utils';
import { NamedRoutes } from 'config/routes/named-routes';
import { ORDER_STATUSES } from 'lib/status';

import OrderLineItemRow from './components/order-list/OrderLineItemsRow';

import './balance-due.scss';

const Order = () => {
  const [filters, setFilters] = useState({});
  const [showLineItems, setShowLineItems] = useState(
    Storage.get('show-order-line-items', { defaultValue: 'off' }) === 'on',
  );

  const statusFilterOptions = () =>
    Object.keys(ORDER_STATUSES).map((status) => ({
      text: ORDER_STATUSES[status].name,
      value: status,
    }));

  const filterFields = [
    {
      attribute: 'number',
      type: 'text',
      label: 'Número do pedido',
      id: 'order-number-filter',
      klass: 'order-number-filter-container',
    },
    {
      attribute: 'status',
      type: 'select',
      label: 'Status',
      id: 'status-filter',
      klass: 'status-filter-container',
      options: [{ text: 'Todos', value: '' }].concat(statusFilterOptions()),
    },
  ];

  const sortingFields = {
    number: { direction: 'off' },
    status: { direction: 'off' },
    inserted_at: { direction: 'desc' },
  };

  return (
    <>
      <Filters fields={filterFields} applyFilters={setFilters} />
      <br />
      <Switch
        label="Exibir itens de pedido?"
        onChange={() => {
          const newValue = !showLineItems;
          setShowLineItems(newValue);
          Storage.set('show-order-line-items', newValue ? 'on' : 'off');
        }}
        checked={showLineItems}
      />

      <hr />

      <CrudList
        title="Pedidos"
        columns={{
          number: {
            text: 'Número',
            render: (resource) => {
              if (!resource.number || resource.number === '') {
                return (
                  <a
                    href={NamedRoutes.order_path(
                      resource.number || resource.id,
                    )}
                  >
                    <small>S/N</small>
                  </a>
                );
              }

              return (
                <a href={NamedRoutes.order_path(resource.number)}>
                  {resource.number}
                </a>
              );
            },
          },
          actions: {
            text: <i className="fa fa-triangle-exclamation" />,
            render: (resource) => {
              const needsAttention =
                (resource.status === 'waiting_for_payment' ||
                  resource.status === 'placed') &&
                resource.line_items.some(
                  (item) =>
                    item.type === 'variant' && item.status === 'created',
                );

              return (
                <div className="text-center">
                  {/* If order is waiting for payment or ready and has at least one
                  line item that is on the created status, it needs attention */}
                  {needsAttention && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Este pedido possui itens que precisam de agendamento
                        </Tooltip>
                      }
                    >
                      <i className="fa-regular fa-clock me-2"></i>
                    </OverlayTrigger>
                  )}

                  {resource.has_change_request && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Este pedido possui ao menos um item com pedido de
                          mudança pendente
                        </Tooltip>
                      }
                    >
                      <i className="fa-regular fa-comment-dots me-2"></i>
                    </OverlayTrigger>
                  )}
                </div>
              );
            },
          },
          price: {
            text: 'Preço do pedido',
            render: (resource) => resource.billing.price.formatted,
          },
          subtotal: {
            text: 'Subtotal',
            render: (resource) => resource.billing.subtotal.formatted,
          },
          balance: {
            text: 'Saldo devedor',
            render: (resource) => {
              let klass = '';

              const balanceDue = resource.billing.balance_due.in_decimal;

              if (balanceDue > 0) {
                klass = 'to-receive-color';
              } else if (balanceDue < 0) {
                klass = 'to-refund-color';
              }

              return (
                <span className={klass}>
                  {resource.billing.balance_due.formatted}
                </span>
              );
            },
          },
          status: {
            text: 'Status',
            render: (resource) => (
              <span
                className={`badge ${ORDER_STATUSES[resource.status].klass}`}
              >
                {ORDER_STATUSES[resource.status].name}
              </span>
            ),
          },
          inserted_at: {
            text: 'Criado em',
            render: (resource) => formatDate(resource.inserted_at),
          },
        }}
        resourceAPI={API.Order}
        resourceKey="number"
        hideId
        hideDeleteButton
        hideNewButton
        hideTitle
        filters={filters}
        sortingFields={sortingFields}
        rowDetails={showLineItems ? OrderLineItemRow : null}
        stickyHeader
      />
    </>
  );
};

export default Order;
