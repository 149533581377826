import React from 'react';
import Form from 'react-bootstrap/Form';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const OrderIdInput = ({ onChange, orderId }) => {
  const infoTooltip = (
    <Tooltip>
      Você consegue o ID do pedido na aba <strong>Dados gerais</strong>, na
      página de edição de um pedido.
    </Tooltip>
  );

  const overlay = (
    <OverlayTrigger placement="top" overlay={infoTooltip}>
      <i className="ms-2 fa-solid fa-circle-info text-secondary"></i>
    </OverlayTrigger>
  );

  return (
    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>
          Order ID
          {overlay}
        </Form.Label>
        <Form.Control type="text" onChange={onChange} value={orderId || ''} />
      </Form.Group>
    </Form>
  );
};

export default OrderIdInput;
