import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Confetti from 'react-confetti';
import Row from 'react-bootstrap/Row';
import { useAuth } from 'contexts/AuthContext';

import AddAdditionalRequest from './AddAdditionalRequest';
import AddGiftCard from './AddGiftCard';
import AddVariant from './AddVariant';
import Favorites from './Favorites';
import OrderConfiguration from './OrderConfiguration';
import OrderSummary from './OrderSummary';

import './styles.scss';
import GenerationModal from './GenerationModal';

const OrderFactory = () => {
  const { currentUser } = useAuth();

  const [orderParams, setOrderParams] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [config, setConfig] = useState({
    userId: currentUser.id,
    status: 'pre_booking',
    paymentMethod: 'credit_card',
    includeRequestChange: false,
  });
  const [showGenerationModal, setShowGenerationModal] = useState(false);

  const isProduction = !['qa.makemydate.com.br', 'localhost'].includes(
    window.location.hostname,
  );

  const addLineItem = (params) => {
    const newOrderParams = [...orderParams];
    newOrderParams.push(params);
    setOrderParams(newOrderParams);
  };

  if (isProduction) {
    return (
      <div className="card">
        <div className="card-header">
          <i className="fa-solid fa-cart-shopping"></i> &nbsp; Gerador de
          pedidos
        </div>
        <div className="card-body">
          <div className="alert alert-danger">
            <i className="fa-solid fa-exclamation-triangle"></i> &nbsp; Você não
            pode usar o gerador de pedidos em produção
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Row className="order-factory-component">
        {completed && <Confetti />}
        <Col xs={8}>
          <div className="card">
            <div className="card-header">
              <i className="fa-solid fa-cart-shopping"></i> &nbsp; Itens no
              carrinho
            </div>
            <div className="card-body">
              <Row>
                <Col>
                  <AddVariant
                    onAdd={addLineItem}
                    orderParams={orderParams}
                    setOrderParams={setOrderParams}
                  />
                </Col>
                <Col className="border-start">
                  <AddGiftCard onAdd={addLineItem} />
                </Col>
              </Row>
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header">
              <i className="fa-solid fa-cart-shopping"></i> &nbsp; Pedidos
              adicionais
            </div>
            <div className="card-body">
              <AddAdditionalRequest
                variantLineItems={orderParams.filter(
                  (p) => p.type === 'variant',
                )}
                onAdd={addLineItem}
              />
            </div>
          </div>

          <div className="card mt-4">
            <div className="card-header">
              <i className="fa-regular fa-star"></i> &nbsp; Favoritos
            </div>
            <div className="card-body">
              <Favorites
                orderParams={orderParams}
                orderConfig={config}
                setOrderParams={setOrderParams}
                setOrderConfig={setConfig}
              />
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <Row>
            <Col>
              <OrderSummary
                orderParams={orderParams}
                onGenerate={async () => {
                  setShowGenerationModal(true);
                }}
                onDelete={(orderParam) => {
                  const newList = orderParams.filter(
                    (item) => item !== orderParam,
                  );

                  setOrderParams(newList);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="card">
                <div className="card-header">
                  <i className="fa-solid fa-gear"></i> &nbsp; Configurações
                </div>
                <div className="card-body">
                  <OrderConfiguration
                    allowRequestChanges={orderParams.some(
                      (p) => p.type === 'variant',
                    )}
                    initialValues={config}
                    onChange={setConfig}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {showGenerationModal && (
        <GenerationModal
          orderParams={orderParams}
          orderConfig={config}
          onClose={() => setShowGenerationModal(false)}
          onComplete={() => {
            setCompleted(true);
            setTimeout(() => {
              setCompleted(false);
            }, 5000);
          }}
        />
      )}
    </>
  );
};

export default OrderFactory;
