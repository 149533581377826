import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

import { useDispatch } from 'react-redux';
import { weekDaysString } from 'lib/week-days';

import API from 'api';

import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';
import {
  formatDateTime,
  formatDate,
  formatDateTimeUsingSlashes,
  convertToUTC,
  parseDateAndTimeUsingSlashes,
} from 'lib/date-utils';
import TodoList from 'components/TodoList/TodoList';

import { alert, confirm, confirmWithCustomHtml } from 'lib/notifications';
import { htmlForBookingLineItem } from 'pages/orders/helpers/changeStatusModal';
import { keyPrefix } from 'components/TodoList/keyPrefix';
import { LINE_ITEM_STATUSES } from 'lib/status';
import { storeActions } from 'store';

import ActionButton from '../shared/ActionButton';
import AdditionalRequests from '../line-item-sections/AdditionalRequests';
import ChangesRequested from '../line-item-sections/ChangesRequested';
import CustomFields from '../line-item-sections/CustomFields';
import LineItemPriceSummary from './LineItemPriceSummary';
import Participants from '../line-item-sections/Participants';
import VariantDetails from '../line-item-sections/VariantDetails';

import './overview-styles.scss';

const VariantOverview = ({ buildLabel, lineItem, order, refetch }) => {
  const dispatch = useDispatch();
  const { billing, variant } = lineItem;
  const responsibles = variant.responsibles.map((r) => r.name).join(', ');

  const bookAction = {
    icon: 'fa-calendar-days',
    text: 'Agendar',
    onClick: () => {
      const text = `Chegou a hora de agendar o item <b>#${lineItem.number}</b>`;

      confirmWithCustomHtml({
        title: 'Agendamento',
        icon: 'no-icon',
        onConfirm: (values) => {
          if (values.scheduled_for !== '') {
            API.LineItem.book(order.id, lineItem.id, {
              comment: values.comment,
              scheduled_for: convertToUTC(values.scheduledFor),
            }).then(() => {
              alert({
                text: 'Item agendado com sucesso!',
                icon: 'success',
                callback: () => refetch(),
              });
            });
          } else {
            alert({ text: 'Lembre-se de escolher uma data para o date' });
          }
        },
        html: htmlForBookingLineItem(text, lineItem),
        preConfirm: () => ({
          comment: document.getElementById('swal2-comment').value,
          scheduledFor: document.getElementById('swal2-datetime').value,
        }),
      });
    },
  };

  const completeAction = {
    icon: 'fa-square-check',
    text: 'Completar',
    onClick: () => {
      const text = `Você está prestes a completar o item ${lineItem.number} - ${lineItem.title}. Deseja continuar?`;

      confirm({
        title: 'Alteração de status',
        text,
        onConfirm: () => {
          API.LineItem.complete(lineItem.id).then(() => {
            alert({
              text: 'Item completado com sucesso!',
              icon: 'success',
              callback: () => {
                dispatch(storeActions.page.showConfetti());
                refetch();

                setTimeout(() => {
                  dispatch(storeActions.page.hideConfetti());
                }, 5000);
              },
            });
          });
        },
      });
    },
  };

  const unfulfillAction = {
    icon: 'fa-thumbs-down',
    text: 'Não aconteceu',
    onClick: () => {
      const text = `Você está prestes a marcar o item ${lineItem.number} - ${lineItem.title} como "Não aconteceu". Deseja continuar?`;

      confirm({
        title: 'Alteração de status',
        text,
        onConfirm: () => {
          API.LineItem.unfulfill(lineItem.id).then(() => {
            alert({
              text: 'Item atualizado com sucesso!',
              icon: 'success',
              callback: () => refetch(),
            });
          });
        },
      });
    },
  };

  const actionsPerStatus = {
    created: [bookAction],
    booked: [bookAction, completeAction, unfulfillAction],
    completed: [],
    canceled: [],
    unfulfilled: [],
  };

  const lineItemActions =
    lineItem.type === 'variant' ? actionsPerStatus[lineItem.status] : [];

  const showBookingBox =
    lineItem.booking &&
    lineItem.booking.start_date &&
    !['completed', 'unfulfilled', 'canceled'].includes(lineItem.status);
  const showActionsBox = lineItemActions.length > 0;

  const scheduledDateTimeForCalendar = parseDateAndTimeUsingSlashes(
    lineItem.scheduled_for,
  );

  const { location } = lineItem;
  const calendarDetails = [
    `Item de pedido ${lineItem.number}`,
    `Participantes: ${lineItem.participants.map((p) => p.name).join(', ')}`,
  ].join('\n');

  return (
    <div className="variant-overview">
      <Row className="card flex-row mb-3">
        <Col className="col-3 px-0">
          <img
            className="overview-img"
            src={lineItem.image_url}
            alt={lineItem.title}
          />
        </Col>

        <Col className="py-2 col-6 d-flex flex-column justify-content-between">
          <Row>
            <h4>
              <strong>{lineItem.title}</strong>
            </h4>

            <span>#&nbsp;{lineItem.number}</span>

            <span
              className={`badge ${LINE_ITEM_STATUSES[lineItem.status].klass}`}
            >
              {LINE_ITEM_STATUSES[lineItem.status].name}
            </span>
          </Row>
          <Row>
            {buildLabel(
              'Pedido criado em',
              formatDateTimeUsingSlashes(lineItem.inserted_at),
            )}
          </Row>
        </Col>

        <Col className="col-3 p-1 border-start d-flex flex-column align-items-center">
          <LineItemPriceSummary billing={billing} lineItem={lineItem} />
        </Col>
      </Row>
      {(showBookingBox || showActionsBox) && (
        <Row>
          {showBookingBox && (
            <Col className="ps-0">
              <div className="card mb-3">
                <div className="card-header">
                  <i className="fa fa-calendar-days"></i> &nbsp; Agendamento
                  {!lineItem.scheduled_for && (
                    <span className="badge mx-1 bg-warning">Pendente</span>
                  )}
                </div>
                <div className="card-body">
                  {lineItem.scheduled_for && (
                    <h5>
                      Agendado para {formatDateTime(lineItem.scheduled_for)}
                    </h5>
                  )}

                  {!lineItem.scheduled_for &&
                    lineItem.booking.start_date &&
                    lineItem.booking.from_time &&
                    lineItem.booking.to_time &&
                    lineItem.booking.week_days && (
                      <>
                        <p>
                          <strong>Preferência</strong>: a partir de{' '}
                          {formatDate(lineItem.booking.start_date)}, entre{' '}
                          {lineItem.booking.from_time} e{' '}
                          {lineItem.booking.to_time}
                        </p>

                        <h6>
                          {weekDaysString(lineItem.booking.week_days)
                            .split(', ')
                            .map((weekDay) => (
                              <span
                                key={`variant-overview-weekday-${weekDay}`}
                                className="badge mx-1 bg-secondary"
                              >
                                {weekDay}
                              </span>
                            ))}
                        </h6>
                        <br />
                      </>
                    )}

                  {lineItem.booking.comment
                    ? buildLabel('Comentário', lineItem.booking.comment)
                    : ''}

                  {lineItem.scheduled_for && (
                    <AddToCalendarButton
                      name={lineItem.title}
                      startDate={scheduledDateTimeForCalendar.startDate}
                      endDate={scheduledDateTimeForCalendar.endDate}
                      startTime={scheduledDateTimeForCalendar.startTime}
                      endTime={scheduledDateTimeForCalendar.endTime}
                      options={['Google']}
                      timeZone="America/Sao_Paulo"
                      location={[
                        location.address,
                        location.number,
                        location.complement,
                        location.city,
                        location.state,
                        location.zip_code,
                      ]
                        .filter((item) => item)
                        .join(', ')}
                      description={calendarDetails}
                    ></AddToCalendarButton>
                  )}
                </div>
              </div>
            </Col>
          )}

          {showActionsBox && (
            <Col className="pe-0 ps-0">
              <div className="card mb-3">
                <div className="card-header">
                  <i className="fa fa-fire-flame-curved"></i> &nbsp; Ações
                </div>
                <div className="card-body d-flex">
                  {lineItemActions.map((action) => (
                    <ActionButton
                      key={`variant-overview-action-${action.text}`}
                      icon={action.icon}
                      text={action.text}
                      onClick={action.onClick}
                    />
                  ))}
                </div>
              </div>
            </Col>
          )}
        </Row>
      )}
      <Row>
        <div className="card px-0 mb-3">
          <div className="card-header">
            <i className="fa fa-bell-concierge"></i> &nbsp; Pedidos adicionais
          </div>
          <div className="card-body">
            <AdditionalRequests
              order={order}
              lineItem={lineItem}
              refetch={refetch}
            />
          </div>
        </div>
      </Row>
      {lineItem.changes_requested && lineItem.changes_requested.length > 0 && (
        <Row>
          <div className="card px-0 mb-3">
            <div className="card-header">
              <i className="fa-regular fa-comment-dots"></i> &nbsp; Pedidos de
              mudança
            </div>
            <div className="card-body">
              <ChangesRequested
                order={order}
                lineItem={lineItem}
                onChange={refetch}
              />
            </div>
          </div>
        </Row>
      )}
      <Row>
        <div className="card px-0 mb-3">
          <div className="card-header">
            <i className="fa fa-list-check"></i> &nbsp; Lista de tarefas
          </div>
          <div className="card-body">
            <div className="card-title">
              {buildLabel('Reponsáveis', responsibles)}
            </div>
            <h5>Tarefas:</h5>
            <div className="form-check">
              <TodoList
                list={lineItem.todo_list || []}
                attributes={{
                  onChange: (newTodoList) => {
                    API.LineItem.updateTodoList(lineItem.id, newTodoList);
                  },
                  aggregations: [
                    {
                      title: 'Custo Estimado',
                      initialValue: 0,
                      fn: (task, acc) => acc + parseFloat(task.cost),
                      format: (value) =>
                        value.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }),
                    },
                    {
                      title: 'Custo Total',
                      initialValue: 0,
                      fn: (task, acc) =>
                        task.done ? acc + parseFloat(task.cost) : acc,
                      format: (value) =>
                        value.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }),
                    },
                  ],
                  types: [
                    {
                      name: 'task',
                      type: 'text',
                      title: 'Tarefa',
                    },
                    {
                      name: 'cost',
                      type: 'number',
                      title: 'Custo',
                      render: (item, attrIndex) => {
                        const key = `${keyPrefix(item)}-task-item-${attrIndex}`;
                        return (
                          <span className="task-cost" key={key}>
                            [R${' '}
                            {parseFloat(item.cost || 0)
                              .toString()
                              .replace('.', ',')}
                            ]
                          </span>
                        );
                      },
                      value: (item) => item.cost,
                    },
                    {
                      name: 'comment',
                      type: 'textarea',
                      title: 'Comentário',
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </Row>
      <Participants lineItem={lineItem} variant={variant} refetch={refetch} />

      {lineItem.custom_fields && lineItem.custom_fields !== {} && (
        <CustomFields lineItem={lineItem} variant={variant} />
      )}
      <Row className="card mb-3">
        <div className="card-header">
          <i className="fa fa-wand-magic-sparkles"></i> &nbsp; Variante
        </div>
        <div className="card-body">
          <VariantDetails
            order={order}
            lineItem={lineItem}
            buildLabel={buildLabel}
            onClose={refetch}
          />
        </div>
      </Row>
      <Row>
        <Col>
          {lineItem.documentation && lineItem.documentation.length > 0 && (
            <Button
              variant="dark"
              type="button"
              className="mt-4"
              onClick={() => {
                window.open(lineItem.documentation, '_blank');
              }}
            >
              <i className="fa fa-book-reader"></i> Leia a documentação
            </Button>
          )}
        </Col>
        <Col>
          <strong>ID do item de pedido:</strong>
          <ClipboardCopy copyText={lineItem.id} ariaLabel="LineItem ID" />
        </Col>
      </Row>
    </div>
  );
};

export default VariantOverview;
