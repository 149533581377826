import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import API from 'api';

import ActivationTab from 'pages/users/components/ActivationTab';
import AddressTab from 'pages/users/components/AddressTab';
import Loading from 'components/Loading/Loading';
import PersonalDataTab from 'pages/users/components/PersonalDataTab';
import StoreCreditsTab from 'pages/users/components/StoreCreditsTab';

import { updateQueryString } from 'lib/query-string';

import './styles.scss';

const UserForm = ({ onSave, onClose, resource }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('personal-data');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location]);

  const handleSelect = (key) => {
    setActiveTab(key);
    updateQueryString(location, navigate, key);
  };

  const { register, handleSubmit } = useForm({
    defaultValues: resource || {},
  });

  const fetchStates = async () => {
    const response = await API.State.all();
    const { data: body } = response;

    return body.data;
  };

  const { data: states, isLoading } = useQuery('states', fetchStates);

  if (!states || isLoading) {
    return <Loading />;
  }

  const onSubmit = (formData) => {
    onSave(formData);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Tabs
          defaultActiveKey="personal-data"
          className="mb-3"
          activeKey={activeTab}
          onSelect={handleSelect}
        >
          <Tab eventKey="personal-data" title="Dados pessoais">
            <PersonalDataTab register={register} resource={resource} />
          </Tab>

          <Tab eventKey="address-data" title="Endereço">
            <AddressTab
              register={register}
              resource={resource}
              states={states}
            />
          </Tab>

          {(resource?.status === 'inactive' ||
            resource?.status === 'temporary_access') && (
            <Tab eventKey="activation-data" title="Ativação de conta">
              <ActivationTab resource={resource} />
            </Tab>
          )}

          <Tab eventKey="store-credits-data" title="Créditos">
            <StoreCreditsTab resource={resource} />
          </Tab>
        </Tabs>

        <br />
        <br />
        <hr />

        <section className="form-actions">
          <Button variant="secondary" onClick={onClose}>
            Voltar
          </Button>

          <Button variant="primary" type="submit">
            Salvar
          </Button>
        </section>
      </Form.Group>
    </Form>
  );
};

export default UserForm;
