export const defaultAdditionalInformation = {
  instructions: [
    {
      data: [
        'A Make My Date não é um site de relacionamentos, mas sim de experiências para casais já formados;',
        'Atualmente operamos apenas na Grande São Paulo;',
        'Todos os nossos preços dizem respeito a 2 pessoas.',
      ],
      title: 'Importante',
      type: 'bullet',
    },
    {
      data: [
        'Possui estacionamento conveniado;',
        'Todas as exposições em funcionamento estão inclusas.',
      ],
      title: 'Avisos',
      type: 'bullet',
    },
    {
      data: [
        'Fique à vontade! Você pode ficar até o horário de fechamento do museu.',
      ],
      title: 'Duração',
      type: 'paragraph',
    },
    {
      data: [
        'Você receberá a informação completa da empresa responsável assim que receber o comprovante de reserva. Não se preocupe, nós selecionamos com muito carinho quem cuida de nossas experiências :-).',
      ],
      title: 'Sobre a empresa responsável',
      type: 'paragraph',
    },
    {
      data: ['Esse date não depende das condições climáticas.'],
      title: 'Condições climáticas',
      type: 'paragraph',
    },
    {
      data: ['O que quiser! Só recomendamos calçados confortáveis.'],
      title: 'O que vestir',
      type: 'paragraph',
    },
    {
      data: [
        'Obrigatório o uso de máscaras e distanciamento social, álcool gel será providenciado em diversos pontos do museu.',
      ],
      title: 'Medidas de prevenção COVID-19',
      type: 'paragraph',
    },
  ],
};
