import React from 'react';

const menuItem = (props) => {
  const { name, icon, active, onClick } = props;

  return (
    <div
      key={name}
      className={`menu-item ${active ? 'active' : ''}`}
      onClick={onClick}
    >
      <i className={`fa fa-${icon}`}></i>
      <span className="title">{name}</span>
    </div>
  );
};

export default menuItem;
