/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from 'react-query';

import API from 'api';

import Loading from 'components/Loading/Loading';
import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';
import OrderIdInput from './OrderIdInput';

import './webhook-simulator.scss';

const WebhookSimulator = () => {
  const [finalMessage, setFinalMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorDetails, setErrorDetails] = useState(null);
  const [currentPayload, setCurrentPayload] = useState(null);

  const isProduction = !['qa.makemydate.com.br', 'localhost'].includes(
    window.location.hostname,
  );

  const fetchOrder = async () => {
    const response = await API.Order.find(orderId);
    return response.data.data;
  };

  const { data: order, isLoading: isLoadingOrder } = useQuery(
    [`webhook-simulator-order-${orderId}`],
    fetchOrder,
    {
      enabled: orderId !== undefined && orderId !== null,
    },
  );

  if (isProduction) {
    return (
      <div className="card webhook-simulator">
        <div className="card-header">
          <i className="fa-solid fa-user-secret"></i> &nbsp; Webhook Simulator
        </div>
        <div className="card-body">
          <div className="alert alert-danger">
            <i className="fa-solid fa-exclamation-triangle"></i> &nbsp; Você não
            pode usar o simulador de webhook em produção
          </div>
        </div>
      </div>
    );
  }

  const buildPayload = () => {
    if (order.payment_method === 'pix') {
      return buildOpenPixPayload();
    }

    return buildStripePayload();
  };

  const buildOpenPixPayload = () => ({
    mock_webhook: true,
    mock_webhook_order_number: order.number,
    charge: {
      comment: 'Webhook Simulator',
      correlationID: `${order.number}--${uuidv4()}--${
        window.location.href.includes('localhost') ? 'dev' : 'production'
      }`,
      paymentLinkUrl:
        'https://openpix.com.br/pay/078ea8e1-06a8-4d03-8926-c162796bff9d',
      pixKey: '881b0813-108c-48e0-9e70-53e7bef7c975',
      qrCodeImage:
        'https://api.openpix.com.br/openpix/charge/brcode/image/078ea8e1-06a8-4d03-8926-c162796bff9d.png',
    },
    pix: {
      endToEndId: `${order.number}E9e2252598cae467380c839ba13640cfd`,
    },
    event: 'OPENPIX:CHARGE_COMPLETED',
  });

  const buildStripePayload = () => ({
    id: `evt_${order.number}3ONKGUIMLFt7ePrx03m0HX8n`,
    object: 'event',
    api_version: '2022-11-15',
    mock_webhook: true,
    data: {
      object: {
        id: `pi_${order.number}3ONKGUIMLFt7ePrx0gjHHkYq`,
        object: 'payment_intent',
        amount: order.billing.balance_due.in_cents,
        amount_received: order.billing.balance_due.in_cents,
        client_secret: `pi_${order.number}3ONKGUIMLFt7ePrx0gjHHkYq_secret_PNFsw0d73SkdtlIBScHep6sOr`,
        currency: 'brl',
        metadata: {
          order_number: order.number,
          local_env: `${window.location.href.includes('localhost')}`,
          user_id: order.user_id,
          order_id: order.id,
        },
        payment_method: `pm_${order.number}1ONKGTIMLFt7ePrxWCeH2xt4`,
        payment_method_types: ['card'],
        status: 'succeeded',
      },
    },
    type: 'payment_intent.succeeded',
  });

  const sendRequest = () => {
    setErrorMessage('');
    setIsLoading(true);

    const payload = buildPayload(order);
    setCurrentPayload(payload);

    API.Payment.paymentNotification(payload)
      .then(() => {
        setFinalMessage('Envio concluído com sucesso');
      })
      .catch((error) => {
        setErrorMessage(`Error fetching checkout URL: ${error.message}`);
        setErrorDetails(error.config.data);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="card webhook-simulator">
      <div className="card-header">
        <i className="fa-solid fa-user-secret"></i> &nbsp; Webhook Simulator
      </div>
      <div className="card-body">
        <p>
          Simule o retorno do processador de pagamento como se o usuário tivesse
          feito um pagamento
        </p>
        <Row className="mt-2">
          <Col>
            <OrderIdInput
              orderId={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
            {order && (
              <>
                <label htmlFor="orderNumber">Número:</label>
                <ClipboardCopy copyText={order.number} />
              </>
            )}
          </Col>
        </Row>

        {isLoadingOrder && <Loading className="loading-order" />}

        {!isLoadingOrder && order && !order.payment_method && (
          <Row>
            <Col className="mt-3">
              <Alert variant="warning">
                O pedido não possui nenhum método de pagamento
              </Alert>
            </Col>
          </Row>
        )}

        {!isLoadingOrder && order && order.payment_method && (
          <Row>
            <Col>
              <label htmlFor="paymentProcessor">
                Processador de pagamento:
              </label>
              <select
                id="paymentProcessor"
                className="form-control"
                value={order.payment_method}
                disabled
              >
                <option value="credit_card">Stripe</option>
                <option value="pix">OpenPix</option>
              </select>
            </Col>
            <Col>
              <label htmlFor="paymentAmount">Valor (em centavos):</label>
              <input
                type="number"
                id="paymentAmount"
                className="form-control"
                value={order.billing.balance_due.in_cents}
                disabled
              />
            </Col>
          </Row>
        )}

        <div className="form-group mt-4 d-flex">
          <button
            className="btn btn-primary ms-2"
            disabled={!orderId || isLoading}
            type="button"
            onClick={sendRequest}
          >
            Simular
          </button>
        </div>

        <hr />

        {isLoading && <Loading className="webhook-simulator-loading" />}

        {!isLoading && finalMessage && (
          <div>
            <small className="text-success fw-bold">POST</small>{' '}
            {process.env.REACT_APP_BACKEND_API_URL}
            /api/payment-notifications
            <br />
            {currentPayload && (
              <pre>{JSON.stringify(currentPayload, null, 2)}</pre>
            )}
          </div>
        )}

        {!isLoading && errorMessage && (
          <div className="text-danger">{errorMessage}</div>
        )}
        {!isLoading && errorDetails && <pre>{errorDetails}</pre>}
      </div>
    </div>
  );
};

export default WebhookSimulator;
