import API from 'api';

import Dev from 'pages/dev/dev';

const DevController = {
  basePath: '/dev',
  form: null,
  page: Dev,
  resourceAPI: API.Dev,
};

export default DevController;
