import React from 'react';

/* eslint-disable no-param-reassign */

// This function will build the entire log diagram using React Flow. It will
// use data from the regular orger logs and build a diagram with nodes and edges
// representing each change on the order and its line items.
//
// The x axis will represent the time of the change, meaning that the further
// to the right a node is, the later it happened in the order lifecycle.
//
// Click on the node to see more details about it.
export const buildNodesAndEdges = (options = {}) => {
  const { animatedEdges, order, logs, onClick } = options;
  const elements = { lineItemsNodes: [], ordersNodes: [], edges: [] };
  const lineHeight = 80;
  const nodeWidth = 200;
  const orderLineY = order.line_items.length * (lineHeight + 50);
  let nextLineItemPosition = { x: 200, y: -1 * lineHeight };
  let lastXPosition = -1 * nodeWidth;

  const findMaxXPosition = () => {
    const nodesXPositions = elements.ordersNodes
      .concat(elements.lineItemsNodes)
      .map((node) => parseInt(node.data.lastXPosition, 10))
      .sort((a, b) => a - b);

    return nodesXPositions[nodesXPositions.length - 1] || 0;
  };

  logs.forEach((log, index) => {
    lastXPosition = findMaxXPosition() + nodeWidth;

    const params = {
      elements,
      order,
      index,
      orderLineY,
      log,
      onClick,
      lastXPosition,
      animatedEdges,
    };

    switch (true) {
      case /Pedido criado/.test(log.comment): {
        plotOrderCreated(params);
        break;
      }
      case /Item adicionado ao pedido/.test(log.comment): {
        nextLineItemPosition = {
          x: nextLineItemPosition.x,
          y: nextLineItemPosition.y + lineHeight,
        };

        const lineItemStatus = order.line_items.find(
          (li) => li.id === log.extra_information[0].value,
        )?.status;
        const style = {};

        const statusIcons = {
          created: 'fa-solid fa-star-of-life',
          booked: 'fa fa-clock',
          completed: 'fa-regular fa-thumbs-up text-success',
          unfulfilled: 'fa-regular fa-thumbs-down text-danger',
          canceled: 'fa-solid fa-xmark text-danger',
        };

        if (!lineItemStatus || lineItemStatus === undefined) {
          style.backgroundColor = '#ea6571';
          style.color = '#fff';
        }

        const icon =
          statusIcons[lineItemStatus] || 'fa-solid fa-xmark text-danger';

        plotLineItemNode(log.extra_information[1].value, {
          ...params,
          icon,
          position: nextLineItemPosition,
          // lastXPosition should not change for line items, we want them
          // to be on the same column in the graph.
          lastXPosition: nodeWidth,
          representsLineItem: true,
          style,
        });
        break;
      }
      case /Usuário (.*) vinculado ao pedido/.test(log.comment): {
        const icon = 'fa fa-user';
        plotOrderNode('Usuário vinculado ao pedido', { ...params, icon });
        break;
      }
      case /Participantes adicionados ao item do pedido/.test(log.comment): {
        const icon = 'fa-solid fa-users';
        plotLineItemNode('Participantes adicionados', { ...params, icon });
        break;
      }
      case /Informações de agendamento adicionadas ao item/.test(log.comment): {
        plotLineItemNode('Informações de agendamento adicionadas', params);
        break;
      }
      case /Pedido gerado com número(.*)/.test(log.comment): {
        const style = { backgroundColor: '#212529', color: '#fff' };
        plotOrderNode('Pedido requisitado', { ...params, style });
        break;
      }
      case /Agendamento realizado/.test(log.comment): {
        const icon = 'fa fa-clock';
        plotLineItemNode('Item agendado', { ...params, icon });
        break;
      }
      case /Pedido pronto para pagamento/.test(log.comment): {
        const style = { backgroundColor: '#FFC108' };
        plotOrderNode('Aguardando pagamento', { ...params, style });
        break;
      }
      case /Mudança de método de pagamento/.test(log.comment): {
        const label = `Método de pagamento: ${log.extra_information[0].value}`;
        plotOrderNode(label, params);
        break;
      }
      case /Pedido de alteração solicitado/.test(log.comment): {
        const icon = 'fa-regular fa-comment-dots';
        plotLineItemNode('Pedido de alteração', { ...params, icon });
        break;
      }
      case /Aprovação de pedido de mudança/.test(log.comment): {
        const icon = 'fa fa-check text-success';
        plotLineItemNode('Aprovação no pedido de mudança', { ...params, icon });
        break;
      }
      case /Rejeição de pedido de mudança/.test(log.comment): {
        const icon = 'fa-solid fa-xmark text-danger';
        plotLineItemNode(log.comment, { ...params, icon });
        break;
      }
      case /Pedido pronto/.test(log.comment): {
        const style = { backgroundColor: '#0DCAF0' };
        plotOrderNode(log.comment, { ...params, style });
        break;
      }
      case /Pedido concluído/.test(log.comment): {
        const style = { background: '#198754', color: '#fff' };
        plotOrderNode(log.comment, { ...params, style });
        break;
      }
      case /Pedido expirado/.test(log.comment): {
        const style = { background: '#DC3545', color: '#fff' };
        plotOrderNode(log.comment, { ...params, style });
        break;
      }
      case /Cartão-presente criado/.test(log.comment): {
        plotLineItemNode(log.comment, params);
        break;
      }
      case /Item cancelado/.test(log.comment): {
        const icon = 'fa-solid fa-xmark text-danger';
        plotLineItemNode(log.comment, { ...params, icon });
        break;
      }
      case /Item concluído/.test(log.comment): {
        const icon = 'fa-regular fa-thumbs-up text-success';
        plotLineItemNode(log.comment, { ...params, icon });
        break;
      }
      case /Item não realizado/.test(log.comment): {
        const icon = 'fa-regular fa-thumbs-down text-danger';
        plotLineItemNode(log.comment, { ...params, icon });
        break;
      }
      case /Pedido adicional removido do pedido/.test(log.comment): {
        const icon = 'fa-solid fa-minus text-danger';
        plotLineItemNode(log.comment, { ...params, icon });
        break;
      }
      case /Pedido adicional/.test(log.comment): {
        const icon = 'fa-solid fa-plus text-success';
        plotLineItemNode(log.comment, { ...params, icon });
        break;
      }
      case /Item de pedido atualizado/.test(log.comment): {
        plotLineItemNode(log.comment, params);
        break;
      }
      case /Item removido do pedido/.test(log.comment): {
        const icon = 'fa-solid fa-minus text-danger';
        plotLineItemNode(log.comment, { ...params, icon });
        break;
      }
      case /Data de expiração estendida/.test(log.comment): {
        plotOrderNode(log.comment, params);
        break;
      }
      default:
        break;
    }
  });

  // at the end, we will find all leaves of the tree and set the type to
  // 'output' to remove the output handle
  return removeOutputHandles(elements);
};

const removeOutputHandles = (elements) => {
  const sources = elements.edges.map((edge) => edge.source);
  elements.lineItemsNodes.forEach((node) => {
    if (!sources.includes(node.id)) {
      node.type = 'output';
    }
  });

  elements.ordersNodes.forEach((node) => {
    if (!sources.includes(node.id)) {
      node.type = 'output';
    }
  });

  return elements;
};

const buildLabel = (text, icon = null) => {
  if (!icon) {
    return text;
  }

  return (
    <>
      <i className={`${icon} me-2`}></i>
      {text}
    </>
  );
};

// This function will plot a node representing an order change in the diagram.
// The order changes will be plotted in the lower part of the diagram, while the
// line items changes will be plotted above it.
//
// The new node will have an edge connecting it to the last order node, so the
// diagram will show the order of the changes.
//
// The node will have a label with the text of the node and an options hash
// that allows us to configure the node. Valid options are:
//
// * animatedEdges: boolean, if the edge connecting the nodes should be
//   animated or not.
//
// * elements: object, the elements object that will be used to store the nodes
//   and edges. It contains the lineItemsNodes, ordersNodes and edges.
//
// * icon: string, the bootstrap icon classes (and virtually any other class
//   you need) that will be used in the label. If no icon is provided, the
//   label will be just the text.
//
// * index: number, the index of the node in the logs array. It will be used to
//   uniquely identify a node by being part of its id.
//
// * lastXPosition: number, the x position of the last node in the diagram. It
//   will be used to calculate the x position of the new node.
//
// * orderLineY: number, the y position of the order nodes in the diagram. It
//   will be used to calculate the y position of the new node.
//
// * style: object, the style object that will be used to style the node. It
//   is useful to change the background and color of the node, for example.
//
const plotOrderNode = (label, options = {}) => {
  const {
    animatedEdges,
    elements,
    icon,
    index,
    lastXPosition,
    orderLineY,
    style,
  } = options;

  const lastOrderNode = elements.ordersNodes[elements.ordersNodes.length - 1];

  elements.ordersNodes.push({
    id: `order-${index}`,
    data: {
      label: buildLabel(label, icon),
      ...options,
    },
    position: { x: lastXPosition, y: orderLineY },
    sourcePosition: 'right',
    targetPosition: 'left',
    style: style || {},
  });

  elements.edges.push({
    id: `${lastOrderNode.id}-order-${index}`,
    source: lastOrderNode.id,
    target: `order-${index}`,
    animated: animatedEdges,
    style: { stroke: '#000' },
  });
};

// This function will plot a node representing a change on a line item.
//
// The changes will be plotted in the higher part of the diagram, while the
// order changes will be plotted below it.
//
// The new node will have an edge connecting it to the last node of the same
// line item to which it belongs.
//
// The node will have a label with the text, usually related to the log message,
// and an options hash that allows us to configure the node. Valid options are:
//
// * animatedEdges: boolean, if the edge connecting the nodes should be
//   animated or not.
//
// * elements: object, the elements object that will be used to store the nodes
//   and edges. It contains the lineItemsNodes, ordersNodes and edges.
//
// * icon: string, the bootstrap icon classes (and virtually any other class
//   you need) that will be used in the label. If no icon is provided, the
//   label will be just the text.
//
// * index: number, the index of the node in the logs array. It will be used to
//   uniquely identify a node by being part of its id.
//
// * log: object, the log object that represents the change. It contains the
//   comment and extra_information fields that will be used to build the node.
//
// * position: object, the position object that will be used to set the x and y
//   position of the node. If no position is provided, the node will be placed
//   to the right of the last node of the same line item. It is useful to
//   position the node in a specific place in the diagram, like the root nodes
//   for the line items, that are displayed in the same x position.
//
// * representsLineItem: boolean, if the node represents a line item or not. If
//   false, the node will be connected to the last node of the same line item.
//   If true, it means it is the initial node for a line item, representing the
//   line item itself. It will be the root node for the line item.
//
// * lastXPosition: number, the x position of the last node in the diagram. It
//   will be used to calculate the x position of the new node.
//
// * orderLineY: number, the y position of the order nodes in the diagram. It
//   will be used to calculate the y position of the new node.
//
// * style: object, the style object that will be used to style the node. It
//   is useful to change the background and color of the node, for example.
//
const plotLineItemNode = (label, options = {}) => {
  const {
    animatedEdges,
    elements,
    icon,
    index,
    lastXPosition,
    log,
    position,
    representsLineItem,
    style,
  } = options;

  const parentLineItemId =
    log.extra_information[3]?.text === 'ID da variante'
      ? log.extra_information[3].value
      : null;
  const lineItemId = parentLineItemId || log.extra_information[0].value;
  const lineItemNodes = elements.lineItemsNodes.filter(
    (node) => node.data.lineItemId === lineItemId,
  );
  const currentLineItem = lineItemNodes[lineItemNodes.length - 1];
  const currentLineItemId = representsLineItem
    ? 'line-items-0'
    : currentLineItem.id;

  elements.lineItemsNodes.push({
    id: `line-items-${index}`,
    data: {
      label: buildLabel(label, icon),
      ...options,
      lineItemId,
    },
    position: position || { x: lastXPosition, y: currentLineItem.position.y },
    sourcePosition: 'right',
    targetPosition: 'left',
    style: style || {},
  });

  elements.edges.push({
    id: `${currentLineItemId}-line-items-${index}`,
    source: currentLineItemId,
    target: `line-items-${index}`,
    animated: animatedEdges,
    style: { stroke: '#000' },
  });
};

// This function will plot a node representing the creation of an order in the
// diagram. The node will be the first node of the diagram, representing the
// start of the order lifecycle.
//
// The node will have a label with the text of the node and an options hash
// that allows us to configure the node. Valid options are:
//
// * elements: object, the elements object that will be used to store the nodes
//   and edges. It contains the lineItemsNodes, ordersNodes and edges.
//
// * index: number, the index of the node in the logs array. It will be used to
//   uniquely identify a node by being part of its id.
//
// * order: object, the order object that represents the order. It will be used
//   to get the order number and other information.
//
// * orderLineY: number, the y position of the order nodes in the diagram. It
//   will be used to calculate the y position of the new node.
//
export const plotOrderCreated = (options = {}) => {
  const { elements, index, order, orderLineY } = options;

  elements.lineItemsNodes.push({
    id: `line-items-${index}`,
    data: {
      label: `Pedido ${order.number ? order.number : 'sem número'}`,
      ...options,
    },
    type: 'input',
    position: { x: 0, y: 0 },
    sourcePosition: 'right',
  });

  elements.ordersNodes.push({
    id: `order-${index}`,
    data: {
      label: 'Pré-agendado',
      ...options,
    },
    type: 'input',
    position: { x: 0, y: orderLineY },
    sourcePosition: 'right',
    style: {
      backgroundColor: '#6D757D',
      color: '#fff',
    },
  });
};
