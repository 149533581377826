import API from 'api';

import ProductForm from 'pages/products/components/Products/ProductForm';
import Products from 'pages/products/components/Products/Products';

const ProductsController = {
  basePath: '/produtos',
  form: ProductForm,
  page: Products,
  resourceAPI: API.Product,
};

export default ProductsController;
