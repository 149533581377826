import React, { useState, useEffect } from 'react';
import ReactDragListView from 'react-drag-listview';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Instruction from './Instruction';

const AdditionalInformation = ({
  additionalInformation,
  listHeader,
  onAdditionalInformationChange,
  setAdditionalInformationErrors,
}) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [instructions, setInstructions] = useState(
    additionalInformation.instructions
      ? additionalInformation.instructions
      : [],
  );
  const [instructionsError, setInstructionsError] = useState(false);

  useEffect(() => {
    onAdditionalInformationChange(instructions);
  }, [instructions]);

  const setErrors = (status) => {
    setAdditionalInformationErrors(status);
    setInstructionsError(status);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...instructions];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setInstructions(data);
    },
    nodeSelector: 'li.instruction',
    handleSelector: 'i.fa-grip-lines',
  };

  const onInstructionChange = (instruction, index) => {
    const newInstructions = [...instructions];
    newInstructions[index] = instruction;
    setInstructions(newInstructions);
  };

  const onDeleteInstruction = (instruction, index) => {
    onInstructionChange({ ...instruction, deleted: true }, index);
    setCurrentIndex(-1);
  };

  return (
    <Row>
      <Col className="border-right">
        {listHeader('Instruções', instructionsError, () => {
          const newInstructions = [...instructions];
          newInstructions.push({});
          setInstructions(newInstructions);
          setCurrentIndex(newInstructions.length - 1);
        })}

        <ReactDragListView {...dragProps}>
          <ul className="instructions-list">
            {instructions.map((instruction, index) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                className={`instruction ${
                  currentIndex === index ? 'selected' : ''
                } ${instruction.deleted ? 'deleted' : ''}`}
                // eslint-disable-next-line react/no-array-index-key
                key={`instruction-${index}`}
                onClick={() => {
                  setCurrentIndex(index);
                }}
              >
                <i className="fa fa-grip-lines"></i>
                {instruction.title || `Instrução #${index}`}
              </li>
            ))}
          </ul>
        </ReactDragListView>
      </Col>
      <Col xs={8}>
        {currentIndex === -1 && (
          <div className="instruction-alert">
            Selecione uma das instruções à esquerda para editar
          </div>
        )}

        {currentIndex > -1 && (
          <Instruction
            instruction={instructions[currentIndex]}
            index={currentIndex}
            onInstructionChange={onInstructionChange}
            setHeaderErrors={setErrors}
            onDelete={onDeleteInstruction}
          />
        )}
      </Col>
    </Row>
  );
};

export default AdditionalInformation;
