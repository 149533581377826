import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';

import Input from 'components/Form/Input';

const ProductTypeForm = ({ onSave, onClose, resource }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: resource || {},
  });

  const onSubmit = (data) => {
    onSave(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        label="Nome"
        placeholder="Nome do tipo de produto (em inglês)"
        register={register('name', { required: true, maxLength: 30 })}
      />

      <Form.Group className="mb-3">
        <section className="form-actions">
          <Button variant="secondary" onClick={onClose}>
            Voltar
          </Button>

          <Button variant="primary" type="submit">
            Salvar
          </Button>
        </section>
      </Form.Group>
    </Form>
  );
};

export default ProductTypeForm;
