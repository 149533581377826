import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { useQuery } from 'react-query';

import API from 'api';

import hourglass from './hourglass.svg';

const WaitingRefund = ({ onClose, refundId }) => {
  const [showSlowProcessMessage, setShowSlowProcessMessage] = useState(false);

  const intervalId = setInterval(() => {
    refetch();
  }, 20000);

  useEffect(
    () =>
      // Clean up the interval when the component unmounts
      () => {
        clearInterval(intervalId);
      },
    [],
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowSlowProcessMessage(true);
    }, 60000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const fetchRefund = async () => {
    const response = await API.Refund.find(refundId);
    return response.data.data;
  };

  const { data: refund, refetch } = useQuery(
    [`refund-${refundId}`],
    fetchRefund,
  );

  if (refund && refund.status === 'completed') {
    clearInterval(intervalId);
  }

  const isCompleted = refund && refund.status === 'completed';

  if (isCompleted && showSlowProcessMessage) {
    setShowSlowProcessMessage(false);
  }

  return (
    <div className="lh-3 p-4">
      <Row className="mb-3">
        {!isCompleted && (
          <Col>
            <img src={hourglass} alt="Devolução em andamento" />
          </Col>
        )}

        {isCompleted && (
          <Col className="d-flex justify-content-center">
            <i
              className="fa-regular fa-circle-check text-success"
              style={{ fontSize: '70px' }}
            ></i>
          </Col>
        )}

        {!isCompleted && (
          <Col>
            O{' '}
            <a href={`/devolucoes/${refundId}/editar`} target="_blank">
              processo de devolução
            </a>{' '}
            está em andamento. Iremos notificá-lo quando estiver pronto, mas
            você pode fechar essa janela a qualquer momento, isso não irá
            interromper o processo.
          </Col>
        )}

        {isCompleted && (
          <Col>
            <a
              href={`/devolucoes/${refundId}/editar`}
              target="_blank"
              className="me-1"
            >
              A devolução #{refundId}
            </a>
            foi processada com sucesso.
          </Col>
        )}
      </Row>

      {showSlowProcessMessage && (
        <Row className="mb-3">
          <Col>
            <Alert variant="warning">
              hum... isto está demorando mais do que o esperado, verifique o
              status da devolução{' '}
            </Alert>
          </Col>
        </Row>
      )}

      <Row>
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
      </Row>
    </div>
  );
};

export default WaitingRefund;
