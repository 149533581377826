import React from 'react';
import { PubSub } from 'use-pubsub-js';

import './styles.scss';

const Backdrop = () => {
  const onClickHandler = () => {
    PubSub.publish('backdrop', 'clicked');
  };

  return <div className="backdrop" onClick={onClickHandler}></div>;
};

export default Backdrop;
