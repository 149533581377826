import React from 'react';
import Confetti from 'react-confetti';

import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useSelector } from 'react-redux';

// Uncomment this line (and line 52) if you want to debug React Query
// import { ReactQueryDevtools } from 'react-query/devtools';

import Backdrop from 'components/Backdrop/Backdrop';
import CustomRoutes from 'routes';
import Loading from 'components/Loading/Loading';
import Navigation from 'components/Navigation/Navigation';
import TopBar from 'components/TopBar/TopBar';
import { AuthProvider } from 'contexts/AuthContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.scss';

function App() {
  const backdrop = useSelector((state) => state.page.backdrop);
  const loading = useSelector((state) => state.page.loading);
  const confetti = useSelector((state) => state.page.confetti);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // if true, it will refetch data when the browser's tab gains focus.
        // It does not work well on admin because of nested forms.
        refetchOnWindowFocus: false,
        onError: (error) => {
          if (
            error.response?.status === 401 ||
            error.response?.status === 403
          ) {
            window.location.href = `/logout?url=${encodeURI(
              window.location.pathname,
            )}`;
          }

          if (error.response?.status === 404) {
            window.location.href = '/404';
          }
        },
        retry: 0,
      },
    },
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <AuthProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          {confetti && <Confetti />}
          <Navigation />

          <div className="right-panel">
            {backdrop && <Backdrop />}

            <TopBar />

            <div className="workspace">
              <CustomRoutes />
            </div>
          </div>

          {/* Uncomment this line if you want to debug React Query */}
          {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
        </QueryClientProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
