import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import API from 'api';

import Modal from 'components/Modal/Modal';

import { alert, confirm } from 'lib/notifications';
import { maskCPF, maskCNPJ, maskPhoneNumber, maskZipCode } from 'lib/masks';
import { isValidEmail } from 'lib/string';

const Participants = ({ lineItem, refetch, variant }) => {
  const [showAddParticipantsModal, setShowAddParticipantsModal] =
    useState(false);
  const [participantValues, setParticipantValues] = useState({});
  const [validForm, setValidForm] = useState(false);

  useEffect(() => {
    validateForm();
  }, [participantValues]);

  const validateForm = () => {
    let isValid = true;
    variant.participant_fields.forEach((field) => {
      if (field.required && !participantValues[field.name]) {
        isValid = false;
      }

      if (
        isValid &&
        field.validation_type === 'email' &&
        !isValidEmail(participantValues[field.name])
      ) {
        isValid = false;
      }
    });

    setValidForm(isValid);
  };

  const onChange = (e, field) => {
    let value = null;
    const validationType = field.validation_type?.toLowerCase() || '';
    if (validationType === 'cpf') {
      value = maskCPF(e.target.value);
    } else if (validationType === 'cnpj') {
      value = maskCNPJ(e.target.value);
    } else if (validationType === 'phone_number') {
      value = maskPhoneNumber(e.target.value);
    } else if (validationType === 'zip_code') {
      value = maskZipCode(e.target.value);
    } else {
      value = e.target.value;
    }

    const newValues = {
      ...participantValues,
      [e.target.name]: value || e.target.value,
    };
    setParticipantValues(newValues);
  };

  const onClose = () => {
    setShowAddParticipantsModal(false);
  };

  const inputMaxLength = {
    cpf: 14,
    cnpj: 18,
    phone_number: 15,
    zip_code: 9,
  };

  return (
    <>
      <Row className="card mb-3">
        <div className="card-header">
          <i className="fa-solid fa-users"></i> &nbsp; Participantes (
          {lineItem.participants.length} / {variant.max_party_size})
        </div>
        <div className="card-body">
          {lineItem.participants.length > 0 && (
            <Accordion>
              {lineItem.participants.map((participant, index) => (
                <Accordion.Item
                  key={`variant-overview-participants-item-${
                    participant.name ||
                    participant.email ||
                    participant[Object.keys(participant)[0]]
                  }`}
                  eventKey={index}
                >
                  <Accordion.Header>
                    {participant.name ||
                      participant.email ||
                      participant[Object.keys(participant)[0]]}
                  </Accordion.Header>
                  <Accordion.Body>
                    {Object.keys(participant).map((key) => (
                      <div
                        className="mb-2"
                        key={`variant-overview-participant-${
                          participant.name ||
                          participant.email ||
                          participant[Object.keys(participant)[0]]
                        }-${key}`}
                      >
                        <strong>{key}</strong>: &nbsp;{participant[key]}
                      </div>
                    ))}

                    <section className="actions-container d-flex">
                      <div
                        className="mt-4 cursor-pointer me-3"
                        onClick={() => {
                          setParticipantValues({
                            ...participant,
                            edit: true,
                            index,
                          });
                          setShowAddParticipantsModal(true);
                        }}
                      >
                        <i className="fa-solid fa-pencil text-warning"></i>{' '}
                        Editar
                      </div>

                      <div
                        className="mt-4 cursor-pointer"
                        onClick={() => {
                          confirm({
                            title: 'Remover participante',
                            message: `Tem certeza que deseja remover o participante?`,
                            onConfirm: () => {
                              const newList = lineItem.participants.filter(
                                (p, i) => i !== index,
                              );

                              API.LineItem.updateParticipants(
                                lineItem.id,
                                newList,
                              ).then(() => {
                                alert({
                                  text: 'O participante foi removido com sucesso.',
                                });
                              });
                              refetch();
                            },
                          });
                        }}
                      >
                        <i className="far fa-trash-alt me-1 text-danger"></i>{' '}
                        Remover
                      </div>
                    </section>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}

          {lineItem.participants.length === 0 && (
            <Alert variant="warning">Ainda não há participantes.</Alert>
          )}

          <Button
            className="float-end mt-3"
            variant="primary"
            onClick={() => {
              setParticipantValues({});
              setShowAddParticipantsModal(true);
            }}
          >
            <i className="fa fa-plus"></i>
          </Button>
        </div>
      </Row>

      {showAddParticipantsModal && (
        <Modal
          className="add-participants-modal"
          title="Participantes"
          onClose={onClose}
        >
          <Form.Group>
            {variant.participant_fields.map((field) => {
              if (
                ['text', 'number', 'email', 'password'].includes(field.type)
              ) {
                return (
                  <>
                    <Form.Label>
                      {field.label}
                      {field.required ? '*' : ''}
                    </Form.Label>
                    <Form.Control
                      type={field.type}
                      name={field.name}
                      required={field.required}
                      onChange={(e) => onChange(e, field)}
                      value={participantValues[field.name] || ''}
                      maxLength={inputMaxLength[field.validation_type]}
                    />
                  </>
                );
              }

              return null;
            })}
          </Form.Group>

          <div className="mt-3">
            <small>* Campos obrigatórios</small>
          </div>
          <section className="form-actions">
            <Button variant="secondary" onClick={onClose}>
              Cancelar
            </Button>

            <Button
              disabled={!validForm}
              variant="primary"
              onClick={() => {
                let newList = [];

                if (participantValues.edit) {
                  newList = lineItem.participants.map((p, index) => {
                    const values = { ...participantValues };
                    delete values.edit;
                    delete values.index;

                    return index === participantValues.index ? values : p;
                  });
                } else {
                  newList = [...lineItem.participants, participantValues];
                }

                API.LineItem.updateParticipants(lineItem.id, newList).then(
                  () => {
                    alert({
                      text: 'O participante foi salvo com sucesso.',
                    });

                    refetch();
                    onClose();
                  },
                );
              }}
            >
              Salvar
            </Button>
          </section>
        </Modal>
      )}
    </>
  );
};

export default Participants;
