/* eslint-disable no-bitwise */
import Resource from '../resource';

export default class LineItem extends Resource {
  updateTodoList(lineItemId, todoList) {
    return this.client.put(`/line_items/${lineItemId}/update-todo-list`, {
      todo_list: todoList,
    });
  }

  book(orderId, lineItemId, params) {
    return this.client.put(`/orders/${orderId}/schedule_variant`, {
      order: {
        comment: params.comment,
        scheduled_for: params.scheduled_for,
        line_item_id: lineItemId,
      },
    });
  }

  complete(lineItemId) {
    return this.client.put(`/line_items/${lineItemId}/complete`, {});
  }

  unfulfill(lineItemId) {
    return this.client.put(`/line_items/${lineItemId}/unfulfill`, {});
  }

  updateParticipants(lineItemId, participants) {
    return this.client.put(`/line_items/${lineItemId}/update_participants`, {
      participants,
    });
  }
}
