/* eslint-disable no-param-reassign */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import Loading from 'components/Loading/Loading';
import { alert } from 'lib/notifications';
import { storeActions } from 'store';

// Resource Loader is a function that will replace the default fetching method.
// It is useful when the find by id is not enough for your use case. For
// example, the edit order page user the order number instead of the order id
// as an identifier, and as you can see on OrdersController, the
// `resourceLoader` is set to a custom function.
const CrudForm = ({ basePath, formFor, form, resourceAPI, resourceLoader }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchResource = async () => {
    let result = null;

    if (resourceLoader && typeof resourceLoader === 'function') {
      result = await resourceLoader(id);
    } else {
      result = await resourceAPI.find(id);
    }
    const { data: body } = result;
    return body.data;
  };

  const onSaveHandler = (item) => {
    if (item.id) {
      return onUpdateHandler(item);
    }

    return onCreateHandler(item);
  };

  const onCreateHandler = (item) => {
    resourceAPI.create(item).then((response) => {
      const body = response.data;
      dispatch(storeActions.form.setFormStateToUnchanged());
      alert({
        text: 'Criado com sucesso!',
        icon: 'success',
        callback: () => {
          navigate(`${basePath}/${body.data.id}/editar`, { replace: true });
        },
      });
    });
  };

  const onUpdateHandler = (item) => {
    resourceAPI.update(item.id, item).then(() => {
      dispatch(storeActions.form.setFormStateToUnchanged());
      alert({
        text: 'Salvo com sucesso!',
        icon: 'success',
        callback: () => {
          refetch();
        },
      });
    });
  };

  const { data, isLoading, isRefetching, refetch } = useQuery(
    [`crud-page-form-${formFor}-${id}`],
    fetchResource,
    {
      enabled: id !== null && id !== undefined,
    },
  );

  if (isLoading || isRefetching) {
    return <Loading />;
  }

  const ResourceForm = form;

  return (
    <ResourceForm
      onSave={onSaveHandler}
      onClose={() => {
        dispatch(storeActions.form.setFormStateToUnchanged());
        navigate(basePath);
      }}
      resource={data}
    />
  );
};

export default CrudForm;
