/* eslint-disable no-alert */
import React from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import menuItems from 'components/Navigation/menu';
import MenuItem from 'components/Navigation/MenuItem';
import { confirmFormStateBeforeRedirect } from 'lib/form';
import { getPathDomainFromUrl } from 'lib/url';
import { storeActions } from 'store';

import './styles.scss';

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore();

  const changePage = (item) => {
    navigate(item.path);
  };

  const currentPath = getPathDomainFromUrl();

  const icons = () =>
    menuItems.map((item) => (
      <MenuItem
        key={`menu-item-${item.name}`}
        onClick={() => {
          const { formState } = store.getState().form;

          confirmFormStateBeforeRedirect(formState, () => {
            dispatch(storeActions.form.setFormStateToUnchanged());
            changePage(item);
          });
        }}
        icon={item.icon}
        name={item.name}
        active={currentPath === item.path}
      />
    ));

  return <nav id="navigation">{icons()}</nav>;
};

// Wrapping the main class with this functional component
function NavigationWrapper(props) {
  const navigate = useNavigate();
  return <Navigation {...props} navigate={navigate} />;
}

export default NavigationWrapper;
