import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import API from 'api';

import ClipboardCopy from 'components/ClipboardCopy/ClipboardCopy';
import Loading from 'components/Loading/Loading';
import OrderIdInput from './OrderIdInput';

import './checkout-url.scss';

const CheckoutUrl = () => {
  const [paymentMethod, setPaymentMethod] = useState('credit_card');
  const [orderId, setOrderId] = useState(null);
  const [checkoutURL, setCheckoutURL] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorDetails, setErrorDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const generateUrl = () => {
    setErrorMessage('');
    setErrorDetails('');
    setCheckoutURL('');
    setIsLoading(true);

    API.Order.addPaymentMethod(orderId, paymentMethod)
      .then(() => API.Payment.checkoutUrl(orderId))
      .then((response) => {
        setCheckoutURL(response.data.checkout_url);
      })
      .catch((error) => {
        setErrorMessage(`Error fetching checkout URL: ${error.message}`);
        setErrorDetails(error.config.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="card">
      <div className="card-header">
        <i className="fa-solid fa-dollar-sign"></i> &nbsp; URL de pagamento
      </div>
      <div className="card-body">
        <Form.Label>Forma de pagamento:</Form.Label>
        <div className="payment-method-container">
          <div
            className={`payment-method-option ${
              paymentMethod === 'credit_card' ? 'selected' : ''
            }`}
            onClick={() => {
              setPaymentMethod('credit_card');
            }}
          >
            <div className="logo">
              <i className="mx-1 far fa-credit-card"></i>
            </div>
            <div className="text">
              <p>Cartão de crédito</p>
            </div>
          </div>
          <div
            className={`payment-method-option  ${
              paymentMethod === 'pix' ? 'selected' : ''
            }`}
            onClick={() => setPaymentMethod('pix')}
          >
            <div className="logo">
              <i className="fa-brands fa-pix"></i>
            </div>
            <div className="text">
              <p>PIX</p>
            </div>
          </div>
        </div>

        <OrderIdInput
          orderId={orderId}
          onChange={(e) => setOrderId(e.target.value)}
        />
        <p>
          <Button
            variant="primary"
            onClick={generateUrl}
            type="button"
            disabled={orderId === null || isLoading}
          >
            Gerar URL
          </Button>
        </p>

        <hr />

        {isLoading && <Loading className="checkout-url-loading" />}

        {!isLoading && checkoutURL && (
          <div className="checkout-url">
            <Form.Label>URL de pagamento:</Form.Label>
            <ClipboardCopy
              copyText={checkoutURL}
              ariaLabel="URL de pagamento"
            />
            <div className="text-end">
              <a href={checkoutURL} target="_blank">
                Abrir em outra aba
              </a>
            </div>
          </div>
        )}

        {!isLoading && errorMessage && (
          <div className="text-danger">{errorMessage}</div>
        )}
        {!isLoading && errorDetails && <pre>{errorDetails}</pre>}
      </div>
    </div>
  );
};

export default CheckoutUrl;
