import React from 'react';
import Table from 'react-bootstrap/Table';
import TooltipIcon from 'components/TooltipIcon/TooltipIcon';

import './line-item-price-summary.scss';

const LineItemPriceSummary = ({ billing, lineItem }) => {
  const hasChildren =
    lineItem.children.length > 0 && billing.children_total_price.in_cents > 0;
  return (
    <Table hover className="borderless-table line-item-price-summary-component">
      <tbody>
        {billing.has_discount && (
          <tr>
            <td>De:</td>
            <td className="text-end price">
              <s>{billing.price.formatted}</s>
            </td>
          </tr>
        )}
        {billing.has_discount && (
          <tr>
            <td>Por*:</td>
            <td className="text-end price">
              {billing.price_with_discount.formatted}
            </td>
          </tr>
        )}

        {!billing.has_discount && (
          <tr>
            <td className="d-flex">
              Preço:
              {!hasChildren && (
                <TooltipIcon
                  className="ps-0 text-muted total-price-explanation"
                  icon="fa-solid fa-circle-info ms-2"
                  text="Esse preço é o valor do item já com os descontos aplicados. O
          cálculo não leva em consideração valores pagos ou devolvidos, nem custos
          adicionais."
                />
              )}
            </td>
            <td className="text-end price">{billing.price.formatted}</td>
          </tr>
        )}

        {hasChildren && (
          <tr>
            <td>Adicionais: </td>
            <td className="text-end price">
              {billing.children_total_price.formatted}
            </td>
          </tr>
        )}

        {hasChildren && (
          <tr>
            <td colSpan="2">
              <hr className="m-2" />
            </td>
          </tr>
        )}

        {hasChildren && (
          <tr>
            <td className="d-flex">
              Total:
              <TooltipIcon
                className="ps-0 text-muted total-price-explanation"
                icon="fa-solid fa-circle-info ms-2"
                text="Esse preço é o valor do item já com os descontos aplicados. O
          cálculo não leva em consideração valores pagos ou devolvidos, nem custos
          adicionais."
              />
            </td>
            <td className="text-end price">{billing.total.formatted}</td>
          </tr>
        )}

        {billing.has_discount && (
          <tr>
            <td colSpan="2" className="text-muted text-end discount-hint">
              *{billing.discount.formatted} OFF
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default LineItemPriceSummary;
