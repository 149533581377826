/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import LogContextMenu from './LogContextMenu';
import LogExtraInformation from './LogExtraInformation';

const extractUrls = (string) => string.match(/\bhttps?:\/\/\S+/gi);

const LogComment = ({ log, showAllDetails }) => {
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  useEffect(() => {
    setShowExtraInfo(showAllDetails);
  }, [showAllDetails]);

  const comment = log.comment || '';
  let text = comment.replaceAll('\n', '<br />');
  const urls = extractUrls(text);

  if (urls && urls.length > 0) {
    urls.forEach((url) => {
      text = text.replace(url, `<a href="${url}" target="_blank">${url}</a>`);
    });
  }

  return (
    <div>
      <div className="float-start me-2 cursor-pointer">
        {!showExtraInfo && log.extra_information.length > 0 && (
          <span onClick={() => setShowExtraInfo(true)}>
            <i className="fa-solid fa-plus" title="Mostrar detalhes"></i>
          </span>
        )}
        {showExtraInfo && log.extra_information.length > 0 && (
          <span onClick={() => setShowExtraInfo(false)}>
            <i className="fa-solid fa-minus" title="Esconder detalhes"></i>
          </span>
        )}
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />

      <div className="mt-2 mb-2">
        <LogContextMenu log={log} />
      </div>

      {showExtraInfo &&
        log.extra_information &&
        log.extra_information.map((extraInfo) => (
          <LogExtraInformation
            key={`extra-information-field-${extraInfo.text}=${extraInfo.value}`}
            extraInfo={extraInfo}
          />
        ))}
      <br />
    </div>
  );
};

export default LogComment;
