export const ORDER_STATUSES = {
  pre_booking: { name: 'Pré agendado', klass: 'bg-secondary' },
  placed: { name: 'Requisitado', klass: 'bg-dark' },
  waiting_for_payment: { name: 'Aguardando pagamento', klass: 'bg-warning' },
  ready: { name: 'Pronto', klass: 'bg-info text-dark' },
  closed: { name: 'Concluído', klass: 'bg-success' },
  expired: { name: 'Expirado', klass: 'bg-danger' },
};

export const LINE_ITEM_STATUSES = {
  created: { name: 'Criado', klass: 'bg-secondary' },
  booked: { name: 'Agendado', klass: 'bg-warning' },
  completed: { name: 'Concluído', klass: 'bg-success' },
  canceled: { name: 'Cancelado', klass: 'bg-info text-dark' },
  unfulfilled: { name: 'Não aconteceu', klass: 'bg-danger' },
};

export const REFUND_STATUSES = {
  created: { name: 'Criado', klass: 'bg-dark' },
  in_progress: { name: 'Em andamento', klass: 'bg-info text-dark' },
  completed: { name: 'Completo', klass: 'bg-success' },
  closed: { name: 'Fechado', klass: 'bg-danger' },
};
