import React from 'react';
import { Handle } from 'reactflow';

const DefaultNode = ({ data }) => (
  <div>
    <Handle type="target" position="left" style={{ borderRadius: 0 }} />
    <div
      onClick={() => {
        if (data.onClick && typeof data.onClick === 'function') {
          data.onClick(data);
        }
      }}
    >
      {data.label}
    </div>
    <Handle type="source" position="right" style={{ borderRadius: 0 }} id="a" />
  </div>
);

export default DefaultNode;
