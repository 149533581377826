/* eslint-disable no-param-reassign */
export const maskCNPJ = (v) => {
  v = v.replace(/\D/g, ''); // Remove all that is not a digit
  v = v.replace(/^(\d{2})(\d)/, '$1.$2'); // Add a point between second and third digits
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Add a point between fifth and sixth digits
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Add a slash between the eighth and nineth digits
  v = v.replace(/(\d{4})(\d)/, '$1-$2'); // Add an hifen after the four-digits block

  return v;
};

export const maskCPF = (v) => {
  v = v.replace(/\D/g, ''); // Remove all that is not a digit
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Add a point between third and fourth digits
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Add a point between third and fourth digits again (for the second block)
  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Add an hifen between third and fourth digits

  return v;
};

export const hideCPF = (cpf) => {
  const hiddenCPF = `${cpf.substr(0, 3)}.***.***-${cpf.substr(cpf.length - 2)}`;
  return hiddenCPF;
};

export const maskPhoneNumber = (v) => {
  let r = v.replace(/\D/g, '');
  r = r.replace(/^0/, '');

  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else {
    r = r.replace(/^(\d*)/, '($1');
  }

  return r;
};

export const maskZipCode = (v) => {
  let r = v.replace(/\D/g, '');

  if (r.length > 5) {
    r = r.replace(/^(\d{5})(\d{0,3}).*/, '$1-$2');
  }
  return r;
};
