import React from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';

import { storeActions } from 'store';

const TextArea = (props) => {
  const dispatch = useDispatch();

  const { label, onChange, register, ...attrs } = props;

  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="textarea"
        {...attrs}
        {...register}
        onChange={(e) => {
          dispatch(storeActions.form.setFormStateToModified());

          if (onChange) {
            onChange(e);
          }
        }}
      />
    </Form.Group>
  );
};

export default TextArea;
