/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment-timezone';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import API from 'api';

import AdditionalInformation from 'pages/products/components/Products/AdditionalInformation';
import CustomFields from 'components/CustomFields';
import Input from 'components/Form/Input';
import Loading from 'components/Loading/Loading';
import Location from 'pages/products/components/Products/Location';
import ParticipantFields from 'components/ParticipantFields';
import Responsible from 'pages/products/components/Products/Responsible';
import Select from 'components/Form/Select';
import Switch from 'components/Form/Switch';
import TextArea from 'components/Form/TextArea';
import TimeOfDay from 'pages/products/components/Products/TimeOfDay';
import TodoList from 'components/TodoList/TodoList';

import { alert, confirm } from 'lib/notifications';
import { storeActions } from 'store';
import { keyPrefix } from 'components/TodoList/keyPrefix';

import './styles.scss';

const VariantForm = ({ product, onClose, variant = {} }) => {
  const dispatch = useDispatch();

  const [validations, setValidations] = useState({});

  const [highlightValidationErrors, setHighlightValidationErrors] =
    useState(false);

  const [active, setActive] = useState(variant.active);

  const [title, setTitle] = useState(variant.title || '');
  const [label, setLabel] = useState(variant.label || '');
  const [position, setPosition] = useState(variant.position || 0);
  const [metaDescription, setMetaDescription] = useState(
    variant.meta_description || '',
  );
  const [price, setPrice] = useState(
    parseFloat(variant.billing?.price.in_decimal || 0),
  );

  const [discount, setDiscount] = useState(
    parseFloat(variant.billing?.discount.in_decimal || 0),
  );

  const [discountType, setDiscountType] = useState(
    variant.billing?.discount_type || 'flat',
  );

  const [minPartySize, setMinPartySize] = useState(
    parseFloat(variant.min_party_size || 0),
  );

  const [maxPartySize, setMaxPartySize] = useState(
    parseFloat(variant.max_party_size || 0),
  );

  const [bookingType, setBookingType] = useState(variant.booking_type);
  const [brainy, setBrainy] = useState(variant.brainy || false);
  const [carRequired, setCarRequired] = useState(variant.car_required || false);
  const [firstDateFriendly, setFirstDateFriendly] = useState(
    variant.first_date_friendly || false,
  );
  const [movement, setMovement] = useState(variant.movement || false);
  const [nature, setNature] = useState(variant.nature || false);
  const [over18, setOver18] = useState(variant.over18 || false);
  const [weatherDependent, setWeatherDependent] = useState(
    variant.weather_dependent || false,
  );

  const [mustBring, setMustBring] = useState(variant.must_bring || '');
  const [description, setDescription] = useState(variant.description || '');
  const [preBookingDescription, setPreBookingDescription] = useState(
    variant.pre_booking_description || '',
  );

  const [todoList, setTodoList] = useState(variant.todo_list || []);

  const [locations, setLocations] = useState(variant.locations || []);
  const [responsibles, setResponsibles] = useState(variant.responsibles || []);
  const [timesOfDay, setTimesOfDay] = useState(variant.times_of_day || []);
  const [additionalInformation, setAdditionalInformation] = useState(
    variant.additional_information || {},
  );

  const [participantFields, setParticipantFields] = useState(
    variant.participant_fields || [],
  );
  const [customFields, setCustomFields] = useState(variant.custom_fields || []);

  const [timeZone, setTimeZone] = useState(
    variant.time_zone || 'America/Sao_Paulo',
  );

  // error control
  const [locationsErrors, setLocationsErrors] = useState(false);
  const [responsiblesErrors, setResponsiblesErrors] = useState(false);
  const [timesOfDayErrors, setTimesOfDayErrors] = useState(false);
  const [additionalInformationErrors, setAdditionalInformationErrors] =
    useState(false);

  const brazilTimeZones = moment.tz.zonesForCountry('BR');

  const fetch = async (resource) => {
    const response = await resource.all();
    const { data: body } = response;
    return body.data;
  };

  const { data: states, isLoading: isLoadingStates } = useQuery('states', () =>
    fetch(API.State),
  );

  const { data: responsiblesList, isLoading: isLoadingResponsibles } = useQuery(
    'responsibles',
    () => fetch(API.Responsible),
  );

  const { data: timesOfDayList, isLoading: isLoadingTimesOfDayList } = useQuery(
    'times-of-day',
    () => fetch(API.TimeOfDay),
  );

  if (
    !states ||
    !responsibles ||
    !timesOfDayList ||
    isLoadingStates ||
    isLoadingResponsibles ||
    isLoadingTimesOfDayList
  ) {
    return <Loading />;
  }

  const createOrUpdateVariant = (body) => {
    if (variant.id) {
      return API.Variant.update(product.id, variant.id, body);
    }

    return API.Variant.create(product.id, body);
  };

  const checked = (value) => value === 'on' || value === true;

  const getData = () => ({
    label,
    title,
    description,
    position,
    pre_booking_description: preBookingDescription,
    booking_type: bookingType,
    meta_description: metaDescription,
    country_code: 'br',
    todo_list: todoList,
    must_bring: mustBring,
    price: parseFloat(price),
    discount: parseFloat(discount),
    discount_type: discountType,
    over18: checked(over18),
    first_date_friendly: checked(firstDateFriendly),
    car_required: checked(carRequired),
    weather_dependent: checked(weatherDependent),
    movement: checked(movement),
    nature: checked(nature),
    brainy: checked(brainy),
    locations: locations.filter((l) => !l.deleted),
    responsibles: responsibles.filter((r) => !r.deleted),
    times_of_day: timesOfDay.filter((t) => !t.deleted),
    additional_information: additionalInformation,
    min_party_size: minPartySize,
    max_party_size: maxPartySize,
    active,
    participant_fields: participantFields.filter((field) => !field.deleted),
    custom_fields: customFields.filter((field) => !field.deleted),
    time_zone: timeZone,
  });

  const cloneVariant = (currentVariant) => {
    const newVariant = {
      ...currentVariant,
      id: null,
      label: `Cópia de ${currentVariant.label}`,
      title: `Cópia de ${currentVariant.title}`,
      active: false,
    };

    API.Variant.create(product.id, newVariant).then(() => {
      dispatch(storeActions.form.setFormStateToUnchanged());
      alert({
        text: `"${newVariant.title} - ${newVariant.label}" criado com sucesso.`,
        icon: 'success',
        callback: () => window.location.reload(),
      });
    });
  };

  const saveVariant = () => {
    setHighlightValidationErrors(true);

    if (validateVariant()) {
      const data = getData();

      const currentOperation = variant.id ? 'update' : 'create';
      createOrUpdateVariant(data)
        .then(() => {
          dispatch(storeActions.form.setFormStateToUnchanged());
          const word = currentOperation === 'update' ? 'atualizado' : 'criado';
          alert({
            text: `"${data.title} - ${data.label}" ${word} com sucesso.`,
            icon: 'success',
            callback: () => window.location.reload(),
          });
        })
        .catch(() => {
          alert({ text: 'Não foi possível salvar a variante' });
        });
    } else {
      alert({ text: 'Verifique os campos obrigatórios' });
    }
  };

  const allValuesTrue = (hash) =>
    Object.values(hash).every((value) => value === true);

  const validateVariant = () => {
    const currentValidations = {
      title: title.trim().length > 0,
      label: label.trim().length > 0,
      price: parseFloat(price) > 0,
      discount: parseFloat(discount) >= 0,
      description: description.trim().length > 0,
      metaDescription: metaDescription.trim().length > 0,
      location: !locationsErrors,
      timesOfDay: !timesOfDayErrors,
      responsibles: !responsiblesErrors,
      additionalInformation: !additionalInformationErrors,
      minPartySize:
        parseFloat(minPartySize) > 0 &&
        parseFloat(minPartySize) <= parseFloat(maxPartySize),
      maxPartySize:
        parseFloat(maxPartySize) > 0 &&
        parseFloat(maxPartySize) >= parseFloat(minPartySize),
    };

    setValidations(currentValidations);

    const isValid = allValuesTrue(currentValidations);

    if (!isValid) {
      // eslint-disable-next-line no-console
      console.log('Variant validations', currentValidations);
    }

    return isValid;
  };

  const onDeleteVariant = () => {
    confirm({
      title: 'Atenção',
      text: 'Você tem certeza que deseja excluir esta variante? Esta operação não poderá ser desfeita.',
      onConfirm: () => {
        API.Variant.destroy(product.id, variant.id)
          .then(() => {
            dispatch(storeActions.form.setFormStateToUnchanged());
            const callback = () => {
              onClose();
            };
            alert({
              text: 'Variante excluída com sucesso',
              icon: 'success',
              callback,
            });
          })
          .catch(() => {
            alert({ text: 'Não foi possível excluir a variante' });
          });
      },
    });
  };

  const listHeader = (
    headerTitle,
    associationError,
    onClick,
    hideButton = false,
  ) => (
    <div className="association-header">
      {associationError && (
        <i className="fas fa-xs fa-exclamation-circle warning-icon"></i>
      )}
      {headerTitle}
      {associationError}
      {!hideButton && (
        <Button
          variant="primary"
          type="button"
          className="btn-sm association-add-button"
          onClick={onClick}
        >
          <i className="fa fa-plus"></i>
        </Button>
      )}
    </div>
  );

  const onLocationChange = (location, index) => {
    const newLocations = [...locations];
    newLocations[index] = location;
    setLocations(newLocations);
  };

  const onDeleteLocation = (location, index) => {
    onLocationChange({ ...location, deleted: true }, index);
  };

  const onResponsibleChange = (responsible, index) => {
    const newResponsibles = [...responsibles];
    newResponsibles[index] = responsible;
    setResponsibles(newResponsibles);
  };

  const onDeleteResponsible = (responsible, index) => {
    onResponsibleChange({ ...responsible, deleted: true }, index);
  };

  const onTimeOfDayChange = (tod, index) => {
    const newTimesOfDay = [...timesOfDay];
    newTimesOfDay[index] = tod;
    setTimesOfDay(newTimesOfDay);
  };

  const onDeleteTimeOfDay = (tod, index) => {
    onTimeOfDayChange({ ...tod, deleted: true }, index);
  };

  const onAdditionalInformationChange = (instructions) => {
    const newAdditionalInformation = { ...additionalInformation };
    newAdditionalInformation.instructions = instructions.filter(
      (i) => !i.deleted,
    );
    setAdditionalInformation(newAdditionalInformation);
  };

  const classAfterValidation = (key) => {
    if (!highlightValidationErrors) {
      return '';
    }

    return validations[key] ? '' : 'required';
  };

  const pricePreview =
    discountType === 'percentage'
      ? price * (1 - discount / 100)
      : price - discount;

  return (
    <div className="variant-container">
      <Row className="mb-0">
        <Col>
          <Input
            label="Título*"
            value={title}
            className={classAfterValidation('title')}
            onChange={(e) => setTitle(e.currentTarget.value)}
          />

          <Row>
            <Col>
              <Input
                label="Preço*"
                type="number"
                value={parseFloat(price)}
                className={classAfterValidation('price')}
                onChange={(e) =>
                  setPrice(parseFloat(e.currentTarget.value || 0))
                }
              />
            </Col>

            <Col>
              <Select
                label="Tipo do desconto"
                options={[
                  { text: 'Desconto fixo', value: 'flat' },
                  { text: 'Porcentagem', value: 'percentage' },
                ]}
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              />
            </Col>
            <Col>
              <Input
                className={classAfterValidation('discount')}
                label="Desconto*"
                type="number"
                value={discount}
                min={0}
                onChange={(e) =>
                  setDiscount(parseFloat(e.currentTarget.value || 0))
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                className={classAfterValidation('minPartySize')}
                label="Nᵒ mín. de participantes"
                type="number"
                value={minPartySize}
                onChange={(e) =>
                  setMinPartySize(parseFloat(e.currentTarget.value))
                }
              />
            </Col>
            <Col>
              <Input
                className={classAfterValidation('maxPartySize')}
                label="Nᵒ máx. de participantes"
                type="number"
                value={maxPartySize}
                onChange={(e) =>
                  setMaxPartySize(parseFloat(e.currentTarget.value))
                }
              />
            </Col>
            <Col>
              <Select
                label="Tipo do agendamento"
                options={[
                  { text: 'Nenhum', value: 'none' },
                  { text: 'Manual', value: 'manual' },
                  { text: 'Automático', value: 'automatic' },
                ]}
                value={bookingType}
                onChange={(e) => setBookingType(e.target.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Input
                label="Label*"
                value={label}
                className={classAfterValidation('label')}
                onChange={(e) => setLabel(e.currentTarget.value)}
              />

              <Input
                disabled
                label="Preview de preço"
                value={`R$ ${pricePreview}`}
              />

              <Input
                label="Posição"
                value={position}
                onChange={(e) => setPosition(e.currentTarget.value)}
              />
            </Col>
            <Col className="mt-4">
              <Switch
                label="Com desafios que façam pensar?"
                checked={brainy}
                onChange={(e) => {
                  setBrainy(checked(e.currentTarget.checked));
                }}
              />

              <Switch
                label="Precisa de carro?"
                checked={carRequired}
                onChange={(e) => {
                  setCarRequired(e.currentTarget.checked);
                }}
              />

              <Switch
                label="Bom para primeiro encontro?"
                checked={firstDateFriendly}
                onChange={(e) => {
                  setFirstDateFriendly(e.currentTarget.checked);
                }}
              />
            </Col>
            <Col className="mt-4">
              <Switch
                label="Movimento"
                checked={movement}
                onChange={(e) => setMovement(e.currentTarget.checked)}
              />

              <Switch
                label="Natureza"
                checked={nature}
                onChange={(e) => setNature(e.currentTarget.checked)}
              />

              <Switch
                label="Maiores de 18 anos"
                checked={over18}
                onChange={(e) => setOver18(e.currentTarget.checked)}
              />

              <Switch
                label="Depende do clima"
                checked={weatherDependent}
                onChange={(e) => setWeatherDependent(e.currentTarget.checked)}
              />

              <Switch
                label="Ativo?"
                checked={active}
                onChange={(e) => setActive(e.currentTarget.checked)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            label="Meta descrição (SEO)"
            maxLength={160}
            value={metaDescription}
            className={classAfterValidation('metaDescription')}
            onChange={(e) => setMetaDescription(e.currentTarget.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            label="Fuso horário"
            options={brazilTimeZones.map((tz) => ({ text: tz, value: tz }))}
            onChange={(e) => setTimeZone(e.target.value)}
            value={timeZone}
          />
        </Col>
      </Row>
      <Row>
        <hr />
        <Col>
          <TextArea
            label="Descrição*"
            value={description}
            className={classAfterValidation('description')}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </Col>
        <Col>
          <TextArea
            label="Precisa trazer"
            value={mustBring}
            onChange={(e) => setMustBring(e.currentTarget.value)}
          />
        </Col>
        <Col>
          <TextArea
            label="Descrição no pré-booking"
            value={preBookingDescription}
            onChange={(e) => setPreBookingDescription(e.currentTarget.value)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <hr />
          <h3>Lista de Tarefas</h3>
          <TodoList
            list={todoList}
            attributes={{
              onChange: (newTodoList) => {
                setTodoList(newTodoList);
              },
              disabled: true,
              aggregations: [
                {
                  title: 'Custo Estimado',
                  initialValue: 0,
                  fn: (task, acc) => acc + parseFloat(task.cost),
                  format: (value) =>
                    value.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                },
              ],
              types: [
                {
                  name: 'task',
                  type: 'text',
                  title: 'Tarefa',
                },
                {
                  name: 'cost',
                  type: 'number',
                  title: 'Custo',
                  render: (item, attrIndex) => {
                    const key = `${keyPrefix(item)}-task-item-${attrIndex}`;
                    return (
                      <span className="task-cost" key={key}>
                        [R${' '}
                        {parseFloat(item.cost || 0)
                          .toString()
                          .replace('.', ',')}
                        ]
                      </span>
                    );
                  },
                  value: (item) => item.cost,
                },
                {
                  name: 'comment',
                  type: 'textarea',
                  title: 'Comentário',
                },
              ],
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <hr />
          <h4>O que pedir para cada participante?</h4>
          <ParticipantFields
            fields={participantFields}
            onChange={(fields) => setParticipantFields(fields)}
          />
        </Col>
        <Col>
          <hr />
          <h4>Perguntas extras</h4>
          <CustomFields
            fields={customFields}
            onChange={(fields) => setCustomFields(fields)}
          />
        </Col>
      </Row>

      <br />

      <Row className="associations-row">
        <hr />
        <Col className="border-right">
          {listHeader(
            'Localizações',
            locationsErrors,
            () => {
              const newLocations = [...locations];
              newLocations.push({});
              setLocations(newLocations);
            },
            locations.length === 1,
          )}
          <Accordion>
            {locations.map((location, index) => (
              <Accordion.Item
                eventKey={`location-${index}`}
                key={`location-${index}`}
                className={location.deleted ? 'deleted' : ''}
              >
                <Accordion.Header>
                  {location.title && location.title.trim().length > 0
                    ? location.title
                    : 'Nova localização'}
                </Accordion.Header>
                <Accordion.Body>
                  <Location
                    location={location}
                    onLocationChange={onLocationChange}
                    states={states}
                    locationIndex={index}
                    setHeaderErrors={setLocationsErrors}
                    onDelete={onDeleteLocation}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
        <Col className="border-right">
          {listHeader('Responsáveis', responsiblesErrors, () => {
            const newResponsibles = [...responsibles];
            newResponsibles.push({});
            setResponsibles(newResponsibles);
          })}

          <Accordion>
            {responsibles.map((responsible, index) => (
              <Accordion.Item
                eventKey={index}
                key={`responsible-${index}`}
                className={responsible.deleted ? 'deleted' : ''}
              >
                <Accordion.Header>
                  {responsible.id
                    ? responsible.name
                    : `Responsável #${index + 1}`}
                </Accordion.Header>
                <Accordion.Body>
                  <Responsible
                    responsible={responsible}
                    onResponsibleChange={onResponsibleChange}
                    responsiblesList={responsiblesList}
                    index={index}
                    setHeaderErrors={setResponsiblesErrors}
                    onDelete={onDeleteResponsible}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
        <Col className="border-right">
          {listHeader('Períodos do dia', timesOfDayErrors, () => {
            const newTimesOfDay = [...timesOfDay];
            newTimesOfDay.push({});
            setTimesOfDay(newTimesOfDay);
          })}

          <Accordion>
            {timesOfDay.map((tod, index) => (
              <Accordion.Item
                eventKey={index}
                key={`times-of-day-${index}`}
                className={tod.deleted ? 'deleted' : ''}
              >
                <Accordion.Header>
                  {tod.id ? tod.name : `Período #${index + 1}`}
                </Accordion.Header>
                <Accordion.Body>
                  <TimeOfDay
                    timeOfDay={tod}
                    onTimeOfDayChange={onTimeOfDayChange}
                    timesOfDayList={timesOfDayList}
                    index={index}
                    setHeaderErrors={setTimesOfDayErrors}
                    onDelete={onDeleteTimeOfDay}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
      <Row className="associations-row">
        <hr />
        <Col>
          <AdditionalInformation
            additionalInformation={additionalInformation}
            listHeader={listHeader}
            onAdditionalInformationChange={onAdditionalInformationChange}
            setAdditionalInformationErrors={setAdditionalInformationErrors}
          />
        </Col>
      </Row>

      <section className="form-actions">
        {variant.id && (
          <Button variant="danger" type="button" onClick={onDeleteVariant}>
            <i className="fa fa-trash"></i>&nbsp; Excluir {variant.title}
          </Button>
        )}
        <Button variant="primary" type="button" onClick={saveVariant}>
          Salvar variante
        </Button>

        <Button
          variant="info"
          type="button"
          onClick={() => {
            confirm({
              title: 'Clone wars',
              text: 'Você está prestes a criar uma nova variante com os mesmos dados desta. Tem certeza que deseja continuar?',
              onConfirm: () => {
                cloneVariant(getData());
              },
            });
          }}
        >
          Clonar variante
        </Button>
      </section>
    </div>
  );
};

export default VariantForm;
