import API from 'api';

import Leads from 'pages/leads/leads';

const LeadsController = {
  basePath: '/leads',
  form: null,
  page: Leads,
  resourceAPI: API.Lead,
};

export default LeadsController;
