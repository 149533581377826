import React from 'react';
import { confirmWithCustomHtml } from 'lib/notifications';

const DeleteButton = ({ onClick }) => (
  <div
    className="cursor-pointer delete-button"
    onClick={() => {
      confirmWithCustomHtml({
        title: 'Tem certeza que deseja remover?',
        onConfirm: onClick,
      });
    }}
  >
    <i className="fa fa-trash-can" />
  </div>
);

export default DeleteButton;
