/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ParticipantField from './ParticipantField';

const ParticipantFields = ({ fields: participantFields, onChange }) => {
  const [fieldsList, setFieldsList] = useState(participantFields || []);

  const onAddHandler = () => {
    const newField = {
      label: '',
      type: 'text',
      name: '',
      required: true,
      validation_type: null,
    };

    const newFieldsList = [...fieldsList, newField];
    setFieldsList(newFieldsList);
    if (onChange && typeof onChange === 'function') {
      onChange(newFieldsList);
    }
  };

  const onChangeHandler = (field, index) => {
    const newFieldsList = fieldsList.map((f, i) => (i === index ? field : f));
    setFieldsList([...newFieldsList]);
    if (onChange && typeof onChange === 'function') {
      onChange(newFieldsList);
    }
  };

  if (fieldsList.length === 0) {
    return (
      <div>
        <br />
        <p>
          Ainda não há nenhum campo na sua lista. Os campos definidos aqui serão
          pedidos para cada um dos participantes.
        </p>

        <br />
        <Button variant="primary" onClick={onAddHandler} type="button">
          Clique aqui para criar o primeiro
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Accordion defaultActiveKey="0">
        {fieldsList.map((field, index) => (
          <ParticipantField
            key={index}
            field={field}
            index={index}
            onChange={onChangeHandler}
          />
        ))}
      </Accordion>
      <br />
      <Button variant="primary" onClick={onAddHandler} type="button">
        <i className="fa fa-plus"></i>&nbsp; Adicionar
      </Button>
    </div>
  );
};

export default ParticipantFields;
