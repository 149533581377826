/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

import Task from './Task';
import EditableTask from './EditableTask';
import { keyPrefix } from './keyPrefix';

import './styles.scss';

/*
This component manages a todo list. It allows users to mark any task as
done/not done, and to add or remove tasks.

It receives the following props:

- list: an array of objects, each one representing a task.

  Example:

  ```
  {
    task: 'Buy vegan milk',
    done: false,
    comment: 'We usually buy from the Vegança Store'
  }
  ```

- attributes: even though it is possible to extract the attributes from the
  todoList, the component needs to know their types, becase when the user
  adds a new task, the component needs to know which fields to show.

  The attributes prop is an object containing the following keys:

  * onChange: a function that receives the new todoList as a parameter. It is
    called whenever the user adds or removes a task, or marks a task as done
    or not done.

  * types: an array of hashes containing the attributes and their types.

  For example, considering the previous todo List example, the attributes prop
  could be:

  ```
    [
      {
        name: 'task',
        type: 'input',
      },
      {
        name: 'done',
        type: 'check',
      },
      {
        name: 'comment',
        type: 'textarea',
      }
    ]
  ```
*/
const TodoList = ({ list, attributes }) => {
  const [todoList, setTodoList] = useState(list);
  const [linesInEditMode, setLinesInEditMode] = useState([]);
  const [taskInEditMode, setTaskInEditMode] = useState();

  const doneAttribute = [{ name: 'done', type: 'check' }];
  const validAttributes = doneAttribute.concat(
    attributes.types.filter((attr) => attr.name !== 'done'),
  );

  const isTaskInEditMode = (index) => linesInEditMode.includes(index);
  const inEditMode = () => linesInEditMode.length > 0;

  const leaveEditMode = (index) => {
    const indexToRemove = linesInEditMode.indexOf(index);
    const updatedArray = linesInEditMode.filter((_, i) => i !== indexToRemove);

    setLinesInEditMode(updatedArray);
  };

  /* CRUD operations */

  const cancelEdition = (index) => {
    leaveEditMode(index);

    setTaskInEditMode(null);
  };

  const editTask = (task, index) => {
    const newTask = { ...task };
    setTaskInEditMode(newTask);
    setLinesInEditMode([...linesInEditMode, index]);
  };

  const newTask = () => {
    const task = {};
    validAttributes.forEach((attr) => {
      if (attr.type === 'number') {
        task[attr.name] = attr.type === 'number' ? 0 : '';
      } else if (attr.type === 'check') {
        task[attr.name] = false;
      } else {
        task[attr.name] = '';
      }
    });
    const newList = [...todoList, task];
    setTodoList(newList);
    setLinesInEditMode([...linesInEditMode, newList.length - 1]);
  };

  const removeTask = (index) => {
    const newList = [...todoList];
    newList.splice(index, 1);
    setTodoList(newList);

    if (attributes.onChange) {
      attributes.onChange(newList);
    }
  };

  const saveTaskChanges = (index) => {
    const newList = [...todoList];
    newList[index] = taskInEditMode;
    setTodoList(newList);

    if (attributes.onChange) {
      attributes.onChange(newList);
    }

    leaveEditMode(index);
  };

  /* End of CRUD operations */

  const showList = () =>
    todoList.map((task, index) => (
      <div key={`todo-list-show-list-${task.task}`}>
        {!isTaskInEditMode(index) && (
          <li
            className={`task ${task.done ? 'done' : 'not-done'}`}
            key={`show-task-${keyPrefix(task)}`}
          >
            <Task
              task={task}
              attributes={validAttributes}
              disabled={attributes.disabled}
              onStatusChange={() => {
                const newList = [...todoList];
                newList[index].done = !newList[index].done;
                setTodoList(newList);
                if (attributes.onChange) {
                  attributes.onChange(newList);
                }
              }}
            />

            {!inEditMode() && (
              <div className="task-actions">
                <i
                  className="fa-solid fa-pen"
                  onClick={() => {
                    editTask(task, index);
                  }}
                ></i>

                <i
                  className="fa-solid fa-trash"
                  onClick={() => {
                    removeTask(index);
                  }}
                ></i>
              </div>
            )}
          </li>
        )}

        {isTaskInEditMode(index) && (
          <li className="task edit-task" key={`edit-task-${keyPrefix(task)}`}>
            <EditableTask
              task={task}
              attributes={validAttributes}
              updateTask={(updatedTask) => {
                setTaskInEditMode(updatedTask);
              }}
            />

            <div className="task-actions">
              <i
                className="fa-solid fa-floppy-disk d-flex mt-4"
                onClick={() => {
                  saveTaskChanges(index);
                }}
              ></i>
              <i
                className="fa-solid fa-ban"
                onClick={() => {
                  cancelEdition(index);
                }}
              ></i>
            </div>
          </li>
        )}
      </div>
    ));

  const showAggregations = () => {
    const result = {};
    attributes.aggregations.forEach((aggregation) => {
      result[aggregation.title] = aggregation.initialValue;
      todoList.forEach((task) => {
        result[aggregation.title] = aggregation.fn(
          task,
          result[aggregation.title],
        );
      });

      if (aggregation.format && typeof aggregation.format === 'function') {
        result[aggregation.title] = aggregation.format(
          result[aggregation.title],
        );
      }
    });

    if (Object.keys(result).length === 0) {
      return null;
    }

    return (
      <ul className="aggregations">
        {Object.keys(result).map((key) => (
          <li key={`todo-list-aggregations-${key}`}>
            <label>
              <strong>{key}</strong>:
            </label>
            &nbsp;
            <span>{result[key]}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <section>
      {todoList.length > 0 && <ul className="todo-list">{showList()}</ul>}

      {todoList.length === 0 && <span>Não há tarefas</span>}

      <div>{attributes.aggregations && showAggregations()}</div>

      <Button
        className="new-task-button"
        variant="primary"
        type="submit"
        onClick={newTask}
        disabled={inEditMode()}
      >
        <i className="fa fa-plus"></i> Nova tarefa
      </Button>
    </section>
  );
};

export default TodoList;
