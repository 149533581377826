import React from 'react';

import './styles.scss';

const NotFound = () => (
  <div className="not-found-page">
    <h1>404 - Not Found</h1>

    <img
      src="https://res.cloudinary.com/makemydate/image/upload/v1710282538/Admin/preview_mb3gry.jpg"
      alt="404"
    />
  </div>
);

export default NotFound;
